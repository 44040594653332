import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import calendarAddIcon from '../../../../../assets/icons/calendar-add.svg';
import phoneIcon from '../../../../../assets/icons/call.svg';
import editIcon from '../../../../../assets/icons/edit.svg';
import emailIcon from '../../../../../assets/icons/email.svg';
import locationIcon from '../../../../../assets/icons/location.svg';
import saveIcon from '../../../../../assets/icons/save-small.svg';
import { GET_GEOCODING_SUGGESTIONS } from '../../../../../services/graphql/address/address-querys';
import type { GetVendorByIdResponse } from '../../../../../types/vendors';
import { convertToAmericanDateFormatWithTime } from '../../../../../utils/convertTimestampWithTime';
import StatusDropdown from '../../VendorStatusDropdown';

type Props = {
  data?: GetVendorByIdResponse;
  updateVendor: (arg: any) => void;
  vendor: string;
};

const VendorFixedCards = ({ updateVendor, vendor, data }: Props) => {
  const { t } = useTranslation('common');

  // === STATES ===
  // Phone
  const [phone, setPhone] = useState<string>(
    data?.getVendorById?.data.phone || '',
  );
  const [isEditingPhone, setIsEditingPhone] = useState<boolean>(false);
  const [isHoveringPhone, setIsHoveringPhone] = useState<boolean>(false);

  // Email
  const [email, setEmail] = useState<string>(
    data?.getVendorById?.data.email || '',
  );
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [isHoveringEmail, setIsHoveringEmail] = useState<boolean>(false);

  // Address
  const [address, setAddress] = useState<string>(
    data?.getVendorById?.data.address || '',
  );
  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);
  const [isHoveringAddress, setIsHoveringAddress] = useState<boolean>(false);

  const resetIsEditingStates = () => {
    setIsEditingPhone(false);
    setIsEditingEmail(false);
    setIsEditingAddress(false);
  };

  const handleSavePhone = () => {
    updateVendor({
      variables: {
        vendorId: vendor,
        key: 'phone',
        value: phone,
      },
    });
    setIsEditingPhone(false);
    resetIsEditingStates();
  };

  useEffect(() => {
    setPhone(data?.getVendorById?.data.phone || '');
    setEmail(data?.getVendorById?.data.email || '');
    setAddress(data?.getVendorById?.data.address || '');
  }, [data]);

  // Geocoding
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>(
    data?.getVendorById?.data.address || '',
  );
  const [showAddressList, setShowAddressList] = useState<boolean>(false);

  const { data: fetchAddressData } = useQuery<any>(GET_GEOCODING_SUGGESTIONS, {
    variables: {
      address: address || '',
    },
    skip: !address || address === data?.getVendorById.data.address,
  });

  useEffect(() => {
    if (
      fetchAddressData &&
      fetchAddressData.getAddressSuggestions.code === 200
    ) {
      if (isEditingAddress) {
        setShowAddressList(true);
      }

      setAddressSuggestions(
        fetchAddressData.getAddressSuggestions.data.map(
          (item: any) => item.place,
        ),
      );
    }
  }, [fetchAddressData]);

  useEffect(() => {
    if (!isEditingAddress) {
      // We give time to hit the selected option
      setTimeout(() => {
        setShowAddressList(false);
      }, 200);
    }
    if (isEditingAddress && addressSuggestions.length > 0) {
      setShowAddressList(true);
    }
  }, [isEditingAddress]);

  const handleAddressSelection = (selectedOption: string) => {
    setAddress(selectedOption);
    setSelectedAddress(selectedOption);
    setShowAddressList(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAddress = e.target.value;

    setAddress(inputAddress);
    setSelectedAddress(inputAddress);

    if (inputAddress.trim() === '') {
      setShowAddressList(false);
    }
  };

  const validateAddress = (_selectedAddress: string) => {
    return addressSuggestions.includes(_selectedAddress);
  };

  const handleSaveAddress = () => {
    if (!validateAddress(selectedAddress)) {
      toast.warn(t('vendors-overall.notify-address-invalid'));
      return;
    }
    updateVendor({
      variables: {
        vendorId: vendor,
        key: 'address',
        value: selectedAddress,
      },
    });
    setIsEditingAddress(false);
    resetIsEditingStates();
  };

  return (
    <>
      <div className="flex flex-col gap-2 rounded-lg bg-[#EBF0F1] pb-10 pl-6 pr-3 pt-6">
        <div>
          <h1 className="text-3xl font-[700]">
            {data?.getVendorById?.data.name}
          </h1>
          <div className="flex items-center gap-2">
            <img src={calendarAddIcon} alt="calendar add icon" />
            <p className="text-sm font-medium">
              {data?.getVendorById?.data.createdAt &&
                convertToAmericanDateFormatWithTime(
                  data?.getVendorById?.data.createdAt,
                )}
            </p>
          </div>
        </div>
        <div className="my-3">
          {data && data.getVendorById && vendor && (
            <StatusDropdown
              vendorId={vendor}
              mutation={updateVendor}
              initialStatusValue={data.getVendorById?.data.status || ''}
            />
          )}
        </div>
        <div
          onMouseOver={() => setIsHoveringPhone(true)}
          onMouseLeave={() => setIsHoveringPhone(false)}
          className="mt-3 flex justify-between gap-2 pr-2"
        >
          {isEditingPhone ? (
            <div className="flex items-center gap-2">
              <img className="h-[16px]" src={phoneIcon} alt="phone icon" />
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                id="phone"
                className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                value={phone}
              />
            </div>
          ) : (
            <div className="flex w-[calc(100%-16px)] items-center gap-2">
              <img className="h-[16px]" src={phoneIcon} alt="phone icon" />
              <p className="text-xs font-medium">{phone}</p>
            </div>
          )}

          {isEditingPhone ? (
            <button
              className={`${isEditingPhone ? 'min-w-[16px]' : 'hidden'}`}
              onClick={handleSavePhone}
              id="savePhone"
            >
              <img src={saveIcon} alt="edit icon" />
            </button>
          ) : (
            <button
              className={`${isHoveringPhone ? 'min-w-[16px]' : 'hidden'}`}
              onClick={() => setIsEditingPhone(!isEditingPhone)}
              id="editPhone"
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
        </div>
        <div
          onMouseOver={() => setIsHoveringEmail(true)}
          onMouseLeave={() => setIsHoveringEmail(false)}
          className="flex justify-between gap-2 pr-2"
        >
          {isEditingEmail ? (
            <div className="flex items-center gap-2">
              <img className="h-[16px]" src={emailIcon} alt="email icon" />
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                value={email}
              />
            </div>
          ) : (
            <div className="flex w-[calc(100%-16px)] items-center gap-2 truncate ">
              <img className="h-[16px]" src={emailIcon} alt="email icon" />
              <p className="text-xs font-medium">{email}</p>
            </div>
          )}

          {isEditingEmail ? (
            <button
              className={`${isEditingEmail ? 'min-w-[16px]' : 'hidden'}`}
              onClick={() => {
                updateVendor({
                  variables: {
                    vendorId: vendor,
                    key: 'email',
                    value: email,
                  },
                });
                setIsEditingEmail(false);
                resetIsEditingStates();
              }}
            >
              <img src={saveIcon} alt="edit icon" />
            </button>
          ) : (
            <button
              className={`${isHoveringEmail ? 'min-w-[16px]' : 'hidden'}`}
              onClick={() => setIsEditingEmail(!isEditingEmail)}
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
        </div>
        <div
          onMouseOver={() => setIsHoveringAddress(true)}
          onMouseLeave={() => setIsHoveringAddress(false)}
          className="flex justify-between gap-2 pr-2"
        >
          {isEditingAddress ? (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <img
                  className="h-[16px]"
                  src={locationIcon}
                  alt="location icon"
                />
                <input
                  onChange={handleInputChange}
                  autoComplete="off"
                  onFocus={() => setShowAddressList(true)}
                  onBlur={() => {
                    setTimeout(() => {
                      setShowAddressList(false);
                    }, 200);
                  }}
                  type="text"
                  className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                  value={address}
                />
              </div>
              <div className="relative ">
                {showAddressList && (
                  <div className="geocoding-datalist absolute left-[20px] top-[-5px] z-30 flex max-h-[160px] w-full flex-col overflow-y-auto border border-graylight bg-white">
                    {addressSuggestions.map((option, index) => (
                      <div
                        className="flex size-full cursor-pointer border-b border-graydark p-1 text-[12px] font-medium hover:bg-graydark"
                        key={index}
                        onClick={() => handleAddressSelection(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex w-[calc(100%-16px)] items-center gap-2">
              <img
                className="h-[16px]"
                src={locationIcon}
                alt="location icon"
              />
              <p className="text-xs font-medium">{address}</p>
            </div>
          )}

          {isEditingAddress ? (
            <button
              className={`${isEditingAddress ? 'min-w-[16px]' : 'hidden'}`}
              onClick={handleSaveAddress}
            >
              <img src={saveIcon} alt="edit icon" />
            </button>
          ) : (
            <button
              className={`${isHoveringAddress ? 'min-w-[16px]' : 'hidden'}`}
              onClick={() => setIsEditingAddress(!isEditingAddress)}
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default VendorFixedCards;
