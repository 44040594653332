import 'react-datepicker/dist/react-datepicker.css';

import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import clientIcon from '../../../assets/icons/clients-small.svg';
import hoodCleaningIcon from '../../../assets/icons/cooker-hood.svg';
import greaseTrapIcon from '../../../assets/icons/grease-trap.svg';
import oilDisposalIcon from '../../../assets/icons/oil-disposal.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import {
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
  serviceClientOptions,
} from '../../../config/constants';
import { useDropdownData } from '../../../hooks/dropdownData';
import { useModal } from '../../../hooks/useModal';
import { driversListState } from '../../../recoil/driversList';
import { trucksListState } from '../../../recoil/trucksList';
import { CREATE_ROUTE } from '../../../services/graphql/route/route-mutations';
import { convertDateToString } from '../../../utils/convertDate';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import DateField from '../../ui/common/Inputs/DateField';
import Dropdown from '../../ui/common/Inputs/Dropdown';

interface DriverOrTruck {
  label: string;
  value: string;
  id?: string;
}
const NewRoute = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const breadcrumbTitle = `${t('routes.title-h1')} > ${t(
    'modalNewRoute.newRoute',
  )}`;

  // ==== STATES ====

  const drivers: DriverOrTruck[] = useRecoilValue(driversListState);
  const trucks: DriverOrTruck[] = useRecoilValue(trucksListState);

  const [driverSelect, setDriverSelect] = useState<string>('');
  const [truckSelect, setTruckSelect] = useState<string>('');
  const [truckSelectKey, setTruckSelectKey] = useState(Math.random());
  const [driverSelectKey, setDriverSelectKey] = useState(Math.random());
  const [dateSelect, setDateSelect] = useState<string>('');
  const [serviceIcon, setServiceIcon] = useState<string>('');
  const [service, setService] = useState<string>('');

  const { handleCloseAllModals } = useModal();
  useDropdownData(service, dateSelect, truckSelect);

  // ==== VISUAL NOTIFICATIONS ====

  const notifyCreateRouteError = () =>
    toast.error(t('modalNewRoute.create-error'));
  const notifyCreateRouteOk = () =>
    toast.success(t('modalNewRoute.create-ok'), {
      toastId: 'notifyStatusSaveOk',
    });

  const [createRoute, { loading: createRouteLoading }] = useMutation(
    CREATE_ROUTE,
    {
      onCompleted: (data) => {
        if (data.createRoute.data) {
          handleCloseAllModals();
          notifyCreateRouteOk();
          navigate(`/dashboard/routes/edit/${data.createRoute.data}`);
        }
      },
      onError: () => {
        notifyCreateRouteError();
      },
    },
  );

  const handleServiceFilterChange = (_service: string) => {
    if (_service === GREASE_TRAP_SERVICE_NAME) {
      setServiceIcon(greaseTrapIcon);
    } else if (_service === OIL_DISPOSAL_SERVICE_NAME) {
      setServiceIcon(oilDisposalIcon);
    } else if (_service === HOOD_CLEANING_SERVICE_NAME) {
      setServiceIcon(hoodCleaningIcon);
    } else {
      setServiceIcon('');
    }
    setService(_service);
    setTruckSelect('');
    setTruckSelectKey(Math.random());
    setDriverSelect('');
    setDriverSelectKey(Math.random());
  };

  useEffect(() => {
    setTruckSelect('');
    setTruckSelectKey(Math.random());
    setDriverSelect('');
    setDriverSelectKey(Math.random());
  }, [dateSelect]);
  const handleDriverSelect = (selectedDrivers: string) => {
    setDriverSelect(selectedDrivers);
  };

  const handleTruckSelect = (selectedTruckLabels: string) => {
    const truck = trucks.find((_truck) => _truck.label === selectedTruckLabels);
    const truckId = truck ? truck.id : undefined;

    if (truckId) {
      setTruckSelect(truckId);
      setDriverSelect('');
      setDriverSelectKey(Math.random());
    }
  };

  const handleCreateRoute = () => {
    if (!service || !dateSelect || !driverSelect || !truckSelect) {
      notifyCreateRouteError();
      return;
    }
    createRoute({
      variables: {
        service,
        date: dateSelect,
        driverId: driverSelect,
        truckId: truckSelect,
      },
    });
  };
  return (
    <>
      <Breadcrumb title={breadcrumbTitle} image={clientIcon} />
      <h1 className="mt-6 text-xl font-[600]">{t('modalNewRoute.newRoute')}</h1>
      <Dropdown
        label="Service"
        id="service"
        icon={serviceIcon}
        onSelectChange={handleServiceFilterChange}
        options={serviceClientOptions}
        selectedValue="Choose an option"
        placeholder="Choose an option"
      />
      <div className="my-2 h-[60px]">
        <DateField
          selectedValue={
            dateSelect ? new Date(dateSelect).getTime().toString() : ''
          }
          onSelectChange={(date: any) => {
            setDateSelect(convertDateToString(date));
          }}
          placeholder={t('modalNewRoute.datePlaceholder')}
          label={t('modalNewRoute.date')}
          onlyActualDate={true}
        />
      </div>
      {service && dateSelect && (
        <Dropdown
          label="Truck"
          key={truckSelectKey}
          onSelectChange={handleTruckSelect}
          options={trucks}
          id="truck"
          selectedValue={truckSelect}
        />
      )}
      {dateSelect && truckSelect && (
        <Dropdown
          label="Driver"
          key={driverSelectKey}
          onSelectChange={handleDriverSelect}
          options={drivers}
          id="driver"
          selectedValue={driverSelect}
        />
      )}
      <div className="mt-6 flex justify-end">
        <Button
          text={t('modalNewRoute.save')}
          variant="green"
          icon={saveBigIcon}
          disabled={createRouteLoading}
          loading={createRouteLoading}
          onClick={handleCreateRoute}
          type="button"
          id="save-create-new-route"
        />
      </div>
    </>
  );
};

export default NewRoute;
