import { useRecoilState } from 'recoil';

import closeIcon from '../../assets/icons/close-square.svg';
import iconVerify from '../../assets/icons/verify.svg';
import { successModalStateAtom } from '../../recoil/modalSuccess';

function ModalSuccess() {
  const [modalState, setModalState] = useRecoilState(successModalStateAtom);

  const handleCancel = () => {
    setModalState({ ...modalState, isOpen: false });
  };

  return (
    <div>
      <div className="fixed inset-0 z-[100] flex items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-800 opacity-50"
          onClick={handleCancel}
        ></div>
        <div className="fixed left-1/2 top-1/2 w-[350px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[20px] bg-white p-6 shadow-lg md:px-10 md:py-14">
          <button
            onClick={handleCancel}
            className="absolute right-2 top-2 m-2 cursor-pointer text-lg text-gray-500"
          >
            <img src={closeIcon} alt="close icon" />
          </button>
          <div className="flex flex-col items-center justify-center gap-2">
            <img src={iconVerify} alt="warning icon" />
            <p className="mt-4 text-center text-lg font-bold">
              {modalState.title}
            </p>
            <p className="mb-4 mt-2 text-center text-xs">
              {modalState.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSuccess;
