import { atom } from 'recoil';

export interface LocationFilters {
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  limit?: number | null;
  offset?: number | null;
  [key: string]: string | string[] | boolean | null | number | undefined;
}

export const locationFilter = atom<LocationFilters | null>({
  key: '_locationFilters',
  default: {
    orderBy: 'name',
    orderDirection: 'DESC',
  },
});
