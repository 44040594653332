import { useSetRecoilState } from 'recoil';

import iconPhone from '../../../../assets/icons/call.svg';
import { useModal } from '../../../../hooks/useModal';
import { vendorDriverToEdit } from '../../../../recoil/dataToEdit';
import type { SingleVendorDriver } from '../../../../types/vendors';

type Props = {
  driver: SingleVendorDriver;
};

const VendorDriverCard = ({ driver }: Props) => {
  const setVendorSelected = useSetRecoilState(vendorDriverToEdit);
  const { handleOpenNewVendorDriverModal } = useModal();

  const handleOpenModalEditPeople = () => () => {
    setVendorSelected(driver);
    handleOpenNewVendorDriverModal();
  };

  return (
    <button
      onClick={handleOpenModalEditPeople()}
      id={driver.name.replace(/\s/g, '')}
      className="box-shadow flex h-[82px] w-[250px]  flex-col items-center justify-center gap-[0.2rem] rounded-lg px-6 py-4"
    >
      <p className="text-xs font-semibold ">{driver.name}</p>
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={iconPhone} alt="icon phone" />
        <p className="text-xs">{driver.phone}</p>
      </div>
    </button>
  );
};

export default VendorDriverCard;
