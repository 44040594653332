import { atom } from 'recoil';

export interface TVendorsFilters {
  vendorNameFilter?: string | null;
  statusFilter?: string[] | null;
  lastDropoffStartDateFilter?: string | null;
  lastDropoffEndDateFilter?: string | null;
  averageMiuMinFilter?: number | null;
  averageMiuMaxFilter?: number | null;
  pricePerPoundMinFilter?: number | null;
  pricePerPoundMaxFilter?: number | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  [key: string]: string | string[] | boolean | number | null | undefined;
}

export const vendorsFiltersState = atom<TVendorsFilters | null>({
  key: '_vendorsFiltersState',
  default: {
    averageMiuMinFilter: null,
    averageMiuMaxFilter: null,
    pricePerPoundMinFilter: null,
    pricePerPoundMaxFilter: null,
  },
});
