/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-case */
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PhoneIcon } from '../../../../assets/icons/call.svg';
import { ReactComponent as EraserIcon } from '../../../../assets/icons/ereaser.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg';
import {
  FAT_BONES_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
} from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { UPDATE_STOP } from '../../../../services/graphql/stops/stops-mutation';
import InputCardField from '../../common/Inputs/InputCardField';

type StopCardAddedProps = {
  businessName: string;
  phoneNumber: string;
  address: string;
  estGallons?: number;
  estBarrels?: number;
  id: string;
  clientId?: string | undefined;
  serviceId?: string | undefined;
  mutation: (arg: any) => void;
  existingStops: any[] | undefined;
  routeId: string;
  totalStopsEstimatedBarrels?: number;
  totalStopsEstimatedGallons?: number;
  service: string;
  refetchTruckCapacity?: () => void;
};

const StopCardAdded: React.FC<StopCardAddedProps> = ({
  businessName,
  phoneNumber,
  address,
  estGallons,
  id,
  mutation,
  clientId,
  serviceId,
  existingStops,
  routeId,
  totalStopsEstimatedGallons,
  totalStopsEstimatedBarrels,
  service,
  refetchTruckCapacity,
  estBarrels,
}) => {
  const { t } = useTranslation('common');

  const { notifySaveSuccess, notifySaveError } = useNotification();

  const [updateStop] = useMutation(UPDATE_STOP);
  const [stopEstGallons, setStopEstGallons] = useState(estGallons || 0);
  const [stopEstBarrels, setStopEstBarrels] = useState(estBarrels || 0);
  const stopId = id;

  const handleFieldChange = (field: string, value: number) => {
    switch (field) {
      case 'estimated_gallons':
        setStopEstGallons(value);
        break;

      case 'estimated_barrel':
        setStopEstBarrels(value);
        break;
    }
  };
  const handleSubmit = (
    field: string,
    value: number,
    existingValue: number,
  ) => {
    if (value !== existingValue) {
      updateStop({
        variables: {
          key: field,
          value: value.toString(),
          stopId,
          // TODO> tener en cuenta que enviamos gallons a pounds
          estimated_gallons: totalStopsEstimatedGallons || 0,
          estimated_barrel: totalStopsEstimatedBarrels || 0,
        },
        onCompleted: () => {
          notifySaveSuccess();
          if (refetchTruckCapacity) {
            refetchTruckCapacity();
          }
        },
        onError: () => {
          notifySaveError();
        },
      });
    }
  };

  const handleRemove = () => {
    const updatedStops = existingStops
      ? existingStops.filter(
          (stop) =>
            stop.client_id !== clientId || stop.service_id !== serviceId,
        )
      : [];

    mutation({
      variables: {
        routeId,
        stops: updatedStops,
      },
    });
  };

  useEffect(() => {
    setStopEstGallons(estGallons || 0);
  }, [estGallons]);

  useEffect(() => {
    setStopEstBarrels(estBarrels || 0);
  }, [estBarrels]);

  return (
    <div className="mb-10 flex w-full flex-col items-start justify-start gap-[15px] rounded-[10px] bg-white p-2.5 shadow">
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="shrink grow basis-0 text-xs font-semibold text-zinc-800">
          {businessName}
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-2.5 self-stretch">
        <div className="flex min-w-[80px] max-w-[120px] items-center justify-start gap-1 self-stretch">
          <PhoneIcon />
          <p className="truncate text-xs">{phoneNumber}</p>
        </div>

        <div className="flex min-w-[100px] max-w-[800px] items-center justify-start gap-1 self-stretch">
          <LocationIcon />
          <p className="truncate text-xs">{address}</p>
        </div>
      </div>
      <div className="w-full">
        {service !== HOOD_CLEANING_SERVICE_NAME &&
          service !== FAT_BONES_SERVICE_NAME && (
            <InputCardField
              type="number"
              label={t('routes.route-card-estimated-gallons')}
              unitGal={true}
              format="number"
              placeholder={stopEstGallons}
              selectedValue={stopEstGallons}
              onSelectChange={(value) =>
                handleFieldChange('estimated_gallons', Number(value))
              }
              onSave={() =>
                handleSubmit(
                  'estimated_gallons',
                  stopEstGallons || 0,
                  estGallons || 0,
                )
              }
              id="estimated-gallons-input-route-edit-field"
            />
          )}
        {service === FAT_BONES_SERVICE_NAME && (
          <InputCardField
            type="number"
            label={t('routes.route-card-estimated-barrel')}
            format="number"
            placeholder={stopEstBarrels}
            selectedValue={stopEstBarrels}
            onSelectChange={(value) =>
              handleFieldChange('estimated_barrel', Number(value))
            }
            onSave={() =>
              handleSubmit(
                'estimated_barrel',
                stopEstBarrels || 0,
                estBarrels || 0,
              )
            }
            id="estimated-gallons-input-route-edit-field"
          />
        )}
      </div>
      <div className="flex w-full items-center justify-end gap-[15px]">
        <button
          onClick={handleRemove}
          className="group flex h-[31px] w-full cursor-pointer flex-row items-center justify-center gap-2 rounded-lg bg-redish text-white hover:bg-gold hover:text-black"
        >
          <EraserIcon className="h-[20px] w-[20px]" />
          <p className="text-xs">{t('routes.remove')}</p>
        </button>
      </div>
    </div>
  );
};

export default StopCardAdded;
