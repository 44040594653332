import { useTranslation } from 'react-i18next';

import iconHood from '../../../../assets/icons/cooker-hood.svg';
import iconGrease from '../../../../assets/icons/grease-trap.svg';
import iconOil from '../../../../assets/icons/oil-disposal-timeline.svg';
import iconDocument from '../../../../assets/icons/timeline-document.svg';
import iconNote from '../../../../assets/icons/timeline-note.svg';
import iconStatus from '../../../../assets/icons/timeline-status.svg';
import iconVisit from '../../../../assets/icons/timeline-visit.svg';
import iconDeleted from '../../../../assets/icons/trash-black.svg';
import iconTruck from '../../../../assets/icons/truck.svg';
import iconDropoffs from '../../../../assets/icons/vendor-dropoffs.svg';
import iconGal from '../../../../assets/icons/weight.svg';
import {
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../../config/constants';
import DocumentActions from '../../common/DocumentActions';

type Props = {
  title: string;
  description?: string;
  type: string;
  author?: string;
  date?: string;
  id?: any | null;
  deleteDocument?: (options: { variables: { id: string } }) => void;
  documentDeleted?: boolean;
};

const TimelineCard = ({
  type,
  title,
  author,
  date,
  description,
  id,
  deleteDocument,
  documentDeleted,
}: Props) => {
  const { t } = useTranslation('common');

  const visitServiceIcon = () => {
    if (description?.includes(OIL_DISPOSAL_SERVICE_NAME)) return iconOil;
    if (description?.includes(GREASE_TRAP_SERVICE_NAME)) return iconGrease;
    if (description?.includes(HOOD_CLEANING_SERVICE_NAME)) return iconHood;
    if (description?.includes('Dropoff')) return iconDropoffs;
    return iconOil;
  };

  const imageSwitch = (_type: string) => {
    switch (_type) {
      case 'Client':
        return iconStatus;
      case 'Vendor':
        return iconStatus;
      case 'ClientPeople':
        return iconStatus;
      case 'Document':
        return iconDocument;
      case 'Notes':
        return iconNote;
      case 'Visits':
        return iconVisit;
      default:
        return iconNote;
    }
  };

  if (type === 'Visits') {
    const newDescriptionDriver = description?.split('-')[0];
    const newDescriptionService = description?.split('-')[1];
    const newDescriptionDate = description?.split('-')[2];

    return (
      <div className="my-3 flex w-full items-start gap-3" id="timelineDiv">
        <img
          src={imageSwitch(type)}
          className="h-[26px] w-[26px]"
          alt="clients icon"
        />
        <div className="flex w-full flex-col gap-1 ">
          <h1 className="text-xs font-medium">{title}</h1>
          {description && (
            <div className="flex w-full">
              <div className="flex items-center gap-1">
                <img className="h-[16px]" src={iconTruck} alt="icon truck" />
                <span className="text-sm">
                  {newDescriptionDriver?.trim()}{' '}
                </span>{' '}
              </div>
              <div className="flex items-center gap-1">
                &nbsp;{'·'}
                <img
                  className="h-[16px]"
                  src={visitServiceIcon()}
                  alt="icon service"
                />
                <span className="text-sm">{newDescriptionService?.trim()}</span>
              </div>
              {newDescriptionService?.trim() === 'Grease Trap' ||
                newDescriptionService?.trim() === 'Oil Disposal' ||
                (newDescriptionService?.trim() === 'Dropoff' && (
                  <div className="flex items-center gap-1">
                    {' · '}
                    <img className="h-[16px]" src={iconGal} alt="icon gal" />
                    <span className="text-sm">{newDescriptionDate}</span>
                  </div>
                ))}
            </div>
          )}
          <div>
            <span className="text-sm">{author && author}</span> ·{' '}
            <span className="text-sm">{date && date}</span>
          </div>
        </div>
      </div>
    );
  }

  if (
    type === 'Notes' ||
    type === 'Client' ||
    type === 'Vendor' ||
    type === 'VendorDriver' ||
    type === 'VendorNote' ||
    type === 'VendorTruck' ||
    type === 'VendorDocument' ||
    type === 'VendorDropoff' ||
    type === 'ClientPeople' ||
    type === 'ClientService'
  ) {
    return (
      <div className="my-3 flex w-full items-start gap-3" id="timelineDiv">
        <img
          src={imageSwitch(type)}
          className="h-[26px] w-[26px]"
          alt="clients icon"
        />
        <div className="flex flex-col gap-1">
          <h1 className="text-xs font-medium">{title}</h1>
          {description && <span className="text-sm">{description}</span>}
          <div className="flex items-center gap-1">
            <span className="text-sm">{author && author}</span> ·{' '}
            <span className="text-sm">{date && date}</span>
          </div>
        </div>
      </div>
    );
  }

  if (type === 'Document') {
    return (
      <div className="my-3 flex w-full items-start gap-3" id="timelineDiv">
        <img
          src={imageSwitch(type)}
          className="h-[26px] w-[26px]"
          alt="clients icon"
        />
        <div className="flex w-full flex-col gap-1">
          <h1 className="text-xs font-medium">{title}</h1>
          <div className="flex justify-between">
            <div className="flex items-center gap-1">
              <span className="text-sm">{author && author}</span> ·{' '}
              <span className="text-sm">{date && date}</span>
            </div>

            {/* eslint-disable-next-line no-nested-ternary */}
            {documentDeleted ? (
              <div className="flex items-center gap-2">
                <img
                  src={iconDeleted}
                  className="opacity-20"
                  alt="icon delete"
                />
                <p className="text-xs text-graylight">{t('global.deleted')}</p>
              </div>
            ) : !title.includes('Document removed') ? (
              <DocumentActions
                documentName={title}
                deleteDocument={deleteDocument}
                documentId={id}
                url={title.split(':')[1]?.trim()}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default TimelineCard;
