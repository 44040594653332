import { selector } from 'recoil';

import type { VendorDocumentsFilters } from './vendorDocumentsFilter';
import { vendorDocumentsFiltersState } from './vendorDocumentsFilter';

export const cleanVendorsDocumentsFiltersState = selector({
  key: 'cleanVendorsDocumentsFiltersState',
  get: ({ get }) => {
    const currentFilters = get(vendorDocumentsFiltersState);

    // Clean the filters and return a new copy with everything null
    const cleanedFilters: VendorDocumentsFilters = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
