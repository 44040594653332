import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import callIcon from '../../assets/icons/call.svg';
import emailIcon from '../../assets/icons/email.svg';
import nameIcon from '../../assets/icons/user-square.svg';
import workersIcon from '../../assets/icons/workers.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import Dropdown from '../../components/ui/common/Inputs/Dropdown';
import InputField from '../../components/ui/common/Inputs/InputField';
import { Signature } from '../../components/ui/common/Signature';
import { USER_ROLES } from '../../config/constants';
import { useNotification } from '../../hooks/useNotification';
import {
  UPDATE_SIGNATURE,
  UPDATE_USER,
} from '../../services/graphql/user/user-mutations';
import { GET_USER_BY_ID } from '../../services/graphql/user/user-query';
import type { UserQuery } from '../../types/user';

const WorkerDetailView = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const { id } = useParams();

  const { data, refetch } = useQuery<UserQuery>(GET_USER_BY_ID, {
    variables: { id },
  });

  const [signature, setSignature] = useState<File | null>(null);
  const [signatureImage, setSignatureImage] = useState<string>();

  const worker = data?.getUser?.data;

  // === MUTATIONS ===
  const [updateUser] = useMutation<any>(UPDATE_USER, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
      refetch();
    },
  });

  const [updateSignature] = useMutation<any>(UPDATE_SIGNATURE, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: async ({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      updateSignOrProfile: { data },
    }: {
      updateSignOrProfile: { data: string };
    }) => {
      notifySaveSuccess();
      await refetch();
      setSignatureImage(data);
    },
  });

  useEffect(() => {
    if (data?.getUser?.data && !signature)
      setSignatureImage(data?.getUser?.data.signature);
  }, [data]);

  // === FUNCTIONS ===
  const handleNameChange = (value: string | number) => {
    if (worker) {
      updateUser({
        variables: {
          userid: id,
          key: 'name',
          value,
        },
      });
    }
  };

  const handleEmailChange = (value: string | number) => {
    if (worker) {
      updateUser({
        variables: {
          userid: id,
          key: 'email',
          value,
        },
      });
    }
  };

  const handlePhoneChange = (value: string | number) => {
    if (worker) {
      updateUser({
        variables: {
          userid: id,
          key: 'phone',
          value,
        },
      });
    }
  };

  const handleRoleChange = (value: string | number) => {
    if (worker) {
      updateUser({
        variables: {
          userid: id,
          key: 'role',
          value,
        },
      });
    }
  };

  const handleSignatureChange = () => {
    if (worker) {
      setSignatureImage(undefined);
      updateSignature({
        variables: {
          file: signature,
          type: 'signature',
          id,
        },
      });
    }
  };

  return (
    <>
      <section className="">
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={t('workers.title-h1')}
            image={workersIcon}
            secondTitle={worker?.name}
          />
        </div>
        <div className="mt-10 flex flex-col gap-y-5 md:flex-row md:flex-wrap md:gap-x-16">
          <div className="flex flex-col gap-y-3.5 text-xl font-bold sm:h-[300px]">
            <p>{t('profile.basic-info')}</p>
            <div className="box-shadow flex h-full flex-col items-center gap-2 rounded-lg px-6 py-10 md:flex-row md:py-16">
              <div className="rounded-full bg-greenlight p-5">
                <img
                  src={workersIcon}
                  alt="worker icon"
                  className="img-color w-[50px]"
                />
              </div>
              <div className="ml-5 flex flex-col gap-y-2">
                {/* Name Input */}
                <div className="w-[250px]">
                  <InputField
                    type="text"
                    selectedValue={worker?.name}
                    onSelectChange={handleNameChange}
                    icon={nameIcon}
                  />
                </div>
                {/* Email Input */}
                <div className="w-[250px]">
                  <InputField
                    type="text"
                    selectedValue={worker?.email}
                    onSelectChange={handleEmailChange}
                    icon={emailIcon}
                  />
                </div>
                {/* Phone Input */}
                <div className="w-[250px]">
                  <InputField
                    type="text"
                    selectedValue={worker?.phone}
                    onSelectChange={handlePhoneChange}
                    icon={callIcon}
                  />
                </div>
                <div className="w-[250px]">
                  <Dropdown
                    options={USER_ROLES}
                    selectedValue={worker?.role}
                    onSelectChange={handleRoleChange}
                    icon={workersIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <Signature
            handleSignatureChange={handleSignatureChange}
            setSignature={setSignature}
            signature={signatureImage}
          />
        </div>
      </section>
    </>
  );
};

export default WorkerDetailView;
