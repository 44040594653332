import type { ChartData, ChartOptions } from 'chart.js';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartDataLabels,
);

type Props = {
  qty: string;
  qtyType: string;
  subTitle: string;
  thirdTitle?: string;
  data: number[];
  removeMin?: boolean;
};

const Graph = ({
  qty,
  qtyType,
  subTitle,
  thirdTitle,
  data,
  removeMin,
}: Props) => {
  const LineOptions: ChartOptions<'line'> = {
    layout: {
      padding: {
        left: 25,
        right: 25,
        top: 40,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#2E4347',
        font: {
          size: 9,
          family: 'Inter',
        },
        align: 'top',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      y: {
        // max: 300,
        min: removeMin ? Math.min(...data) * 1.5 : Math.min(...data) / 3,
        title: {
          display: false,
        },
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const dataContent: ChartData<'line'> = {
    labels: data.map(() => ''),
    datasets: [
      {
        fill: true,
        data,
        borderColor: 'rgb(46, 67, 71)',
        backgroundColor: 'rgb(213, 217, 218)',
        borderWidth: 3,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="relative flex h-[256px] w-[340px] items-end justify-center rounded-2xl shadow-xl">
      <div className="absolute left-6 top-6">
        <h1 className="text-4xl font-[900] text-greenlight">
          {qty} {qtyType}
        </h1>
        <div className="flex items-center gap-2">
          <p className="text-xs font-[600] text-blacker">{subTitle}</p>
          {thirdTitle && (
            <>
              <p className="text-xs font-[600] text-blacker">{' / '}</p>
              <p className="text-sm font-[600]">{thirdTitle}</p>
            </>
          )}
        </div>
      </div>
      <Line options={LineOptions} data={dataContent} />
    </div>
  );
};

export default Graph;
