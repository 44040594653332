import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import capacityIcon from '../../../../assets/icons/capacity.svg';
import licenseIcon from '../../../../assets/icons/licensing.svg';
import { useModal } from '../../../../hooks/useModal';
import { vendorTruckToEdit } from '../../../../recoil/dataToEdit';
import type { SingleVendorTruck } from '../../../../types/vendors';

type Props = {
  truck: SingleVendorTruck;
};

const VendorTruckCard = ({ truck }: Props) => {
  const { t } = useTranslation('common');
  const setVendorSelected = useSetRecoilState(vendorTruckToEdit);
  const { handleOpenNewVendorTruckModal } = useModal();

  const handleOpenModalEditPeople = () => () => {
    setVendorSelected(truck);
    handleOpenNewVendorTruckModal();
  };

  return (
    <button
      onClick={handleOpenModalEditPeople()}
      id={truck.id}
      className="box-shadow flex h-[122px] w-[250px] flex-col items-center justify-center gap-[0.2rem] rounded-lg px-6 py-4"
    >
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={licenseIcon} alt="icon phone" />
        <p className="text-xs">
          {t('single-vendor.license')}: {truck.license_plate}
        </p>
      </div>
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={licenseIcon} alt="icon phone" />
        <p className="text-xs">
          {t('single-vendor.tk')}: {truck.tk}
        </p>
      </div>
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={capacityIcon} alt="icon phone" />
        <p className="text-xs">
          {truck.capacity} {t('single-vendor.gallonsUnit')}
        </p>
      </div>
    </button>
  );
};

export default VendorTruckCard;
