import { gql } from '@apollo/client';

export const CREATE_VENDOR_LOAN = gql`
  mutation CreateVendorLoan($vendorId: String!, $total_amount: Float!) {
    createVendorLoan(vendorId: $vendorId, total_amount: $total_amount) {
      status
      code
      data
      error
    }
  }
`;
