import 'react-datepicker/dist/react-datepicker.css';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import saveBigIcon from '../../../../assets/icons/save.svg';
import vendorIcon from '../../../../assets/icons/vendor.svg';
import { statusVendorsOptions } from '../../../../config/constants';
import { useModal } from '../../../../hooks/useModal';
import { needRefreshState } from '../../../../recoil/needRefresh';
import type { TVendorsFilters } from '../../../../recoil/vendorsFilter';
import { vendorsFiltersState } from '../../../../recoil/vendorsFilter';
import { convertDateToString } from '../../../../utils/convertDate';
import Breadcrumb from '../../../ui/common/Breadcrumb';
import { Button } from '../../../ui/common/Button';
import DropdownMultiple from '../../../ui/common/DropdownMultiple';
import DateField from '../../../ui/common/Inputs/DateField';
import InputField from '../../../ui/common/Inputs/InputField';

const FilterVendor = () => {
  const { t } = useTranslation('common');

  // Filters
  const [filters, setFilters] = useRecoilState(vendorsFiltersState);

  const { setValue, register, watch, handleSubmit } = useForm<TVendorsFilters>({
    defaultValues: {
      averageMiuMinFilter: null,
      averageMiuMaxFilter: null,
      pricePerPoundMinFilter: null,
      pricePerPoundMaxFilter: null,
      ...filters,
    },
  });

  const {
    lastDropoffStartDateFilter,
    lastDropoffEndDateFilter,
    averageMiuMinFilter,
    averageMiuMaxFilter,
    pricePerPoundMinFilter,
    pricePerPoundMaxFilter,
  } = watch();

  // Form title
  const breadcrumbTitle = `${t('vendors.title-h1')}`;
  const breadcrumbSecondTitle = t('modalFilterVendor.filters');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFiltersSave = (data: TVendorsFilters) => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters, ...data };
    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={vendorIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalFilterVendor.filters')}
      </h1>
      <form
        onSubmit={handleSubmit(handleFiltersSave)}
        className="my-6 flex flex-col gap-2"
      >
        {/* Status Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={statusVendorsOptions}
            onChange={(val) => {
              setValue('statusFilter', val);
            }}
            label={t('modalFilterVendor.status')}
            selectedValues={watch('statusFilter') || []}
          />
        </div>
        {/* Start Date Filter Datepicker */}
        <DateField
          selectedValue={
            lastDropoffStartDateFilter
              ? new Date(lastDropoffStartDateFilter!).getTime().toString()
              : ''
          }
          onSelectChange={(date: any) => {
            setValue('lastDropoffStartDateFilter', convertDateToString(date));
          }}
          label={t('modalFilterVendor.startDateLastDropoff')}
          placeholder={t('global.select-date')}
        />

        {/* End Date Filter Datepicker */}
        <DateField
          selectedValue={
            lastDropoffEndDateFilter
              ? new Date(lastDropoffEndDateFilter!).getTime().toString()
              : ''
          }
          onSelectChange={(date: any) => {
            setValue('lastDropoffEndDateFilter', convertDateToString(date));
          }}
          label={t('modalFilterVendor.endDateLastDropoff')}
          placeholder={t('global.select-date')}
        />

        <div className="flex w-full flex-row gap-x-5">
          <InputField
            type="number"
            doNotUseSaveSelfButton
            label={t('modalFilterVendor.averageMiuMinFilter')}
            register={register}
            registerName="averageMiuMinFilter"
            selectedValue={averageMiuMinFilter}
          />
          <InputField
            type="number"
            doNotUseSaveSelfButton
            label={t('modalFilterVendor.averageMiuMaxFilter')}
            register={register}
            registerName="averageMiuMaxFilter"
            selectedValue={averageMiuMaxFilter}
          />
        </div>
        <div className="flex w-full flex-row gap-x-5">
          <InputField
            type="number"
            doNotUseSaveSelfButton
            label={t('modalFilterVendor.pricePerPoundMinFilter')}
            register={register}
            registerName="pricePerPoundMinFilter"
            selectedValue={pricePerPoundMinFilter}
          />

          <InputField
            type="number"
            doNotUseSaveSelfButton
            label={t('modalFilterVendor.pricePerPoundMaxFilter')}
            register={register}
            registerName="pricePerPoundMaxFilter"
            selectedValue={pricePerPoundMaxFilter}
          />
        </div>
        <div className="flex justify-end">
          <Button
            text={t('modalNewVendor.save')}
            variant="green"
            icon={saveBigIcon}
            type="submit"
            id="save-vendor-filters"
          />
        </div>
      </form>
    </>
  );
};

export default FilterVendor;
