import { useMutation } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import workerIcon from '../../../assets/icons/personalcard.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { USER_ROLES } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { CREATE_USER } from '../../../services/graphql/user/user-mutations';
import { EMAIL_REGEX, PHONE_REGEX } from '../../../utils/RegexValidation';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Inputs/Dropdown';
import InputField from '../../ui/common/Inputs/InputField';

type Inputs = {
  name: string;
  phone: string;
  email: string;
  role: string;
};

const NewWorker = () => {
  const { t } = useTranslation('common');
  const {
    notifyEmptyWarn,
    notifySaveSuccess,
    notifySaveError,
    notifyUserExistsError,
  } = useNotification();

  // React Hook Form

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<Inputs>();

  const name = watch('name');
  const phone = watch('phone');
  const email = watch('email');
  const role = watch('role');

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: 'role',
    control,
    rules: { required: t('modalNewWorker.required') },
  });

  const breadcrumbTitle = t('modalNewWorker.newWorkerBreadcrumb');
  const breadcrumbSecondTitle = t('modalNewWorker.newWorkerTitle');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFormReset = () => {
    setValue('name', '');
    setValue('phone', '');
    setValue('email', '');
    setValue('role', '');
  };

  // Queries
  const [createUser, { loading: createUserLoading }] = useMutation<any>(
    CREATE_USER,
    {
      variables: {
        name,
        phone,
        email,
        role,
        // profile_picture,
      },
      onCompleted: (onCompletedData) => {
        if (onCompletedData.createUser.status === 'error') {
          if (
            onCompletedData.createUser.data.includes(
              'ValidationError: The user already exists.',
            )
          ) {
            notifyUserExistsError();
          } else {
            notifySaveError();
          }
        }

        if (onCompletedData.createUser.status === 'success') {
          notifySaveSuccess();
          closeOrder();
          handleFormReset();
        }
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (createUserLoading) return;

    if (data.name && data.phone && data.email && data.role) {
      createUser({
        variables: {
          name: data.name,
          phone: data.phone,
          email: data.email,
          role: data.role,
        },
      });
    }

    if (!data.name || !data.phone || !data.email || !data.role) {
      notifyEmptyWarn();
    }
  };

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={workerIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewWorker.newWorkerTitle')}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="my-6">
        <div className="h-[280px]">
          {/* Name Input */}
          <div className="h-[50px]">
            <InputField
              label={t('modalNewWorker.fullname')}
              selectedValue={name}
              doNotUseSaveSelfButton
              type="text"
              errors={errors.name}
              register={register}
              registerName="name"
              validationSchema={{
                required: t('modalNewWorker.required'),
              }}
            />
          </div>

          {/* Phone Input */}
          <div className="h-[50px]">
            <InputField
              label={t('modalNewWorker.phone')}
              selectedValue={phone}
              doNotUseSaveSelfButton
              type="text"
              errors={errors.phone}
              register={register}
              registerName="phone"
              validationSchema={{
                required: t('modalNewWorker.required'),
                pattern: {
                  value: PHONE_REGEX,
                  message: t('modalNewWorker.phone-error'),
                },
              }}
            />
          </div>
          {/* Email Input */}
          <div className="h-[50px]">
            <InputField
              label={t('modalNewWorker.email')}
              selectedValue={email}
              doNotUseSaveSelfButton
              type="text"
              errors={errors.email}
              register={register}
              registerName="email"
              validationSchema={{
                required: t('modalNewWorker.required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('modalNewWorker.email-error'),
                },
              }}
            />
          </div>
          {/* Role Input */}
          <div className="h-[50px]">
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <Dropdown
                  label={t('modalNewWorker.role')}
                  options={USER_ROLES}
                  onSelectChange={onChange}
                  selectedValue={value || ''}
                  errors={error}
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalNewWorker.save')}
            variant="green"
            type="submit"
            icon={saveBigIcon}
            disabled={createUserLoading}
            loading={createUserLoading}
            id="save-create-new-worker"
          />
        </div>
      </form>
    </>
  );
};

export default NewWorker;
