import { gql } from '@apollo/client';

export const UPDATE_VENDOR_PAYMENT = gql`
  mutation UpdateVendorPayment($key: String!, $value: String!, $id: String!) {
    updateVendorPayment(key: $key, value: $value, id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_VENDOR_PAYMENT = gql`
  mutation CreateVendorPayment($dropoffId: String!) {
    createVendorPayment(dropoffId: $dropoffId) {
      status
      code
      data
      error
    }
  }
`;
