import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import type {
  DocumentToShow,
  GetDropoffDocumentByClientIdQuery,
} from '@/types/document';
import type { ClientsDocumentTable } from '@/types/tables';

import sortIcon from '../../../../assets/icons/sort.svg';
import { CLIENTS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { clientDocumentsFiltersState } from '../../../../recoil/clientDocumentsFilter';
import { GET_DROPOFF_DOCUMENTS } from '../../../../services/graphql/document/document.queries';
import {
  DOCUMENT_DELETE,
  DOCUMENT_DROPOFF_UPLOAD,
} from '../../../../services/graphql/document/document-mutations';
import DocumentActions from '../../common/DocumentActions';
import FileUploader from '../../common/FileUploader';

interface DTDropoffDocumentsProps {
  dropoffId: string | undefined;
  vendorId: string | undefined;
}
const DTDropoffsDocuments: React.FC<DTDropoffDocumentsProps> = ({
  dropoffId,
  vendorId,
}) => {
  const { t } = useTranslation('common');
  const { notifyDeleteError, notifyDeleteOk } = useNotification();

  // States

  const [filters, setFilters] = useRecoilState(clientDocumentsFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLES_MAX_RESULTS,
  });
  const [key, setKey] = useState(0);
  const [data, setData] = useState<DocumentToShow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);

  // ==== VISUAL NOTIFICATIONS ====

  const notifyDocumentUploaded = () => {
    toast.success(t('clients-overall.notify-document-uploaded'), {
      toastId: 'notifyDocumentUploaded',
    });
  };
  const notifyDocumentError = () => {
    toast.error(t('clients-overall.notify-document-error'));
  };

  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const handleFileChange = (file: File | null) => {
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleTypeChange = (type: string | null) => {
    setSelectedFileType(type);
  };

  const {
    data: fetchData,
    error,
    refetch: refetchDocuments,
  } = useQuery<GetDropoffDocumentByClientIdQuery>(GET_DROPOFF_DOCUMENTS, {
    variables: {
      dropoffId,
    },
  });

  const [uploadDocument] = useMutation<any>(DOCUMENT_DROPOFF_UPLOAD, {
    onError: () => {
      notifyDocumentError();
      setSelectedFile(null);
      setSelectedFileType(null);
    },
    onCompleted: () => {
      notifyDocumentUploaded();
      setSelectedFile(null);
      setSelectedFileType(null);
      refetchDocuments();
    },
  });
  const submitFile = () => {
    if (selectedFile) {
      uploadDocument({
        variables: {
          file: selectedFile,
          dropoffId,
          vendorId,
          type: selectedFileType,
        },
        context: {
          headers: {
            'content-type': 'multipart/form-data',
            'x-apollo-operation-name': 'uploadFile',
          },
        },
      }).then(() => {
        setKey((prevKey) => prevKey + 1);
      });
    }
  };
  const [deleteDocument] = useMutation(DOCUMENT_DELETE, {
    onError: () => {
      notifyDeleteError();
    },
    onCompleted: () => {
      refetchDocuments();
      notifyDeleteOk();
    },
  });

  useEffect(() => {
    if (
      fetchData &&
      fetchData.getDropoffDocuments &&
      fetchData.getDropoffDocuments.data
    ) {
      const updatedData: DocumentToShow[] =
        fetchData.getDropoffDocuments.data.map((document) => ({
          id: document.id,
          name: document.name || '',
          type: document.type || '',
          date: document.date || '',
          url: document.url || '',
        }));
      setData(updatedData);
      setTotal(fetchData.getDropoffDocuments.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<ClientsDocumentTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-bold">{info.getValue()}</span>
        ),
        header: ({ header }) => (
          <div className="flex items-center gap-2">
            <span className="text-xs font-medium">
              {t('client-documents.th-document-name')}
            </span>
            <button
              onClick={() => {
                handleChangeSortDirection(header.id);
              }}
            >
              <img src={sortIcon} alt="sort icon" />
            </button>
          </div>
        ),
      }),
      columnHelper.accessor('type', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="truncate text-xs font-bold">{info.getValue()}</span>
        ),

        header: ({ header }) => (
          <div className="flex items-center gap-2">
            <span className="text-xs font-medium">
              {t('client-documents.th-document-type')}
            </span>
            <button
              onClick={() => {
                handleChangeSortDirection(header.id);
              }}
            >
              <img src={sortIcon} alt="sort icon" />
            </button>
          </div>
        ),
      }),
      columnHelper.accessor('url', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.id}
            documentName={info.row.original.name}
            url={info.getValue()}
            deleteDocument={deleteDocument}
          />
        ),
        header: () => (
          <div className="flex items-center gap-2">
            <span className="text-xs font-medium">
              {t('client-documents.th-document')}
            </span>
          </div>
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  useEffect(() => {
    refetchDocuments({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, pageIndex, pageSize]);

  return (
    <div className="mt-4">
      <table
        style={{ tableLayout: 'fixed', width: '100%' }}
        className="w-full table-fixed"
      >
        <thead className="h-[37px] bg-green text-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                let className = '';

                if (index === 0) {
                  className = 'rounded-l-lg';
                } else if (index === headerGroup.headers.length - 1) {
                  className = 'rounded-r-lg';
                }

                return (
                  <th
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                    }}
                    key={header.id}
                    className={`px-2 text-left ${className}`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {error ? (
            <tr>
              <td colSpan={table.getAllColumns().length}>
                <p>Error: {error.message}</p>
              </td>
            </tr>
          ) : (
            total === 0 && (
              <tr className="h-[40px]">
                <td colSpan={table.getAllColumns().length}>
                  <p className="ml-[20px] text-xs ">{t('global.no-data')}</p>
                </td>
              </tr>
            )
          )}
          {table.getRowModel().rows.map((row) => (
            <tr
              className="h-[59px] border-b-[1px] border-graylight hover:bg-graydark"
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="cell-class truncate px-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header, index) => {
                let className = '';

                if (index === 0) {
                  className = 'rounded-l-lg';
                } else if (index === footerGroup.headers.length - 1) {
                  className = 'rounded-r-lg';
                }

                return (
                  <th key={header.id} className={`px-2 ${className}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
      <FileUploader
        onFileChange={handleFileChange}
        onFileTypeChange={handleTypeChange}
        submitFile={submitFile}
        key={key}
      />
    </div>
  );
};
export default DTDropoffsDocuments;
