import { gql } from '@apollo/client';

export const GET_VENDOR_LOANS = gql`
  query GetVendorLoans(
    $vendorId: String!
    $limit: Int
    $offset: Int
    $startDate: Date
    $endDate: Date
  ) {
    getVendorLoans(
      vendorId: $vendorId
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      code
      data {
        id
        vendor_id
        total_amount
        amount_paid
        balance
        createdAt
      }
      error
      count
    }
  }
`;
