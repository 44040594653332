import { useTranslation } from 'react-i18next';

import type { StopToUpdate } from '@/types/route';
import type { SuggestedVisit } from '@/types/service';

import phoneIcon from '../../../../assets/icons/call.svg';
import addressIcon from '../../../../assets/icons/location.svg';
import iconRoute from '../../../../assets/icons/routes.svg';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { shortenWeekDay } from '../../../../utils/shortenWeekDay';
import AddButton from '../../common/AddButton';
import NewStopCardElement from './NewStopCardElement';

type Props = {
  visit: SuggestedVisit;
  isReadyToVisit?: boolean;
  mutation: (arg: any) => void;
  existingStops: any[] | undefined;
  routeId: string;
};

const NewStopCard = ({
  visit,
  mutation,
  isReadyToVisit,
  existingStops,
  routeId,
}: Props) => {
  const { t } = useTranslation('common');

  const handleUpdate = () => {
    const newStop: StopToUpdate = {
      stopId: '',
      client_id: visit.client_id,
      service_id: visit.id,
      estimated_gallons: visit.container_size || 0,
      estimated_barrel: visit.barrel_number || 0,
    };

    const updatedStops = existingStops
      ? [...existingStops, newStop]
      : [newStop];

    mutation({
      variables: {
        routeId,
        stops: updatedStops,
      },
    });
  };

  return (
    <div className="my-2 flex w-full flex-col gap-2 rounded-lg p-4 shadow-lg">
      <div className="flex justify-between">
        <h2 className="text-xs font-semibold">{visit.client_name}</h2>
      </div>
      <div className="flex flex-col items-start gap-2">
        <div className="flex min-w-[110px] gap-1">
          <img src={phoneIcon} alt="phone icon" />
          <span className="text-xs">{visit.client_phone}</span>
        </div>
        <div className="flex min-w-[50px] max-w-[800px] gap-1 self-stretch">
          <img src={addressIcon} alt="address icon" />
          <span className="truncate text-xs">{visit.client_address}</span>
        </div>
      </div>

      <div className="flex flex-col flex-wrap gap-4">
        {/* Estimated Gallons */}
        {visit.name !== 'Meat, Fat & Bone' && (
          <NewStopCardElement
            title={t('routes.route-card-estimated-gallons')}
            value={visit.container_size}
          />
        )}
        {visit.name === 'Meat, Fat & Bone' && (
          <>
            <NewStopCardElement
              title={t('routes.route-card-estimated-barrel')}
              value={`${visit.barrel_number || 0} Barrels`}
            />
            <NewStopCardElement
              title={t('routes.route-card-estimated-barrel-size')}
              value={`${visit.barrel_size || 0} Gal`}
            />
          </>
        )}

        {/* Route Zone */}
        <NewStopCardElement
          title={t('routes.route-zone')}
          value={visit.route_zone}
        />

        {/* Available days */}
        <NewStopCardElement
          title={t('routes.available-days')}
          value={shortenWeekDay(visit.available_days)}
        />

        {/* Frequency */}
        {!isReadyToVisit && (
          <NewStopCardElement
            title={t('routes.frequency')}
            value={visit.frequency}
          />
        )}

        {/* Last Service */}
        {!isReadyToVisit && (
          <NewStopCardElement
            title={t('routes.last-service')}
            value={convertToAmericanDateFormat(visit.last_service)}
          />
        )}
        {/* Future Dates */}
        <NewStopCardElement
          title={t('routes.future-dates')}
          value_list={
            visit.future_dates.length > 0
              ? visit.future_dates.map((d) => convertToAmericanDateFormat(d))
              : ['-']
          }
        />

        <AddButton
          handleAction={handleUpdate}
          height={31}
          icon={iconRoute}
          text={t('routes.add-stop')}
        />
      </div>
    </div>
  );
};

export default NewStopCard;
