// En tu archivo de estado Recoil
import { atom } from 'recoil';

export const successModalStateAtom = atom({
  key: 'successModalStateAtom',
  default: {
    isOpen: false,
    message: '',
    title: '',
  },
});
