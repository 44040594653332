import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import vendorLogo from '../../assets/icons/vendor.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import VendorFixedCards from '../../components/ui/vendors/overall/Sidebar/VendorFixedCards';
import VendorOwnerFixedCards from '../../components/ui/vendors/overall/Sidebar/VendorOwnerFixedCards';
import VendorPaymentFixedCards from '../../components/ui/vendors/overall/Sidebar/VendorPaymentFixedCards';
import { useNotification } from '../../hooks/useNotification';
import { useVendorSelected } from '../../hooks/useVendorSelected';
import { needRefreshState } from '../../recoil/needRefresh';
import { UPDATE_VENDOR } from '../../services/graphql/vendors/vendorsMutation';
import { GET_VENDOR_SIDEBAR } from '../../services/graphql/vendors/vendorsQuery';
import type { GetVendorByIdResponse } from '../../types/vendors';

const SingleVendorLayout = ({
  children,
  title,
  breadcrumbs,
}: {
  children: JSX.Element;
  title?: string;
  breadcrumbs?: string;
}) => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const { updateData: updateSelectedVendor } = useVendorSelected();
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const { vendor } = useParams();

  const refreshOrder = () => {
    setNeedRefresh(true);
    setTimeout(() => {
      setNeedRefresh(false);
    }, 1000);
  };

  // === QUERIES ===
  const { data, refetch } = useQuery<GetVendorByIdResponse>(
    GET_VENDOR_SIDEBAR,
    {
      variables: { vendorId: vendor },
      onCompleted: (response) => {
        // This is necessary to load vendor info on reload on this page.
        // This hook needs to be on every single vendor page so the global state
        // knows what to show on the secondary menu for the vendor we currently are in.
        updateSelectedVendor(response);
      },
    },
  );

  // === MUTATIONS ===
  const [updateVendor] = useMutation<any>(UPDATE_VENDOR, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
      refetch();
      refreshOrder();
    },
  });

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('vendors.title-h1')}`}
            secondTitle={data?.getVendorById?.data.name}
            thirdTitle={breadcrumbs || title}
            image={vendorLogo}
          />
        </div>
        <div className="mt-[30px] flex w-full flex-col md:flex-row">
          {/* Vendor Business Card */}
          <div className="flex w-[260px]">
            <div className="relative min-w-[265px] max-w-[265px] ">
              <div className="z-20 flex w-[265px] flex-col gap-3 md:fixed">
                {data && vendor && (
                  <VendorFixedCards
                    vendor={vendor}
                    data={data}
                    updateVendor={updateVendor}
                  />
                )}
                {data && vendor && (
                  <VendorOwnerFixedCards
                    vendor={vendor}
                    data={data}
                    updateVendor={updateVendor}
                  />
                )}
                {data && vendor && (
                  <VendorPaymentFixedCards
                    vendor={vendor}
                    data={data}
                    updateVendor={updateVendor}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col px-6 pt-6">
            {/* Service info */}
            {title && (
              <div className="w-full">
                <h1 className="mb-2 text-xl font-semibold">{title}</h1>
              </div>
            )}

            {data?.getVendorById.data.id && children}
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleVendorLayout;
