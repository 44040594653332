import { gql } from '@apollo/client';

export const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $name: String!
    $address: String!
    $measuring_method: String!
  ) {
    createLocation(
      name: $name
      address: $address
      measuring_method: $measuring_method
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation(
    $id: String!
    $name: String!
    $address: String!
    $measuring_method: String!
  ) {
    updateLocation(
      id: $id
      name: $name
      address: $address
      measuring_method: $measuring_method
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: String!) {
    deleteLocation(id: $id) {
      status
      code
      data
      error
    }
  }
`;
