import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import locationIcon from '../../../assets/icons/location.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { MEASURING_MEHTOD_OPTIONS } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { locationSelectedToEdit } from '../../../recoil/dataToEdit';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { needRefreshState } from '../../../recoil/needRefresh';
import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
} from '../../../services/graphql/locations/locationsMutation';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Inputs/Dropdown';
import InputField from '../../ui/common/Inputs/InputField';

type Inputs = {
  id: string;
  name: string;
  address: string;
  measuring_method: 'scale' | 'meter' | '';
};

const NewLocation = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [location, setLocation] = useRecoilState(locationSelectedToEdit);

  const notifyDeleteLocationSuccess = () =>
    toast.success(t('modalNewLocation.deleteSuccess'));

  // Form
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      id: location?.id,
      name: location?.name,
      address: location?.address,
      measuring_method: location?.measuring_method,
    },
  });

  useEffect(() => {
    if (register) {
      register('measuring_method', { required: t('global.required') });
    }
  }, [register]);

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const setConfirmModalState = useSetRecoilState(confirmationModalState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const handleFormReset = () => {
    reset();
    setLocation({
      id: '',
      name: '',
      address: '',
      measuring_method: '',
    });
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
    handleFormReset();
  };

  // Query
  const [createLocation, { loading: createLocationLoading }] = useMutation<any>(
    CREATE_LOCATION,
    {
      onCompleted: () => {
        handleFormReset();
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  const [updateLocation, { loading: updatingLocation }] = useMutation<any>(
    UPDATE_LOCATION,
    {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  const [deleteLocation] = useMutation(DELETE_LOCATION);

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    if (location?.id) {
      updateLocation({
        variables: {
          ...data,
        },
      });
    } else {
      createLocation({
        variables: {
          ...data,
        },
      });
    }
  };

  const handleDeleteModal = (event: any) => {
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        closeOrder();
        deleteLocation({
          variables: { id: location?.id },
          onCompleted: () => {
            notifyDeleteLocationSuccess();
          },
        });
      },
      onCancel: () => {},
      message: t('global.are-you-sure-delete'),
    });
    event.preventDefault();
    return false;
  };

  return (
    <>
      <Breadcrumb
        title={t('modalNewLocation.title')}
        image={locationIcon}
        id="LocationModal"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewLocation.bold-title')}
      </h1>
      <form className="my-6">
        {/* Name Input */}
        <InputField
          label={t('modalNewLocation.name')}
          doNotUseSaveSelfButton
          type="text"
          selectedValue={watch('name')}
          errors={errors.name}
          register={register}
          registerName="name"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        <InputField
          label={t('modalNewLocation.address')}
          doNotUseSaveSelfButton
          type="text"
          selectedValue={watch('address')}
          errors={errors.address}
          register={register}
          registerName="address"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        <Dropdown
          label={t('modalNewLocation.measuring_method')}
          selectedValue={watch('measuring_method')}
          errors={errors.measuring_method}
          options={MEASURING_MEHTOD_OPTIONS}
          onSelectChange={(val) =>
            setValue('measuring_method', val as 'scale' | 'meter' | '')
          }
        />

        {/* Save Button */}
        <div className="mt-2 flex justify-end">
          <Button
            text={t('modalNewLocation.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={createLocationLoading}
            loading={createLocationLoading || updatingLocation}
            onClick={handleSubmit(onSubmit)}
            type="button"
            id="save-new-location"
          />
        </div>
        {location?.id}
        {location?.id && (
          <button
            className="flex w-full items-center justify-center"
            onClick={handleDeleteModal}
          >
            <div className=" flex cursor-pointer items-center justify-center text-center text-xs font-bold text-red underline">
              {t('modalNewLocation.delete')}
            </div>
          </button>
        )}
      </form>
    </>
  );
};

export default NewLocation;
