import { gql } from '@apollo/client';

export const GET_SERVICE_SUGGESTED_VISITS = gql`
  query GetSuggestedVisits(
    $limit: Int
    $orderBy: String
    $orderDirection: String
  ) {
    getSuggestedVisits(
      ready_to_visit: true
      orderBy: $orderBy
      orderDirection: $orderDirection
      limit: $limit
    ) {
      status
      code
      count
      data {
        id
        client_id
        name
        frequency
        desired_day
        last_service
        available_days
        next_scheduled
        container_size
        container_location
        barrel_size
        barrel_number
        paid_amount
        route_zone
        ready_to_visit
        createdAt
        updatedAt
        client_name
        client_address
        client_phone
      }
    }
  }
`;

export const GET_SERVICE_VISITS = gql`
  query GetServiceVisits(
    $serviceId: String!
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDirection: String
  ) {
    getServiceVisits(
      serviceId: $serviceId
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        route_id
        route_name
        status
        gallons_picked_up
        payments
        driver
        driver_id
        date
        documents {
          id
          client_id
          name
          stop_id
          truck_id
          type
          url
          date
          createdAt
          updatedAt
          deletedAt
        }
      }
      error
    }
  }
`;

export const GET_STATISTICS = gql`
  query GetStatistics($serviceId: String!) {
    getStatistics(serviceId: $serviceId) {
      status
      code
      error
      data {
        total_oil
        total_numbers
        average
        average_numbers
        container_percentage
        percentaje_numbers
      }
    }
  }
`;

export const GET_SERVICE_FOR_ROUTE_EDIT = gql`
  query GetSuggestedVisits(
    $limit: Int
    $offset: Int
    $client: String
    $ready_to_visit: Boolean
    $availableDays: [String]
    $last_serviceBefore: String
    $estimated_gallons_to: Int
    $estimated_gallons_from: Int
    $serviceType: [String]
    $route_zone: [String]
  ) {
    getSuggestedVisits(
      limit: $limit
      offset: $offset
      client: $client
      ready_to_visit: $ready_to_visit
      availableDays: $availableDays
      last_serviceBefore: $last_serviceBefore
      estimated_gallons_to: $estimated_gallons_to
      estimated_gallons_from: $estimated_gallons_from
      serviceType: $serviceType
      route_zone: $route_zone
    ) {
      status
      code
      count
      data {
        id
        client_id
        name
        frequency
        desired_day
        last_service
        available_days
        next_scheduled
        container_size
        container_location
        barrel_size
        barrel_number
        paid_amount
        route_zone
        ready_to_visit
        createdAt
        updatedAt
        client_name
        client_address
        client_phone
        future_dates
      }
    }
  }
`;
