import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import smallSettings from '../../assets/icons/smallSettings.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import DateField from '../../components/ui/common/Inputs/DateField';
import InputField from '../../components/ui/common/Inputs/InputField';
import { UPDATE_SETTING } from '../../services/graphql/settings/settingsMutation';
import { GET_SETTINGS } from '../../services/graphql/settings/settingsQuery';
import { convertDateToString } from '../../utils/convertDate';

type Settings = {
  id: number;
  name: string;
  value: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
};

const Licensing = () => {
  const { t } = useTranslation('common');
  const [settings, setSettings] = useState([]);

  useQuery(GET_SETTINGS, {
    onCompleted: (infoSettings) => {
      setSettings(infoSettings.getSettings.data);
    },
  });

  const [updateSettingDB] = useMutation(UPDATE_SETTING);

  const notifyUpdateSuccess = () => toast.success(t('settings.notifySuccess'));

  const updateSetting = async (id: string, value: string) => {
    updateSettingDB({
      variables: { id, value },
      onCompleted: () => {
        notifyUpdateSuccess();
      },
    });
  };

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('settings.title')}`}
            secondTitle={`${t('settings.licensing')}`}
            image={smallSettings}
          />
        </div>
        <div className="mt-8">
          <h1 className="flex font-inter text-xl font-bold">
            {t('settings.licensing')}
          </h1>
          <div className="mt-5">
            {settings.map((setting: any) => {
              if (setting.name.includes('EXPDATE')) return '';
              const isDate = `${setting.name}EXPDATE`;
              const findDate: any = settings.find(
                (date: Settings) => date.name === isDate,
              );
              if (findDate) {
                let date;
                if (findDate.value != null) {
                  date = new Date(findDate.value).getTime().toString();
                }
                return (
                  <div className="flex flex-row gap-5">
                    <div
                      key={setting.id}
                      className="flex max-w-[180px] flex-col gap-2"
                    >
                      <InputField
                        type="text"
                        id={setting.id}
                        label={`${setting.name}#`}
                        selectedValue={setting.value}
                        onSelectChange={(value) => {
                          updateSetting(setting.id, String(value));
                        }}
                      />
                    </div>
                    <div className="flex h-11 w-[200px] flex-col items-start justify-start gap-2.5 pl-5">
                      <DateField
                        label={`Valid License Date ${setting.name}#`}
                        placeholder={'Select date'}
                        selectedValue={date}
                        onSelectChange={(_date: any) => {
                          const dateString = convertDateToString(_date);
                          updateSetting(findDate.id, dateString);
                        }}
                        onlyActualDate={true}
                      />
                    </div>
                  </div>
                );
              }

              return (
                <div
                  key={setting.id}
                  className="flex max-w-[180px] flex-col gap-2"
                >
                  <InputField
                    type="text"
                    id={setting.id}
                    label={setting.name}
                    selectedValue={setting.value}
                    onSelectChange={(value) => {
                      updateSetting(setting.id, String(value));
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Licensing;
