import { useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import addTransactionIcon from '../../../../assets/icons/transactions.svg';
import { VENDORS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { useModal } from '../../../../hooks/useModal';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { GET_VENDOR_LOANS } from '../../../../services/graphql/vendors/loans/vendorLoansQuery';
import type { VendorsLoansTable } from '../../../../types/tables';
import type { GetVendorsLoansResponse } from '../../../../types/vendors';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { formatNumberMoney } from '../../../../utils/numberFormatter';
import AddButton from '../../common/AddButton';
import DataTable from '../../common/DataTable/DataTable';
import DTHeader from '../../common/DataTable/DTHeader';
import RangeDateField from '../../common/Inputs/RangeDateField';

type TFilters = {
  startDate: number;
  endDate: number;
};

const DTVendorsLoans = ({ vendorId }: { vendorId?: string }) => {
  const { t } = useTranslation('common');

  const [filters, setFilters] = useState<TFilters>({
    startDate: dayjs().subtract(7, 'days').valueOf(),
    endDate: dayjs().valueOf(),
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: VENDORS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<VendorsLoansTable[]>([]);
  const [total, setTotal] = useState<number>(0);

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchVendors,
  } = useQuery<GetVendorsLoansResponse>(GET_VENDOR_LOANS, {
    variables: {
      vendorId,
      limit: pageSize,
      offset: pageIndex * pageSize,
    },
    skip: !vendorId,
  });

  useEffect(() => {
    if (fetchData && fetchData.getVendorLoans) {
      setData(fetchData.getVendorLoans.data);
      setTotal(fetchData.getVendorLoans.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<VendorsLoansTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('total_amount', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="cell-class text-xs font-bold">
            {formatNumberMoney(info.getValue())}
          </span>
        ),
        header: () => (
          <DTHeader
            title={t('vendor-transactions.loans-table-th1')}
            accessor="total_amount"
          />
        ),
      }),
      columnHelper.accessor('amount_paid', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: () => (
          <DTHeader
            title={t('vendor-transactions.loans-table-th2')}
            accessor="amount_paid"
          />
        ),
        cell: (info) => (
          <span className="cell-class text-xs font-bold">
            {formatNumberMoney(info.getValue())}
          </span>
        ),
      }),
      columnHelper.accessor('balance', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: () => (
          <DTHeader
            title={t('vendor-transactions.loans-table-th3')}
            accessor="balance"
          />
        ),
        cell: (info) => (
          <span className="cell-class text-xs font-bold">
            {formatNumberMoney(info.getValue())}
          </span>
        ),
      }),
      columnHelper.accessor('createdAt', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue().toString())
              : '-'}
          </span>
        ),
        header: () => (
          <DTHeader
            title={t('vendor-transactions.loans-table-th4')}
            accessor="lastVisit"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  const [needRefresh] = useRecoilState(needRefreshState);

  // Need refresh (reload data on modal close, filters,...)
  useEffect(() => {
    refetchVendors({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...(filters as any),
    });
  }, [filters, pageIndex, pageSize, needRefresh]);

  // Modals
  const { handleOpenNewVendorLoanModal } = useModal();

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <div className="relative my-2 flex justify-end gap-4">
          <AddButton
            icon={addTransactionIcon}
            id="newVendorButton"
            handleAction={handleOpenNewVendorLoanModal}
            text={t('vendor-transactions.create-loan')}
          />
          <div>
            <RangeDateField
              onSelectEndChange={(val) =>
                setFilters({
                  endDate: val,
                  startDate: filters.startDate,
                })
              }
              onSelectStartChange={(val) => {
                setFilters({
                  startDate: val,
                  endDate: filters.endDate,
                });
              }}
              updateDates={(start, end) => {
                setFilters({
                  startDate: start,
                  endDate: end,
                });
              }}
              endDate={filters.endDate}
              startDate={filters.startDate}
            />
          </div>
        </div>
        <DataTable
          error={error}
          loading={loading}
          table={table}
          total={total}
          tableId="VendorsOverallTable"
          sorting={{}}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTVendorsLoans;
