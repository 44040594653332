import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';

const useDebouceTime = ({
  setFilters,
  filters,
  key,
}: {
  setFilters: any;
  filters: any;
  key: string;
}) => {
  // Debounce Name filtering
  const [filterValue, setFilterValue] = useState<string>('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleFilterChangeWithDebounce = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setFilterValue(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      let newFilters: Record<string, any> = {};

      newFilters = { ...filters };

      if (inputValue) {
        newFilters[key] = inputValue;
      } else {
        newFilters[key] = null;
      }

      setFilters(newFilters);
    }, 500);
  };
  return { filterValue, handleFilterChangeWithDebounce, setFilterValue };
};

export default useDebouceTime;
