import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { UsersQuery, UserToShow } from '@/types/user';

import addWorkerIcon from '../../../assets/icons/workers.svg';
import { USERS_TABLES_MAX_RESULTS } from '../../../config/constants';
import useDebouceTime from '../../../hooks/useDebounceTime';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { workerFiltersState } from '../../../recoil/workersFilter';
import { cleanWorkersFiltersState } from '../../../recoil/workersFilterClean';
import {
  DELETE_USERS,
  UPDATE_USER,
} from '../../../services/graphql/user/user-mutations';
import { GET_USERS } from '../../../services/graphql/user/user-query';
import type { WorkersTable } from '../../../types/tables';
import { convertToAmericanDateFormat } from '../../../utils/convertTimestamp';
import AddButton from '../common/AddButton';
import DataTable from '../common/DataTable/DataTable';
import DTHeader from '../common/DataTable/DTHeader';
import DeleteButton from '../common/DeleteButton';
import FilterMark from '../common/FilterMark';
import Searchbar from '../common/Searchbar';
import WorkerStatusDropdown from './WorkerStatusDropdown';

const DTWorkers = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const {
    notifyUpdateError,
    notifyUpdateOk,
    notifyDeleteError,
    notifyDeleteOk,
    notifyNoSelection,
  } = useNotification();

  // States

  const [filters, setFilters] = useRecoilState(workerFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [deleteIconState, setDeleteIconState] = useState<boolean>(false);

  const cleanFilters = useRecoilValue(cleanWorkersFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: USERS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<UserToShow[]>([]);
  const [total, setTotal] = useState<number>(0);

  const handleRowClick = (userId: string, event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isCellClick = target.classList.contains('cell-class');

    if (isCellClick) {
      // Go to truck
      navigate(`/dashboard/workers/${userId}`);
    }
  };

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchWorkers,
  } = useQuery<UsersQuery>(GET_USERS, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'name',
      orderDirection: filters?.orderDirection || 'ASC',
    },
  });

  const [updateStatus] = useMutation<any>(UPDATE_USER, {
    onCompleted: () => {
      refetchWorkers({ limit: pageSize, offset: pageIndex * pageSize });
      notifyUpdateOk();
    },
    onError: () => {
      notifyUpdateError();
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getUsers) {
      const updatedData: UserToShow[] = fetchData.getUsers.data.map(
        (userFetched) => ({
          id: userFetched.id,
          name: userFetched.name,
          status: userFetched.status || '',
          role: userFetched.role || '',
          date: userFetched.createdAt || '',
        }),
      );

      setData(updatedData);
      setTotal(fetchData.getUsers.count);
    }
  }, [fetchData]);

  // Update worker status

  const columnHelper = createColumnHelper<WorkersTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        size: 30,
        minSize: 30,
        maxSize: 30,
        header: ({ table }) => (
          <input
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="table-checkbox-th flex h-[18px] w-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        cell: ({ row }) => (
          <input
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="flex h-[18px] w-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      }),
      columnHelper.accessor('name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="cell-class text-xs font-bold">
            {info.getValue()}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('workers.th-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="name"
          />
        ),
      }),
      columnHelper.accessor('status', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <div className="flex items-center">
            <WorkerStatusDropdown
              userId={info.row.original.id}
              initialStatusValue={info.getValue()}
              mutation={updateStatus}
              key={info.row.original.id}
            />
          </div>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('workers.th-status')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="status"
          />
        ),
      }),
      columnHelper.accessor('role', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('workers.th-role')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="role"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('workers.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Debounce Name filtering
  const { handleFilterChangeWithDebounce, filterValue } = useDebouceTime({
    setFilters,
    filters,
    key: 'filterName',
  });

  // Delete worker from row selection

  const [selectedWorkers, setSelectedWorkers] = useState<string[]>([]);

  useEffect(() => {
    if (table.getSelectedRowModel().flatRows.length > 0) {
      setSelectedWorkers(
        table.getSelectedRowModel().flatRows.map((row) => row.original.id),
      );
    }
  }, [table.getSelectedRowModel().flatRows]);

  const [deleteWorkers, { loading: deleteWorkersLoading }] = useMutation<any>(
    DELETE_USERS,
    {
      variables: {
        userId: selectedWorkers,
      },
      onCompleted: () => {
        refetchWorkers({ limit: pageSize, offset: pageIndex * pageSize });
        notifyDeleteOk();
        table.resetRowSelection();
        setDeleteIconState(!deleteIconState);
      },
      onError: () => {
        notifyDeleteError();
      },
    },
  );

  const handleRemoveWorkers = () => {
    if (selectedWorkers.length > 0) {
      deleteWorkers();
    } else {
      notifyNoSelection();
    }
  };

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetchWorkers({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // Modals
  const { handleOpenNewWorkerModal, handleOpenWorkersFilterModal } = useModal();

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'ASC',
      orderBy: 'name',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <div className="my-2 flex h-full w-full justify-between gap-4">
          <Searchbar
            filterValue={filterValue}
            onChange={handleFilterChangeWithDebounce}
            handleOpenFilterModal={handleOpenWorkersFilterModal}
          />
          <div className="relative flex gap-4">
            <AddButton
              handleAction={handleOpenNewWorkerModal}
              icon={addWorkerIcon}
            />
            <DeleteButton handleRemove={handleRemoveWorkers} />
          </div>
        </div>
        <div className="my-2 flex w-full flex-wrap gap-2">
          {filters &&
            Object.entries(filters).map(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                name !== 'filterName' &&
                name !== 'orderDirection' &&
                name !== 'orderBy' && (
                  <FilterMark
                    key={name}
                    filterName={name}
                    filterValue={value}
                    atom={workerFiltersState}
                  />
                ),
            )}
        </div>
        <DataTable
          error={error}
          handleRowClick={handleRowClick}
          loading={loading || deleteWorkersLoading}
          table={table}
          total={total}
          tableId="WorkersTable"
          sorting={{ ...filters }}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTWorkers;
