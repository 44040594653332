import 'react-datepicker/dist/react-datepicker.css';

import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import routeIcon from '../../../assets/icons/routes.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { receiptTypeOptions } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { routeUpdateState } from '../../../recoil/routeUpdate';
import { selectedReceipt } from '../../../recoil/selectedReceipt';
import { selectedRouteState } from '../../../recoil/selectedRoute';
import {
  DELETE_DRIVER_RECEIPT,
  UPDATE_DRIVER_RECEIPT,
} from '../../../services/graphql/receipts/receiptMutation';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Inputs/Dropdown';
import InputField from '../../ui/common/Inputs/InputField';

const EditReceipt = () => {
  const { t } = useTranslation('common');
  const selectedRoute = useRecoilValue(selectedRouteState);
  const [routeUpdateStateValue, setRouteUpdated] =
    useRecoilState(routeUpdateState);
  const receipt = useRecoilValue(selectedReceipt);
  const [receiptAmount, setReceiptAmount] = useState<number>(receipt.amount);
  const [receiptCategory, setReceiptCategory] = useState<string>(
    receipt.category,
  );
  const setConfirmModalState = useSetRecoilState(confirmationModalState);
  const { handleCloseAllModals } = useModal();
  const [updateDriverReceipt, { loading: updateReceiptLoading }] = useMutation(
    UPDATE_DRIVER_RECEIPT,
  );
  const [deleteDriverReceipt] = useMutation(DELETE_DRIVER_RECEIPT);

  const notifyUpdateReceiptError = () =>
    toast.error(t('modalEditReceipt.error'));
  const notifyUpdateRouteSuccess = () =>
    toast.success(t('modalEditReceipt.success'));
  const notifyDeleteReceiptSuccess = () =>
    toast.success(t('modalEditReceipt.deleteSuccess'));
  const closeOrder = () => {
    handleCloseAllModals();
  };
  const deleteReceipt = async () => {
    deleteDriverReceipt({
      variables: { id: receipt.id },
      onCompleted: () => {
        notifyDeleteReceiptSuccess();
        setRouteUpdated(!routeUpdateStateValue);
        closeOrder();
      },
    });
    setRouteUpdated(!routeUpdateStateValue);
  };
  const handleDeleteReceipt = () => {
    handleCloseAllModals();
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deleteReceipt();
      },
      onCancel: () => {},
      message: t('global.are-you-sure-delete'),
    });
  };

  const handleCategoryChange = (category: string) => {
    setReceiptCategory(category);
  };
  const handleAmountChange = (value: string | number) => {
    setReceiptAmount(Number(value));
  };
  const handleUpdateReceipt = () => {
    updateDriverReceipt({
      variables: {
        id: receipt.id,
        amount: receiptAmount || receipt.amount,
        category: receiptCategory || receipt.category,
      },
      onCompleted() {
        setRouteUpdated(!routeUpdateStateValue);
        notifyUpdateRouteSuccess();
        closeOrder();
      },
      onError() {
        notifyUpdateReceiptError();
      },
    });
    closeOrder();
  };
  return (
    <>
      <Breadcrumb
        title={t('modalNewReceipt.newReceiptBreadcrumbTitle')}
        secondTitle={selectedRoute?.route_name}
        thirdTitle={t('modalNewReceipt.newReceiptBreadcrumb')}
        image={routeIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewReceipt.newReceiptTitle')}
      </h1>
      <div className="my-4 flex h-[280px] w-[390px] items-center justify-center">
        {receipt.image && (
          <img
            className="h-full w-full rounded-sm object-contain"
            src={receipt.image}
            alt="Receipt"
          />
        )}
      </div>
      <Dropdown
        label={t('modalNewReceipt.category')}
        onSelectChange={handleCategoryChange}
        options={receiptTypeOptions}
        selectedValue={receiptCategory}
        placeholder={receiptCategory}
      />
      <InputField
        label="Amount"
        type="number"
        onSelectChange={handleAmountChange}
        placeholder={formatNumberMoney(receiptAmount)}
        doNotUseSaveSelfButton={true}
      />
      <div
        className="flex w-full items-center justify-center"
        onClick={handleDeleteReceipt}
      >
        <button className="flex cursor-pointer items-center justify-center text-center text-xs font-bold text-red underline">
          {t('modalEditReceipt.delete')}
        </button>
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          text={t('modalNewRoute.save')}
          variant="green"
          icon={saveBigIcon}
          onClick={handleUpdateReceipt}
          type="button"
          disabled={updateReceiptLoading}
          loading={updateReceiptLoading}
          id="edit-receipt-save-button"
        />
      </div>
    </>
  );
};

export default EditReceipt;
