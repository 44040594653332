import { gql } from '@apollo/client';

export const DOCUMENT_CLIENT_UPLOAD = gql`
  mutation UploadFile(
    $file: Upload!
    $clientId: String
    $vendorId: String
    $type: String!
    $stopId: String
  ) {
    uploadFile(
      file: $file
      clientId: $clientId
      vendorId: $vendorId
      type: $type
      stopId: $stopId
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DOCUMENT_DELETE = gql`
  mutation DeleteDocument($id: [String]) {
    deleteDocument(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const DOCUMENT_DROPOFF_UPLOAD = gql`
  mutation UploadFile(
    $file: Upload!
    $vendorId: String
    $type: String!
    $dropoffId: String
  ) {
    uploadFile(
      file: $file
      vendorId: $vendorId
      type: $type
      dropoffId: $dropoffId
    ) {
      status
      code
      data
      error
    }
  }
`;
