import { atom } from 'recoil';

type SelectedClientType = {
  name: string;
  id: string;
  services: string[] | undefined;
};

type SelectedVendorType = {
  name: string;
  id: string;
};

export const isSecondarySidebarOpen = atom({
  key: 'navigationStateAtom',
  default: false,
});

export const isVendorsSecondarySidebarOpen = atom({
  key: 'navigationVendorsStateAtom',
  default: false,
});

export const selectedClient = atom<SelectedClientType>({
  key: 'selectedClientStateAtom',
  default: {
    name: '',
    id: '',
    services: undefined,
  },
});

export const selectedVendor = atom<SelectedVendorType>({
  key: 'selectedVendorStateAtom',
  default: {
    name: '',
    id: '',
  },
});

export const isSecondarySettingsSidebarOpen = atom({
  key: 'navigationSettingStateAtom',
  default: false,
});

export const isInvoicesOpen = atom({
  key: 'navigationInvoicesStateAtom',
  default: false,
});
