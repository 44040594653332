import dayjs from 'dayjs';
import { atom } from 'recoil';

export interface TDropoffsFilters {
  vendorNameFilter?: string | null;
  statusFilter?: string[] | null;
  vendorIds?: string[] | null;
  locationId?: string[] | null;
  locationNames?: string[] | null;
  vendorNames?: string[] | null;
  totalGallons?: number[] | null;
  avgMiu?: number[] | null;
  gallonPrice?: number[] | null;
  totalPaid?: number[] | null;
  ffa?: number[] | null;
  sulfur?: number[] | null;
  startDate?: number | null;
  endDate?: number | null;
  [key: string]:
    | string
    | string[]
    | number[]
    | boolean
    | number
    | null
    | undefined;
}

export const dropoffsFiltersState = atom<TDropoffsFilters | null>({
  key: 'dropoffsFiltersState',
  default: {
    totalGallons: null,
    avgMiu: null,
    gallonPrice: null,
    totalPaid: null,
    ffa: null,
    sulfur: null,
    totalGallonsMin: null,
    totalGallonsMax: null,
    avgMiuMin: null,
    avgMiuMax: null,
    gallonPriceMin: null,
    gallonPriceMax: null,
    totalPaidMin: null,
    totalPaidMax: null,
    ffaMin: null,
    ffaMax: null,
    sulfurMin: null,
    sulfurMax: null,
    startDate: dayjs().subtract(30, 'days').valueOf(),
    endDate: dayjs().valueOf(),
  },
});
