import { useMutation } from '@apollo/client';
import React from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';
import { DROPOFF_STATUS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { UPDATE_DROPOFF } from '../../../../services/graphql/vendors/dropoffs/dropoffsMutation';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../../styles/statusDropdownStyle';

type DropoffStatusDropdownProps = {
  dropoffId: string | undefined;
  initialStatus: string;
  refetchSingleDropoff: (id: string) => void;
  setLoading: (val: boolean) => void;
};

const DropoffStatusDropdown: React.FC<DropoffStatusDropdownProps> = ({
  dropoffId,
  initialStatus,
  refetchSingleDropoff,
  setLoading,
}) => {
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const [UpdateDropoff] = useMutation(UPDATE_DROPOFF, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      refetchSingleDropoff(dropoffId!);
      notifySaveSuccess();
    },
  });
  const [status, setStatus] = React.useState(initialStatus);

  const handleStatusChange = async (selectedOption: any) => {
    const newStatus = selectedOption.value as string;
    setLoading(true);
    await UpdateDropoff({
      variables: {
        id: dropoffId,
        key: 'status',
        value: newStatus,
      },
    });

    setStatus(newStatus);
  };

  return (
    <div className="flex items-center justify-end gap-[15px]">
      <div className="flex items-center justify-center gap-1 rounded-md px-2.5">
        <Select
          styles={colourStyles(status, DROPOFF_STATUS)}
          components={{
            DropdownIndicator: () => <ArrowDownIcon />,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          options={DROPOFF_STATUS}
          onChange={handleStatusChange}
          value={DROPOFF_STATUS.find((option) => option.value === status)}
          isSearchable={false}
        />
      </div>
    </div>
  );
};
export default DropoffStatusDropdown;
