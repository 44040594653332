import { useEffect, useState } from 'react';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

import closeFilterIcon from '../../../assets/icons/close-filter.svg';
import { useFilterName } from '../../../hooks/useFilterName';
import { formatNumber } from '../../../utils/numberFormatter';

type Props = {
  filterName: string;
  filterValue: string | string[] | number[] | boolean | null | number;
  atom: RecoilState<any>;
};

const FilterMark = ({ filterName, filterValue, atom }: Props) => {
  const [filters, setFilters] = useRecoilState(atom);
  const [val, setVal] = useState<any>('');

  useEffect(() => {
    let prefix = '';
    let sufix = '';
    if (filterName === 'ffa' || filterName === 'sulfur') sufix = 'ppm';
    if (filterName === 'totalPaid' || filterName === 'gallonPrice')
      prefix = '$';
    if (filterName === 'avgMiu') sufix = '%';
    if (Array.isArray(filterValue)) {
      if (typeof filterValue[0] === 'number' && filterValue.length === 2) {
        setVal(
          filterValue.map((v) => prefix + formatNumber(v) + sufix).join(' - '),
        );
      } else setVal(filterValue.join(', '));
    } else if (typeof filterValue === 'boolean') {
      setVal(filterValue ? 'true' : 'false');
    } else {
      setVal(filterValue);
    }
  }, [filterValue]);

  const closeFilter = () => {
    const updatedFilters = { ...filters };
    updatedFilters[filterName] = null;
    if (filterName === 'locationNames') updatedFilters.locationId = null;
    if (filterName === 'vendorNames') updatedFilters.vendorIds = null;
    setFilters(updatedFilters);
  };

  return (
    <div className="flex h-[29px] items-center justify-center gap-2 rounded-xl bg-green px-2 py-1 text-xs font-light text-white">
      <span>
        {useFilterName(filterName)}: {val}
      </span>
      <button onClick={closeFilter}>
        <img src={closeFilterIcon} alt="Close filter" />
      </button>
    </div>
  );
};

export default FilterMark;
