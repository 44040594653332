export function convertToAmericanDateFormat(
  dateString: string,
  notInvalid?: boolean
): string {
  const timestamp = Number(dateString);

  if (!isNaN(timestamp)) {
    const date = new Date(parseInt(dateString, 10));
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const dateTimezone = new Date(
      date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset)
    );

    return dateTimezone.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  } else {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      const dateTimezone = new Date(
        date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset)
      );

      return dateTimezone.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } else {
      if (notInvalid) {
        return '';
      }
      return 'Invalid date';
    }
  }
}

export function convertToAmericanDateTimeFormat(dateString: string): string {
  const timestamp = Number(dateString);

  if (!isNaN(timestamp)) {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } else {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    } else {
      return 'Invalid date';
    }
  }
}

export function convertToAmericanTimeFormat(dateString: string): string {
  const timestamp = Number(dateString);

  if (!isNaN(timestamp)) {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } else {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    } else {
      return 'Invalid date';
    }
  }
}
