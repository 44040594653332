import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type {
  DocumentToShow,
  GetDocumentByVendorIdQuery,
} from '@/types/document';

import actionIcon from '../../../../assets/icons/action-button.svg';
import filterIcon from '../../../../assets/icons/filter.svg';
import searchIcon from '../../../../assets/icons/search.svg';
import deleteIcon from '../../../../assets/icons/trash.svg';
import { VENDORS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import useDebouceTime from '../../../../hooks/useDebounceTime';
import { useModal } from '../../../../hooks/useModal';
import { useNotification } from '../../../../hooks/useNotification';
import { selectedVendor } from '../../../../recoil/navigation';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { vendorDocumentsFiltersState } from '../../../../recoil/vendorDocumentsFilter';
import { cleanVendorsDocumentsFiltersState } from '../../../../recoil/vendorDocumentsFilterClean';
import { GET_DOCUMENT_BY_VENDOR_ID } from '../../../../services/graphql/document/document.queries';
import { DOCUMENT_DELETE } from '../../../../services/graphql/document/document-mutations';
import type { VendorsDocumentTable } from '../../../../types/tables';
import { convertToAmericanDateTimeFormat } from '../../../../utils/convertTimestamp';
import DataTable from '../../common/DataTable/DataTable';
import DTHeader from '../../common/DataTable/DTHeader';
import DocumentActions from '../../common/DocumentActions';
import FilterMark from '../../common/FilterMark';

const DTDocuments = () => {
  const { t } = useTranslation('common');
  const { notifyDeleteError, notifyDeleteOk, notifyNoSelection } =
    useNotification();
  const location = useLocation();

  // States

  const [filters, setFilters] = useRecoilState(vendorDocumentsFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [deleteIconState, setDeleteIconState] = useState<boolean>(false);
  const vendor = useRecoilValue(selectedVendor);
  const cleanFilters = useRecoilValue(cleanVendorsDocumentsFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: VENDORS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<DocumentToShow[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchDocuments,
  } = useQuery<GetDocumentByVendorIdQuery>(GET_DOCUMENT_BY_VENDOR_ID, {
    variables: {
      vendorId: [vendor.id],
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    // eslint-disable-next-line no-unneeded-ternary
    skip: !vendor.id,
  });

  const [deleteDocument] = useMutation<any>(DOCUMENT_DELETE, {
    onCompleted: () => {
      refetchDocuments({ limit: pageSize, offset: pageIndex * pageSize });
      notifyDeleteOk();
      setDeleteIconState(!deleteIconState);
    },
    onError: () => {
      notifyDeleteError();
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getDocuments && fetchData.getDocuments.data) {
      const updatedData: DocumentToShow[] = fetchData.getDocuments.data.map(
        (document) => ({
          id: document.id,
          name: document.name || '',
          type: document.type || '',
          date: document.date || '',
          url: document.url || '',
        }),
      );

      setData(updatedData);
      setTotal(fetchData.getDocuments.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<VendorsDocumentTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        size: 30,
        minSize: 30,
        maxSize: 30,
        header: ({ table }) => (
          <input
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="table-checkbox-th flex h-[18px] w-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        cell: ({ row }) => (
          <input
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="flex h-[18px] w-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      }),
      columnHelper.accessor('name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-bold">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('vendor-documents.th-document-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="name"
          />
        ),
      }),
      columnHelper.accessor('type', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('vendor-documents.th-document-type')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="type"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('vendor-documents.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateTimeFormat(info.getValue() as string)
              : ''}
          </span>
        ),
      }),
      columnHelper.accessor('url', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.id}
            documentName={info.row.original.name}
            deleteDocument={deleteDocument}
            url={info.getValue()}
          />
        ),
        header: () => (
          <DTHeader title={t('vendor-documents.th-document')} accessor="url" />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Debounce Name filtering
  const { handleFilterChangeWithDebounce, filterValue } = useDebouceTime({
    setFilters,
    filters,
    key: 'nameFilter',
  });

  // Delete vendors from row selection

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  useEffect(() => {
    if (table.getSelectedRowModel().flatRows.length > 0) {
      setSelectedDocuments(
        table.getSelectedRowModel().flatRows.map((row) => row.original.id),
      );
    }
  }, [table.getSelectedRowModel().flatRows]);

  const [deleteDocuments, { loading: deleteDocumentsLoading }] =
    useMutation<any>(DOCUMENT_DELETE, {
      variables: {
        id: selectedDocuments,
      },
      onCompleted: () => {
        refetchDocuments({ limit: pageSize, offset: pageIndex * pageSize });
        notifyDeleteOk();
        table.resetRowSelection();
      },
      onError: () => {
        notifyDeleteError();
      },
    });

  const handleRemoveDocuments = () => {
    if (selectedDocuments.length > 0) {
      deleteDocuments();
    } else {
      notifyNoSelection();
    }
  };

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    if (vendor.id && !loading) {
      refetchDocuments({
        limit: pageSize,
        offset: pageIndex * pageSize,
        ...filters,
      });
    }
  }, [filters, needRefresh, pageIndex, pageSize]);

  // Modals
  const { handleOpenVendorDocumentsModal } = useModal();

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <div className="my-2 flex w-full justify-between gap-4">
          <div className="flex gap-4">
            <div className="flex h-[41px] w-[360px] items-center rounded-[15px] border border-greenlight">
              <div className="flex w-[30px] items-center justify-center">
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="h-[16px] w-[16px]"
                />
              </div>
              <input
                value={filterValue}
                onChange={handleFilterChangeWithDebounce}
                type="text"
                id="searchBarDocuments"
                placeholder={t('vendor-documents.filter-placeholder')}
                className="inline-flex  h-[90%] w-[88%]  items-center justify-start gap-2.5 border-transparent p-2 text-base font-medium focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
              />
            </div>
            <button
              onClick={handleOpenVendorDocumentsModal}
              className="button-icon inline-flex h-[41px] w-[46px] items-center justify-center rounded-[5px] border border-greenlight p-2 hover:bg-green"
            >
              <img src={filterIcon} alt="filter icon" />
            </button>
          </div>
          <div className="relative flex gap-4">
            <button
              onClick={() => setDeleteIconState(!deleteIconState)}
              className="button-icon-nohover inline-flex h-[41px] w-[46px] items-center justify-center rounded-[5px] border border-greenlight p-2"
            >
              <img src={actionIcon} alt="more icon" />
            </button>
            <button
              onClick={handleRemoveDocuments}
              className={`absolute bottom-[-26px] right-0 h-[25px] w-full min-w-[150px] items-center gap-1 rounded-md bg-white px-2 ${
                deleteIconState ? 'flex' : 'hidden'
              }`}
            >
              <img src={deleteIcon} alt="delete icon" />
              <span className="select-none text-sm font-normal">
                {t('vendor-documents.delete-button')}
              </span>
            </button>
          </div>
        </div>
        <div className="my-2 flex w-full flex-wrap gap-2">
          {filters &&
            Object.entries(filters).map(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                name !== 'nameFilter' &&
                name !== 'orderDirection' &&
                name !== 'orderBy' &&
                name !== 'limit' &&
                name !== 'offset' && (
                  <FilterMark
                    key={name}
                    filterName={name}
                    filterValue={value}
                    atom={vendorDocumentsFiltersState}
                  />
                ),
            )}
        </div>
        <DataTable
          error={error}
          loading={loading || deleteDocumentsLoading}
          table={table}
          total={total}
          tableId="DocumentsTable"
          sorting={{ ...filters }}
          loadingRows={total || pageSize}
        />
      </div>
    </>
  );
};

export default DTDocuments;
