import { useMutation } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import callIcon from '../../../assets/icons/call.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import nameIcon from '../../../assets/icons/user-square.svg';
import vendorIcon from '../../../assets/icons/vendor.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { vendorDriverToEdit } from '../../../recoil/dataToEdit';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { needRefreshState } from '../../../recoil/needRefresh';
import {
  CREATE_VENDOR_DRIVER,
  DELETE_VENDOR_DRIVER,
  UPDATE_VENDOR_DRIVER,
} from '../../../services/graphql/vendors/vendorsMutation';
import { PHONE_REGEX } from '../../../utils/RegexValidation';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import InputField from '../../ui/common/Inputs/InputField';

type Inputs = {
  name: string;
  phone: string;
};

const NewVendorDriver = () => {
  const { t } = useTranslation('common');
  const [driver, setVendorDriver] = useRecoilState(vendorDriverToEdit);
  const { vendor } = useParams();
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const notifyDeleteVendorDriverSuccess = () =>
    toast.success(t('modalNewVendorDriver.deleteSuccess'));

  // Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: driver?.name,
      phone: driver?.phone,
    },
  });

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const setConfirmModalState = useSetRecoilState(confirmationModalState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFormReset = () => {
    reset();
    setVendorDriver({
      id: '',
      name: '',
      phone: '',
    });
  };

  // Query
  const [deleteVendorDriver] = useMutation(DELETE_VENDOR_DRIVER);

  const [createVendorTruck, { loading: createVendorTruckLoading }] =
    useMutation<any>(CREATE_VENDOR_DRIVER, {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifySaveError();
      },
    });

  const [updateVendorDriver, { loading: updateVendorTruckLoading }] =
    useMutation<any>(UPDATE_VENDOR_DRIVER, {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifySaveError();
      },
    });

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    if (createVendorTruckLoading || updateVendorTruckLoading) return;

    if (driver?.id) {
      updateVendorDriver({
        variables: {
          ...data,
          id: driver.id,
        },
      });
    } else {
      createVendorTruck({
        variables: {
          ...data,
          vendor_id: vendor,
        },
      });
    }
  };

  const handleDeleteModal = (event: any) => {
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deleteVendorDriver({
          variables: { id: driver?.id },
          onCompleted: () => {
            closeOrder();
            handleFormReset();
            notifyDeleteVendorDriverSuccess();
          },
        });
      },
      onCancel: () => {},
      message: t('global.are-you-sure-delete'),
    });
    event.preventDefault();
    return false;
  };

  return (
    <>
      <Breadcrumb
        title={`${t('vendors.title-h1')} > ${t(
          'modalNewVendorDriver.newDriverBreadcrumb',
        )}`}
        image={vendorIcon}
        id="VendorModal"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewVendorDriver.newDriverTitle')}
      </h1>
      <form className="my-6">
        {/* Name Input */}
        <InputField
          label={t('modalNewVendorDriver.name')}
          selectedValue={watch('name')}
          doNotUseSaveSelfButton
          type="text"
          icon={nameIcon}
          errors={errors.name}
          register={register}
          registerName="name"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        {/* Phone Input */}
        <InputField
          label={t('modalNewVendorDriver.phone')}
          selectedValue={watch('phone')}
          doNotUseSaveSelfButton
          type="text"
          icon={callIcon}
          errors={errors.phone}
          register={register}
          registerName="phone"
          validationSchema={{
            required: t('global.required'),
            pattern: {
              value: PHONE_REGEX,
              message: t('global.phone-error'),
            },
          }}
        />

        {/* Save Button */}
        <div className="mt-2 flex justify-end">
          <Button
            text={t('modalNewVendorDriver.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={createVendorTruckLoading || updateVendorTruckLoading}
            loading={createVendorTruckLoading || updateVendorTruckLoading}
            onClick={handleSubmit(onSubmit)}
            type="button"
            id="save-new-vendor"
          />
        </div>
        {driver && (
          <button
            className="flex w-full items-center justify-center"
            onClick={handleDeleteModal}
          >
            <div className=" flex cursor-pointer items-center justify-center text-center text-xs font-bold text-red underline">
              {t('modalNewVendorDriver.delete')}
            </div>
          </button>
        )}
      </form>
    </>
  );
};

export default NewVendorDriver;
