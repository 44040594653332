/* eslint-disable unused-imports/no-unused-vars */
import 'react-datepicker/dist/react-datepicker.css';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import newWithDrawIcon from '../../../assets/icons/newWithDrawIcon.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useCreateWithDraw } from '../../../hooks/createWithDraw';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import InputField from '../../ui/common/Inputs/InputField';
import ReceiptUploader from '../../ui/common/ReceiptUploader';

const NewWithDraw = () => {
  const { t } = useTranslation('common');
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const [receiptFile, setReceiptFile] = useState<File | null>(null);
  const [receiptAmount, setReceiptAmount] = useState<number>(0);
  const { createWithDraw, loading: createWithDrawLoading } =
    useCreateWithDraw();
  const { handleCloseAllModals } = useModal();
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const refreshOrder = () => {
    setNeedRefresh('withdraw');
  };
  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFileSubmit = (file: File) => {
    setReceiptFile(file);
  };

  const handleAmountChange = (value: number | string) => {
    setReceiptAmount(Number(value));
  };

  const handleSubmit = () => {
    if (!receiptFile || !receiptAmount) {
      notifySaveError();
      return;
    }
    createWithDraw({
      variables: {
        file: receiptFile,
        amount: receiptAmount,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'createWithDraw',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  return (
    <>
      <Breadcrumb
        title={t('modalNewWithDraw.newReceiptBreadcrumbTitle')}
        secondTitle={t('modalNewWithDraw.newReceiptBreadcrumb')}
        image={newWithDrawIcon}
      />
      <p className="mt-6 text-xl font-[600]">
        {t('modalNewWithDraw.newReceiptTitle')}
      </p>

      <p className="my-4 text-lg font-[600]">{t('modalNewWithDraw.image')}</p>
      <ReceiptUploader submitFile={handleFileSubmit} />
      <InputField
        label="Amount"
        type="number"
        onSelectChange={handleAmountChange}
        placeholder={formatNumberMoney(receiptAmount)}
        doNotUseSaveSelfButton={true}
      />

      <div className="mt-6 flex justify-end">
        <Button
          text={t('modalNewWithDraw.save')}
          variant="green"
          icon={saveBigIcon}
          disabled={createWithDrawLoading}
          loading={createWithDrawLoading}
          onClick={handleSubmit}
          type="button"
          id="save-new-receipt"
        />
      </div>
    </>
  );
};

export default NewWithDraw;
