import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import type {
  GetDropoffOptionsResponse,
  GetVendorDropoffsStatsResponse,
} from '@/types/dropoffs';

import Graph from '../../components/ui/common/Graph';
import SentryInfiniteScroll from '../../components/ui/common/SentryInfiniteScroll';
import LoaderSmall from '../../components/ui/LoaderSmall';
import DropoffCard from '../../components/ui/vendors/dropoffs/DropoffCard';
import useDropoffList from '../../hooks/useDropoffList';
import { selectedVendor } from '../../recoil/navigation';
import {
  GET_DROPOFF_OPTIONS,
  GET_VENDOR_DROPOFF_STATS,
} from '../../services/graphql/vendors/dropoffs/dropoffsQuery';
import {
  formatNumberMoney,
  formatNumberPercentage,
} from '../../utils/numberFormatter';
import SingleVendorLayout from './SingleVendorLayout';

const VendorDropoffsView = () => {
  const { t } = useTranslation('common');
  const { vendor } = useParams();
  const vendorInfo = useRecoilValue(selectedVendor);
  const [metricsArray, setMetricsArray] = useState<{
    totalGallons: number[];
    miuPercentage: number[];
    payments: number[];
  }>({
    totalGallons: [],
    miuPercentage: [],
    payments: [],
  });

  // === QUERIES ===
  const { data: metrics } = useQuery<GetVendorDropoffsStatsResponse>(
    GET_VENDOR_DROPOFF_STATS,
    {
      skip: !vendor,
      variables: { vendorId: vendor },
      onCompleted: (data) => {
        if (data.getVendorDropoffsStats.data.totals.total_gallons) {
          const acumulatedGallons: number[] = [];
          const miuPercentage: number[] = [];
          acumulatedGallons.unshift(
            data.getVendorDropoffsStats.data.totals.total_gallons,
          );
          const last10 = [...data.getVendorDropoffsStats.data.last10];

          last10.forEach((d) => {
            const last = acumulatedGallons[0];
            const newGallons = (last! - d.total_gallons).toFixed(2);
            if (last !== 0) acumulatedGallons.unshift(Number(newGallons));
            miuPercentage.unshift(d.miu_percentage);
          });

          const acumulatedPayments: number[] = [];
          acumulatedPayments.unshift(
            data.getVendorDropoffsStats.data.totalPayments,
          );
          const payments = [...data.getVendorDropoffsStats.data.lastPayments];

          payments.forEach((d) => {
            const last = acumulatedPayments[0];
            const newPayment = (last! - d.amount).toFixed(2);
            if (last !== 0) acumulatedPayments.unshift(Number(newPayment));
          });

          setMetricsArray({
            miuPercentage,
            totalGallons: acumulatedGallons,
            payments: acumulatedPayments,
          });
        }
      },
    },
  );

  const { data: options } = useQuery<GetDropoffOptionsResponse>(
    GET_DROPOFF_OPTIONS,
    {
      skip: !vendor,
      variables: { vendorId: vendor },
    },
  );

  const {
    fetchMoreDropoffs,
    loading,
    dropoffs,
    hasNextPage,
    errorTimeline,
    loadingSingleQuery,
    refetchSingleDropoff,
  } = useDropoffList({ vendorId: vendor });

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMoreDropoffs,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!errorTimeline,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 20px 0px',
  });
  return (
    <>
      <SingleVendorLayout breadcrumbs={t('vendor-dropoffs.title-breadcrumps')}>
        <div className="flex w-full flex-col gap-y-2">
          {/* Metrics */}
          <div className="w-full">
            <h2 className="text-xl font-semibold">
              {t('vendor-dropoffs.metrics-title')}
            </h2>
            <div className="flex flex-col flex-wrap justify-between md:flex-row">
              {metrics && metrics?.getVendorDropoffsStats?.data && (
                <Graph
                  data={metricsArray.totalGallons}
                  qty={metrics?.getVendorDropoffsStats?.data.totals.total_gallons.toString()}
                  qtyType="Gal"
                  subTitle={t('vendor-dropoffs.graph1-subtitle')}
                />
              )}
              {metrics && metrics?.getVendorDropoffsStats?.data && (
                <Graph
                  data={metricsArray.miuPercentage}
                  qty={formatNumberPercentage(
                    metrics?.getVendorDropoffsStats?.data.totals.miu_percentage,
                  )}
                  qtyType=""
                  subTitle={t('vendor-dropoffs.graph2-subtitle')}
                />
              )}
              {metrics && metrics?.getVendorDropoffsStats?.data && (
                <Graph
                  data={metricsArray.payments}
                  qty={formatNumberMoney(
                    metrics?.getVendorDropoffsStats?.data.totalPayments,
                  )}
                  qtyType=""
                  subTitle={t('vendor-dropoffs.graph3-subtitle')}
                  removeMin
                />
              )}
            </div>
          </div>
          {/* List */}
          <div className="w-full">
            <h2 className="text-xl font-semibold">
              {t('vendor-dropoffs.logs-title')}
            </h2>
            <div className="mb-10 flex w-full flex-col gap-y-5">
              {dropoffs && dropoffs.length === 0 && (
                <div className="flex justify-center">
                  <p className="text-center text-xs italic">
                    {t('vendor-overall.no-timeline-items')}
                  </p>
                </div>
              )}
              {loading && <LoaderSmall />}
              {dropoffs &&
                [...dropoffs].map((dropoff) => (
                  <DropoffCard
                    key={dropoff.id}
                    vendorId={vendorInfo.id}
                    vendorName={vendorInfo.name}
                    dropoff={dropoff}
                    loadingSingleQuery={loadingSingleQuery}
                    refetchSingleDropoff={refetchSingleDropoff}
                    dropdownOptions={options?.getDropoffOptions.data}
                  />
                ))}
              {(loading || hasNextPage) && (
                <SentryInfiniteScroll ref={sentryRef}>
                  <LoaderSmall />
                </SentryInfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </SingleVendorLayout>
    </>
  );
};

export default VendorDropoffsView;
