import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query GetLocations(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDirection: String
  ) {
    getLocations(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      error
      count
      data {
        id
        name
        address
        measuring_method
        createdAt
      }
    }
  }
`;
