import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import iconAll from '../../assets/icons/activity.svg';
import iconAdd from '../../assets/icons/add-circle.svg';
import iconDocument from '../../assets/icons/document.svg';
import iconNotes from '../../assets/icons/note.svg';
import iconVisits from '../../assets/icons/routing.svg';
import TimelineCard from '../../components/ui/clients/overall/TimelineCard';
import FileUploader from '../../components/ui/common/FileUploader';
import SentryInfiniteScroll from '../../components/ui/common/SentryInfiniteScroll';
import LoaderSmall from '../../components/ui/LoaderSmall';
import VendorDriverCard from '../../components/ui/vendors/overall/VendorDriverCard';
import VendorTruckCard from '../../components/ui/vendors/overall/VendorTruckCard';
import { useModal } from '../../hooks/useModal';
import useTimelineVendor from '../../hooks/useTimelineVendor';
import { useUserData } from '../../hooks/useUserData';
import { needRefreshState } from '../../recoil/needRefresh';
import {
  DOCUMENT_CLIENT_UPLOAD,
  DOCUMENT_DELETE,
} from '../../services/graphql/document/document-mutations';
import { CREATE_NOTE_MUTATION } from '../../services/graphql/notes/notesMutation';
import { GET_VENDOR_OVERALL } from '../../services/graphql/vendors/vendorsQuery';
import type { GetVendorOverallByIdResponse } from '../../types/vendors';
import { convertToAmericanDateFormatWithTime } from '../../utils/convertTimestampWithTime';
import { extractIdFromNewInfo } from '../../utils/extractIdFromNewInfo';
import SingleVendorLayout from './SingleVendorLayout';

const VendorDetailView = () => {
  const { t } = useTranslation('common');
  const { vendor } = useParams();

  // === STATES ===
  const [needRefresh, setNeedRefresh] = useRecoilState(needRefreshState);
  const [note, setNote] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filterStyle, setFilterStyle] = useState<string>('All');
  const [openDragAndDrop, setOpenDragAndDrop] = useState<boolean>(false);

  // === VISUAL NOTIFICATIONS ===
  const notifyNoteError = () =>
    toast.error(t('vendor-overall.notify-note-error'));
  const notifyNoteSaveOk = () =>
    toast.success(t('vendor-overall.notify-note-saved'), {
      toastId: 'notifyNoteSaveOk',
    });
  const notifyDocumentError = () =>
    toast.error(t('vendor-overall.notify-document-error'));
  const notifyDocumentUploaded = () =>
    toast.success(t('vendor-overall.notify-document-uploaded'), {
      toastId: 'notifyDocumentUploaded',
    });
  const notifyDocumentDeleteError = () =>
    toast.error(t('vendor-overall.notify-document-delete-error'));
  const notifyDocumentDeleteOk = () =>
    toast.success(t('vendor-overall.notify-document-deleted'), {
      toastId: 'notifyDocumentDeleted',
    });

  // === QUERIES ===
  const { data, refetch } = useQuery<GetVendorOverallByIdResponse>(
    GET_VENDOR_OVERALL,
    {
      variables: { vendorId: vendor },
    },
  );

  const {
    fetchMoreTimelines,
    loading,
    timelines,
    refetchTimeline,
    hasNextPage,
    errorTimeline,
  } = useTimelineVendor();

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMoreTimelines,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!errorTimeline,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 20px 0px',
  });

  const [createNote] = useMutation<any>(CREATE_NOTE_MUTATION, {
    onError: () => {
      notifyNoteError();
    },
    onCompleted: () => {
      setNote('');
      notifyNoteSaveOk();
      refetchTimeline('Notes');
      setFilterStyle('Notes');
    },
  });

  const [uploadDocument] = useMutation<any>(DOCUMENT_CLIENT_UPLOAD, {
    onError: () => {
      notifyDocumentError();
    },
    onCompleted: () => {
      setNote('');
      notifyDocumentUploaded();
      setTimeout(() => {
        refetchTimeline('Document');
        setFilterStyle('Document');
      }, 1000);
    },
  });

  const [deleteDocument] = useMutation<any>(DOCUMENT_DELETE, {
    onError: () => {
      notifyDocumentDeleteError();
    },
    onCompleted: (result) => {
      if (result.deleteDocument.status === 'error') {
        notifyDocumentDeleteError();
        return;
      }

      setNote('');
      notifyDocumentDeleteOk();
      refetchTimeline('All');
      setFilterStyle('All');
    },
  });

  const { id: userId } = useUserData();

  const handleSaveNote = () => {
    if (note) {
      createNote({
        variables: {
          note,
          vendorId: vendor,
          userId,
          routeId: null,
        },
      });
    } else {
      notifyNoteError();
    }
  };

  // Modals
  const { handleOpenNewVendorDriverModal, handleOpenNewVendorTruckModal } =
    useModal();

  const handleFileChange = (file: File | null) => {
    if (file) {
      setSelectedFile(file);
    }
  };

  const openInputDragAndDrop = () => {
    setOpenDragAndDrop(true);
  };
  const closeInputDragAndDrop = () => {
    setOpenDragAndDrop(false);
  };

  const submitFile = async () => {
    if (selectedFile) {
      uploadDocument({
        variables: {
          file: selectedFile,
          vendorId: vendor,
          type: 'Other', // in vendors it will always be other
        },
        context: {
          headers: {
            'content-type': 'multipart/form-data',
            'x-apollo-operation-name': 'uploadFile',
          },
        },
      });
      setSelectedFile(null);
      setOpenDragAndDrop(false);
    }
  };

  const applyTimelineFilter = (type: string) => () => {
    if (type === filterStyle) return;
    const filter = type === 'All' ? undefined : type;
    // refetchTimeline({ type: filter });
    refetchTimeline(filter);
    setFilterStyle(type);
  };

  useEffect(() => {
    if (needRefresh) {
      refetch();
      refetchTimeline('All');
      setFilterStyle('All');

      setTimeout(() => {
        setNeedRefresh(false);
      }, 1000);
    }
  }, [needRefresh]);

  return (
    <>
      <SingleVendorLayout>
        <div className="flex w-full flex-col gap-y-2">
          {/* Drivers */}
          <div className="w-full">
            <h1 className="mb-2 text-xl font-semibold">
              {t('vendor-overall.drivers')}
            </h1>
            <div className="flex w-full flex-wrap gap-3">
              {data &&
                data?.getVendorById?.data.drivers &&
                data?.getVendorById?.data.drivers.length === 0 && (
                  <div className="box-shadow flex h-[82px] w-[250px] items-center justify-center rounded-lg">
                    <p className="text-xs italic">
                      {t('vendor-overall.add-driver')}
                    </p>
                  </div>
                )}
              {data &&
                data?.getVendorById?.data.drivers &&
                data?.getVendorById?.data.drivers.length > 0 &&
                data?.getVendorById?.data.drivers.map((driver) => (
                  <VendorDriverCard driver={driver} key={driver.id} />
                ))}
              <button
                onClick={handleOpenNewVendorDriverModal}
                id="addPeople"
                className="box-shadow flex h-[82px] w-[74px] items-center justify-center rounded-lg"
              >
                <img src={iconAdd} alt="add icon" />
              </button>
            </div>
          </div>

          {/* Trucks */}
          <div className="w-full">
            <h1 className="mb-2 text-xl font-semibold">
              {t('vendor-overall.trucks')}
            </h1>
            <div className="flex w-full flex-wrap gap-3">
              {data &&
                data?.getVendorById?.data.trucks &&
                data?.getVendorById?.data.trucks.length === 0 && (
                  <div className="box-shadow flex h-[122px] w-[250px] items-center justify-center rounded-lg">
                    <p className="text-xs italic">
                      {t('vendor-overall.add-truck')}
                    </p>
                  </div>
                )}
              {data &&
                data?.getVendorById?.data.trucks &&
                data?.getVendorById?.data.trucks.length > 0 &&
                data?.getVendorById?.data.trucks.map((truck) => (
                  <VendorTruckCard truck={truck} key={truck.id} />
                ))}
              <button
                onClick={handleOpenNewVendorTruckModal}
                id="addPeople"
                className="box-shadow flex h-[122px] w-[74px] items-center justify-center rounded-lg"
              >
                <img src={iconAdd} alt="add icon" />
              </button>
            </div>
          </div>

          {/* Timeline */}
          <div className="w-full">
            <h1 className="text-xl font-semibold">
              {t('vendor-overall.timeline')}
            </h1>
            <div className="flex h-[30px] w-full items-start gap-6">
              <button
                onClick={applyTimelineFilter('All')}
                className={`flex items-center gap-1 border-green hover:border-b-2 ${
                  filterStyle === 'All' ? 'border-b-2' : ''
                }`}
              >
                <img src={iconAll} alt="all timeline icon" />
                <span className="text-xs">{t('vendor-overall.all')}</span>
              </button>
              <button
                onClick={applyTimelineFilter('Visits')}
                className={`flex items-center gap-1 border-green hover:border-b-2 ${
                  filterStyle === 'Visits' ? 'border-b-2' : ''
                }`}
              >
                <img src={iconVisits} alt="visits timeline icon" />
                <span className="text-xs">{t('vendor-overall.visits')}</span>
              </button>
              <button
                onClick={applyTimelineFilter('Notes')}
                className={`flex items-center gap-1 border-green hover:border-b-2 ${
                  filterStyle === 'Notes' ? 'border-b-2' : ''
                }`}
              >
                <img src={iconNotes} alt="notes timeline icon" />
                <span className="text-xs">{t('vendor-overall.notes')}</span>
              </button>
              <button
                onClick={applyTimelineFilter('Document')}
                className={`flex items-center gap-1 border-green hover:border-b-2 ${
                  filterStyle === 'Document' ? 'border-b-2' : ''
                }`}
              >
                <img src={iconDocument} alt="document timeline icon" />
                <span className="text-xs">{t('vendor-overall.documents')}</span>
              </button>
            </div>
            {openDragAndDrop ? (
              <FileUploader
                onFileChange={handleFileChange}
                onFileTypeChange={() => {}}
                closeFileUploader={closeInputDragAndDrop}
                submitFile={submitFile}
                view="vendors"
              />
            ) : (
              <div className="relative my-2">
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  autoCorrect="off"
                  className="box-shadow h-[120px] w-full rounded-lg border-none py-4 pl-4 text-blacker placeholder:text-greenextralight focus:shadow-custom focus:ring-0 md:h-[80px] md:pr-[225px]"
                  name="timelineTextearea"
                  id="timelineTextearea"
                  placeholder={t('vendor-overall.add-text')}
                ></textarea>
                <div className="absolute bottom-5 right-5 flex items-center justify-center gap-3">
                  <button
                    onClick={openInputDragAndDrop}
                    id="uploadDocument"
                    className="flex items-center justify-center rounded-md border-[1px] border-green bg-white px-2 py-1 text-xs hover:bg-green hover:text-white"
                  >
                    {t('vendor-overall.upload')}
                  </button>
                  <button
                    onClick={handleSaveNote}
                    id="saveNote"
                    className="button-icon-reverse flex items-center justify-center rounded-md border-[1px] border-green bg-green px-2 py-1 text-xs text-white hover:border-transparent hover:bg-gold hover:text-green"
                  >
                    {t('vendor-overall.save')}
                  </button>
                </div>
              </div>
            )}

            <div className="flex w-full flex-col">
              {timelines && timelines.length === 0 && (
                <div className="flex justify-center">
                  <p className="text-center text-xs italic">
                    {t('vendor-overall.no-timeline-items')}
                  </p>
                </div>
              )}
              {loading && <LoaderSmall />}
              {timelines &&
                [...timelines].map((timeline) => (
                  <TimelineCard
                    key={
                      timeline.createdAt +
                      timeline.timeline_title +
                      Math.random()
                    }
                    title={timeline.timeline_title}
                    description={timeline.timeline_description || ''}
                    author={timeline.user_name || ''}
                    date={convertToAmericanDateFormatWithTime(
                      timeline.createdAt,
                    )}
                    type={timeline.type}
                    id={
                      timeline.new_info &&
                      extractIdFromNewInfo(timeline.new_info)
                    }
                    deleteDocument={deleteDocument}
                    documentDeleted={timeline.document_deleted || false}
                  />
                ))}
              {(loading || hasNextPage) && (
                <SentryInfiniteScroll ref={sentryRef}>
                  <LoaderSmall />
                </SentryInfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </SingleVendorLayout>
    </>
  );
};

export default VendorDetailView;
