import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import documents from '../../../assets/icons/document.svg';
import dropoffs from '../../../assets/icons/vendor-dropoffs.svg';
import overall from '../../../assets/icons/vendor-overall.svg';
import transactions from '../../../assets/icons/vendor-transactions.svg';
import { selectedVendor } from '../../../recoil/navigation';

type Props = {
  sidebarOpen: boolean;
  isSecondaryVendorsOpen: boolean;
};

const SecondarySideBarVendors = ({
  sidebarOpen,
  isSecondaryVendorsOpen,
}: Props) => {
  const [vendor] = useRecoilState(selectedVendor);
  return (
    <div
      className={`fixed z-30 hidden h-full w-[58px] transition-all duration-1000 ${
        sidebarOpen ? 'ml-[212px]' : 'ml-[58px]'
      }  ${isSecondaryVendorsOpen ? 'flex flex-row ' : 'hidden'}`}
    >
      <nav className="flex w-full flex-col justify-center bg-greenextralight pt-6 text-white">
        <div className="relative my-10">
          <div className="flex items-center justify-center">
            <ul className="flex flex-col items-center gap-6">
              <li id="dashboard" className="mb-6">
                <NavLink
                  to={`/dashboard/vendors/overall/${vendor.id}`}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-2 items-center titleAttr'
                      : 'navLinkInactive flex gap-2 titleAttr'
                  }
                  data-title="Overall"
                >
                  <img className="h-[22px]" src={overall} alt="overall" />
                </NavLink>
              </li>

              <li className="mb-6">
                <NavLink
                  to={`/dashboard/vendors/dropoffs/${vendor.id}`}
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-2 items-center titleAttr'
                      : 'navLinkInactive flex gap-2 items-center titleAttr'
                  }
                  data-title="Drop Offs"
                >
                  <img className="h-[22px]" src={dropoffs} alt="dropoffs" />
                </NavLink>
              </li>

              <li id="dashboard" className="mb-6">
                <NavLink
                  to={`/dashboard/vendors/transactions/${vendor.id}`}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-2 items-center titleAttr'
                      : 'navLinkInactive flex gap-2 titleAttr'
                  }
                  data-title="Transactions"
                >
                  <img
                    className="h-[22px]"
                    src={transactions}
                    alt="transactions"
                  />
                </NavLink>
              </li>

              <li id="dashboard" className="mb-6">
                <NavLink
                  to={`/dashboard/vendors/documents/${vendor.id}`}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-2 items-center titleAttr'
                      : 'navLinkInactive flex gap-2 titleAttr'
                  }
                  data-title="Documents"
                >
                  <img className="h-[22px]" src={documents} alt="documents" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SecondarySideBarVendors;
