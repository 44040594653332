import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import saveBigIcon from '../../../assets/icons/save.svg';
import truckIcon from '../../../assets/icons/truck.svg';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { truckServiceToEdit } from '../../../recoil/dataToEdit';
import { needRefreshState } from '../../../recoil/needRefresh';
import { UPDATE_TRUCK } from '../../../services/graphql/trucks/trucks-mutations';
import Breadcrumb from '../../ui/common/Breadcrumb';
import InputField from '../../ui/common/Inputs/InputField';
import LoaderSmall from '../../ui/LoaderSmall';

const TruckServices = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const [truckServices, setTruckServices] = useRecoilState(truckServiceToEdit);

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh('services');
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Mutations
  const [updateTruck, { loading: updateTruckLoading }] = useMutation<any>(
    UPDATE_TRUCK,
    {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );
  // On Submit
  const onSubmit = (e: any) => {
    e.preventDefault();
    updateTruck({
      variables: {
        truckId: truckServices?.id,
        fatBones_service: truckServices?.fatBones_service,
        fatBones_service_gallons: truckServices?.fatBones_service_gallons,
        grease_service: truckServices?.grease_service,
        grease_service_gallons: truckServices?.grease_service_gallons,
        hood_service: truckServices?.hood_service,
        hood_service_gallons: truckServices?.hood_service_gallons,
        oil_service: truckServices?.oil_service,
        oil_service_gallons: truckServices?.oil_service_gallons,
      },
    });
  };
  return (
    <>
      <Breadcrumb
        title={t('modalAssociateTruckService.breadcrumps-1')}
        secondTitle={truckServices?.license}
        thirdTitle={t('modalAssociateTruckService.breadcrumps-3')}
        image={truckIcon}
        id="modalService"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalAssociateTruckService.title')}
      </h1>
      <form className="my-6 flex flex-col gap-y-4">
        <div className="flex h-[25px] flex-row justify-between gap-x-2">
          <div className="w-full">
            <InputField
              type="checkbox"
              label={OIL_DISPOSAL_SERVICE_NAME}
              selectedValue={truckServices?.oil_service?.toString() || 'false'}
              onSelectChange={(val) => {
                setTruckServices({
                  ...truckServices!,
                  oil_service: val === 'true',
                });
              }}
            />
          </div>
          {truckServices?.oil_service && (
            <div className="flex flex-row items-center gap-x-2 text-[14px] ">
              <label className="font-bold">
                {t('modalAssociateTruckService.capacity')}
              </label>
              <input
                type="number"
                value={truckServices.oil_service_gallons}
                onChange={(e) => {
                  setTruckServices({
                    ...truckServices!,
                    oil_service_gallons: Number(e.target.value),
                  });
                }}
                id="OilServiceGallons"
                className="block w-[100px] border-x-0 border-b-2 border-t-0 border-b-graydark p-0 text-xs  placeholder:text-[#5A5A5A] focus:border-b-graydark focus:outline-none focus:ring-0"
              />
            </div>
          )}
        </div>

        <div className="flex h-[25px] flex-row justify-between gap-x-2">
          <div className="w-full">
            <InputField
              type="checkbox"
              label={HOOD_CLEANING_SERVICE_NAME}
              selectedValue={truckServices?.hood_service?.toString() || 'false'}
              onSelectChange={(val) => {
                setTruckServices({
                  ...truckServices!,
                  hood_service: val === 'true',
                });
              }}
            />
          </div>
          {truckServices?.hood_service && (
            <div className="flex flex-row items-center gap-x-2 text-[14px] ">
              <label className="font-bold">
                {t('modalAssociateTruckService.capacity')}
              </label>
              <input
                type="number"
                value={truckServices.hood_service_gallons}
                onChange={(e) => {
                  setTruckServices({
                    ...truckServices!,
                    hood_service_gallons: Number(e.target.value),
                  });
                }}
                id="HoodServiceGallons"
                className="block w-[100px] border-x-0 border-b-2 border-t-0 border-b-graydark p-0 text-xs  placeholder:text-[#5A5A5A] focus:border-b-graydark focus:outline-none focus:ring-0"
              />
            </div>
          )}
        </div>
        <div className="flex h-[25px] flex-row justify-between gap-x-2">
          <div className="w-full">
            <InputField
              type="checkbox"
              label={GREASE_TRAP_SERVICE_NAME}
              selectedValue={
                truckServices?.grease_service?.toString() || 'false'
              }
              onSelectChange={(val) => {
                setTruckServices({
                  ...truckServices!,
                  grease_service: val === 'true',
                });
              }}
            />
          </div>
          {truckServices?.grease_service && (
            <div className="flex flex-row items-center gap-x-2 text-[14px] ">
              <label className="font-bold">
                {t('modalAssociateTruckService.capacity')}
              </label>
              <input
                type="number"
                value={truckServices.grease_service_gallons}
                onChange={(e) => {
                  setTruckServices({
                    ...truckServices!,
                    grease_service_gallons: Number(e.target.value),
                  });
                }}
                id="GreaseServiceGallons"
                className="block w-[100px] border-x-0 border-b-2 border-t-0 border-b-graydark p-0 text-xs  placeholder:text-[#5A5A5A] focus:border-b-graydark focus:outline-none focus:ring-0"
              />
            </div>
          )}
        </div>
        <div className="flex h-[25px] flex-row justify-between gap-x-2">
          <div className="w-full">
            <InputField
              type="checkbox"
              label={FAT_BONES_SERVICE_NAME}
              selectedValue={
                truckServices?.fatBones_service?.toString() || 'false'
              }
              onSelectChange={(val) => {
                setTruckServices({
                  ...truckServices!,
                  fatBones_service: val === 'true',
                });
              }}
            />
          </div>
          {truckServices?.fatBones_service && (
            <div className="flex flex-row items-center gap-x-2 text-[14px] ">
              <label className="font-bold">
                {t('modalAssociateTruckService.capacity')}
              </label>
              <input
                type="number"
                value={truckServices.fatBones_service_gallons}
                onChange={(e) => {
                  setTruckServices({
                    ...truckServices!,
                    fatBones_service_gallons: Number(e.target.value),
                  });
                }}
                id="fatBones"
                className="block w-[100px] border-x-0 border-b-2 border-t-0 border-b-graydark p-0 text-xs  placeholder:text-[#5A5A5A] focus:border-b-graydark focus:outline-none focus:ring-0"
              />
            </div>
          )}
        </div>
        <div className="mt-12 flex justify-end">
          <button
            onClick={onSubmit}
            disabled={updateTruckLoading}
            id="saveService"
            className="button-icon-reverse flex h-[41px] w-[84px] items-center justify-center gap-2 rounded-md bg-green p-2 text-xs text-white  hover:bg-gold hover:text-green"
          >
            {updateTruckLoading ? (
              <LoaderSmall />
            ) : (
              <>
                <img src={saveBigIcon} alt="save icon" />
                <p>{t('modalAssociateTruckService.save')}</p>
              </>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default TruckServices;
