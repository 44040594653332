import { useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { TimelineEvent, TimelineQuery } from '@/types/timeline';

import {
  MAX_NUMBER_OF_TIMELINES,
  TIMELINE_MAX_RESULTS,
} from '../config/constants';
import { GET_CLIENT_TIMELINE } from '../services/graphql/client/client-querys';

const useTimelineClient = () => {
  const { client } = useParams();

  const [timelines, setTimelines] = useState<TimelineEvent[]>([]);

  const {
    // data,
    fetchMore,
    loading,
    error: errorTimeline,
    refetch,
    updateQuery: updateQueryTimeline,
  } = useQuery<TimelineQuery>(GET_CLIENT_TIMELINE, {
    variables: {
      clientId: client,
      offset: 0,
      limit: TIMELINE_MAX_RESULTS,
    },
    onCompleted: (res: TimelineQuery) => {
      setTimelines(res.getClientTimeLine.data || []);
    },
  });

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  function calculateHasNextPage(
    total: number,
    limit: number,
    currentTotal: number,
  ) {
    const currentPage = Math.ceil(currentTotal / limit);
    const totalPages = Math.ceil(total / limit);

    return currentPage < totalPages;
  }

  function refetchTimeline(type?: string | undefined | null) {
    setHasNextPage(true);
    setTimelines([]);

    let filter: string[] | undefined;

    if (type !== null && type !== undefined) {
      if (type !== 'All') {
        filter = [type];
      }
    }

    refetch({
      clientId: client,
      offset: 0,
      limit: TIMELINE_MAX_RESULTS,
      type: filter,
    });
  }

  // useEffect(() => {
  //   if (
  //     data?.getClientTimeLine.data &&
  //     data?.getClientTimeLine.data.length > 0
  //   ) {
  //     setTimelines(data.getClientTimeLine.data);
  //   }
  // }, [data]);

  const fetchMoreTimelines = useCallback(() => {
    if (timelines.length === MAX_NUMBER_OF_TIMELINES) return;

    fetchMore({
      variables: {
        clientId: client,
        offset: timelines.length,
        limit: TIMELINE_MAX_RESULTS,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newData = fetchMoreResult.getClientTimeLine.data || [];
        const newTimelines = [...timelines, ...newData].reduce(
          (p: TimelineEvent[], c: TimelineEvent) => {
            // if the next object's id is not found in the output array
            // push the object into the output array
            if (
              !p.some((el) => {
                return (
                  el.timeline_title === c.timeline_title &&
                  el.createdAt === c.createdAt
                );
              })
            )
              p.push(c);
            return p;
          },
          [],
        );
        setTimelines(newTimelines);

        // Verificar si hay más páginas después de la página actual
        setHasNextPage(
          calculateHasNextPage(
            fetchMoreResult.getClientTimeLine.count || 0,
            TIMELINE_MAX_RESULTS,
            timelines.length + newData.length,
          ),
        );

        return {
          getClientTimeLine: {
            ...prev.getClientTimeLine,
            data: [...prev.getClientTimeLine.data, ...newData],
          },
        };
      },
    });
  }, [fetchMore, timelines, client]);

  return {
    fetchMoreTimelines,
    loading,
    timelines,
    refetchTimeline,
    updateQueryTimeline,
    errorTimeline,
    hasNextPage,
  };
};

export default useTimelineClient;
