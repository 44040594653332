import { forwardRef } from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';

type Props = {
  label?: string;
  selectedValue: string | undefined;
  onSelectChange?: (value: string) => void;
  onSearchChange?: (value: string) => void;
  options: {
    value: string;
    label: string;
  }[];
  icon?: string;
  placeholder?: string;
  errors?: any;
  id?: string;
  isSearchable?: boolean;
  disabled?: boolean;
  selectClassname?: string;
  fullHeight?: boolean;
};

const Dropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      selectedValue,
      onSelectChange,
      options,
      icon,
      label,
      placeholder,
      errors,
      id,
      onSearchChange,
      isSearchable = false,
      disabled = false,
      selectClassname,
      fullHeight,
    },
    ref,
  ) => {
    // Select style
    const colourStyles = {
      control: () => ({
        // ...styles,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        height: '100%',
        minWidth: '100%',
        '&:hover': {
          border: 'none',
          boxShadow: 'none',
        },
        '&:focus': {
          background: 'red',
        },
      }),
      option: (styles: any, { isDisabled, isSelected }: any) => {
        return {
          ...styles,
          backgroundColor: isSelected ? '#2E4347' : '',
          fontWeight: isSelected ? 600 : 400,
          color: isSelected ? 'white' : '#000',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          '&:hover': {
            border: 'none',
            boxShadow: 'none',
            background: isSelected ? '#2E4347' : '#B2BFC1',
          },
        };
      },
      menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#fff',
        border: '1px solid #E5E5E5',
        borderRadius: '7px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginTop: '4px',
        minWidth: '200px',
        zIndex: 10,
        right: '0px',
      }),
      menuList: (styles: any) => ({
        ...styles,
        padding: 0,
        borderRadius: '7px',
      }),
      placeholder: (styles: any) => ({
        ...styles,
        color: '#5A5A5A',
      }),
      singleValue: (styles: any) => ({
        ...styles,
        color: '#000',
      }),
      valueContainer: (styles: any) => ({
        ...styles,
        padding: 0,
      }),
      input: (styles: any) => ({
        ...styles,
        ring: '0px',
        borderColor: 'white',
        '--tw-ring-color': 'transparent',
      }),
    };

    return (
      <div className={fullHeight ? 'h-full' : ''} ref={ref}>
        {label && (
          <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
            {label}
          </label>
        )}
        <div
          className={`relative flex items-center ${
            fullHeight ? 'h-full justify-center' : 'h-[20px]'
          } ${errors ? 'border-b-2 border-b-redish' : ''}`}
        >
          {icon && <img className="mr-2 h-[16px]" src={icon} alt="icon" />}
          <div className={`w-full text-xs font-medium ${selectClassname}`}>
            <Select
              id={id}
              styles={colourStyles}
              components={{
                DropdownIndicator: () => <ArrowDownIcon />,
                IndicatorSeparator: () => null,
              }}
              options={options}
              onChange={(option) => {
                if (onSelectChange) onSelectChange(option!.value);
              }}
              value={
                options.find((option) => option.value === selectedValue) || null
              }
              isSearchable={isSearchable}
              onInputChange={(val: string) => {
                if (onSearchChange) onSearchChange(val);
              }}
              placeholder={placeholder || 'Select an option...'}
              isDisabled={disabled}
            />
          </div>
          {errors && (
            <span className="absolute right-0 top-[-19px] text-[12px] font-bold text-redish">
              {errors.message}
            </span>
          )}
        </div>
      </div>
    );
  },
);

export default Dropdown;
