import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import arrowIcon from '../../assets/icons/arrow-circle-right-adjusted.svg';
import clientsIcon from '../../assets/icons/clients.svg';
import dashboardIcon from '../../assets/icons/dashboard.svg';
import documentIcon from '../../assets/icons/documents.svg';
import dropoffIcon from '../../assets/icons/dropoffs.svg';
import invoicesIcon from '../../assets/icons/invoices.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import notificationOff from '../../assets/icons/notificationOff.svg';
import notificationOn from '../../assets/icons/notificationOn.svg';
import overallIcon from '../../assets/icons/overall.svg';
import routesIcon from '../../assets/icons/routes.svg';
import settings from '../../assets/icons/settings.svg';
import trucksIcon from '../../assets/icons/trucks.svg';
import vendorIcon from '../../assets/icons/vendor.svg';
import workersIcon from '../../assets/icons/workers.svg';
import logo from '../../assets/images/logo.svg';
import { ROLES } from '../../config/permissions';
import { usePermissions } from '../../hooks/usePermission';
import { hasNotificationAtom } from '../../recoil/hasNotifications';
import {
  isInvoicesOpen,
  isSecondarySettingsSidebarOpen,
  isSecondarySidebarOpen,
  selectedClient,
} from '../../recoil/navigation';
import { userStateAtom } from '../../recoil/userData';
import { HAS_UNREAD } from '../../services/graphql/notifications/notificationQuery';
import ModalLogout from '../Modals/ModalLogout';
import ModelNotification from '../Modals/ModalNotification';
import SecondarySideBarInvoices from '../ui/invoices/secondarySideBar';
import SecondarySideBarSettings from '../ui/settings/secondarySideBar';
import SecondarySideBarVendors from '../ui/vendors/secondarySideBar';
import SecondaryNavItem from './SecondaryNavItem';

type Props = {
  toggleSideBar: () => void;
  openSecondarySidebar: () => void;
  closeSecondarySidebar: () => void;
};

const Navigation = ({
  toggleSideBar,
  openSecondarySidebar,
  closeSecondarySidebar,
}: Props) => {
  const { isAuthenticated, user } = useAuth0();
  const { pathname } = useLocation();

  const [logoutModal, setLogoutModal] = useState(false);
  const [isSecondarySettingsOpen, setIsSecondarySettingsOpen] = useRecoilState(
    isSecondarySettingsSidebarOpen,
  );

  const [notificationModal, setNotificationModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [secondarySidebarOpen, setSecondarySidebarOpen] =
    useState<boolean>(false);
  const [expandWithDelay, setExpandWithDelay] = useState<boolean>(false);
  const [expandWithDoubleDelay, setExpandWithDoubleDelay] =
    useState<boolean>(false);
  const [arrowButtonDisabled, setArrowButtonDisabled] =
    useState<boolean>(false);
  const { checkRole } = usePermissions();
  const supervisor = checkRole(ROLES.SUPERVISOR);

  const [userInfo] = useRecoilState(userStateAtom);
  const [hasUnread, setHasUnread] = useRecoilState(hasNotificationAtom);
  const { data } = useQuery(HAS_UNREAD, {
    variables: { userId: userInfo?.id },
    pollInterval: 60000,
    skip: !userInfo?.id,
    onCompleted: (notification) => {
      setHasUnread(notification.hasUnreadedNotifications.data);
    },
  });

  useEffect(() => {
    if (data) {
      setHasUnread(data.hasUnreadedNotifications.data);
    }
  }, [data]);
  const [invoicesOpen, setInvoicesOpen] = useRecoilState(isInvoicesOpen);

  // Selected client
  const [client] = useRecoilState(selectedClient);

  const toggleSidebarWithDoubleDelay = () => {
    setTimeout(() => {
      setExpandWithDoubleDelay(!expandWithDoubleDelay);
    }, 1000);
  };

  const toggleSidebarWithDelay = () => {
    setArrowButtonDisabled(true);
    setSidebarOpen(!sidebarOpen);
    toggleSidebarWithDoubleDelay();
    setTimeout(() => {
      setExpandWithDelay(!expandWithDelay);
      setArrowButtonDisabled(false);
      toggleSideBar();
    }, 300);
  };

  // Open the secondary sidebar through the recoil state
  const [isOpen, setIsOpen] = useRecoilState(isSecondarySidebarOpen);
  const [isOpenVendors, setIsOpenVendors] = useState(false);

  const openSecondarySidebarComponent = () => {
    openSecondarySidebar();
    setSecondarySidebarOpen(true);
  };

  const closeSecondarySidebarComponent = () => {
    closeSecondarySidebar();
    setSecondarySidebarOpen(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      openSecondarySidebarComponent();
    }
  }, [isOpen]);

  useEffect(() => {
    if (/clients\/.*/.test(pathname)) {
      openSecondarySidebarComponent();
    } else {
      closeSecondarySidebarComponent();
    }
  }, [openSecondarySidebarComponent, closeSecondarySidebarComponent]);

  useEffect(() => {
    if (/vendors\/.*/.test(pathname)) {
      setIsOpenVendors(true);
    } else {
      setIsOpenVendors(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (/settings\.*/.test(pathname)) {
      openSecondarySidebar();
      setIsSecondarySettingsOpen(true);
      setInvoicesOpen(false);
    } else if (/invoices/.test(pathname)) {
      openSecondarySidebar();
      setInvoicesOpen(true);
    } else if (/vendors\/.*/.test(pathname)) {
      openSecondarySidebar();
    } else if (!/clients\/.*/.test(pathname)) {
      setIsSecondarySettingsOpen(false);
      setInvoicesOpen(false);
      closeSecondarySidebar();
      setIsOpen(false);
    }
  }, [openSecondarySidebarComponent, closeSecondarySidebarComponent]);

  return (
    <>
      {/* Sidebar */}
      <div
        className={`fixed z-40 flex h-full flex-row transition-all duration-1000 ${
          sidebarOpen ? 'w-[212px]' : 'w-[58px]'
        }`}
      >
        <nav className="flex flex-col justify-between bg-greenlight text-white">
          <div className="relative my-6 h-full md:grid lg:mb-2 lg:mt-10">
            <NavLink onClick={closeSecondarySidebarComponent} to="/dashboard">
              <img
                src={logo}
                alt="logo"
                className={`mx-auto mb-4 transition-all duration-1000 ${
                  sidebarOpen ? 'px-12' : 'px-2'
                } `}
              />
            </NavLink>
            <button
              id="modalOpen"
              className={` ${
                expandWithDelay
                  ? 'absolute right-[-8px] top-[-10px] rotate-180 rounded-full bg-greenlight ring-2 ring-greenlight transition-all duration-1000'
                  : 'absolute right-[17px] top-[30px] bg-greenlight transition-all duration-500'
              } `}
              onClick={toggleSidebarWithDelay}
              disabled={arrowButtonDisabled}
            >
              <img className="h-[22px]" src={arrowIcon} alt="dashboard" />
            </button>
            <div className="mt-10 md:mt-6">
              <ul className="flex flex-col items-center gap-6">
                <li id="dashboard" className="mb-0 md:mb-6">
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard"
                    id="dashboardNav"
                    end
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2'
                    }
                  >
                    <img
                      className="h-[22px]"
                      src={dashboardIcon}
                      alt="dashboard"
                    />
                    <p
                      id="dashboardTextNav"
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Dashboard
                    </p>
                  </NavLink>
                </li>
                <li className={`mb-0 md:mb-6 ${supervisor ? 'hidden' : ''}`}>
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/clients"
                    id="dashboardClients"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img className="h-[22px]" src={clientsIcon} alt="clients" />
                    <p
                      id="dashboardTextClients"
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Clients
                    </p>
                  </NavLink>
                </li>
                <li className="mb-0 md:mb-6">
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/routes"
                    id="dashboardRoutes"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img className="h-[22px]" src={routesIcon} alt="routes" />
                    <p
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Routes
                    </p>
                  </NavLink>
                </li>
                <li className="mb-0 md:mb-6">
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/vendors"
                    id="dashboardVendors"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img className="h-[22px]" src={vendorIcon} alt="vendor" />
                    <p
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Vendors
                    </p>
                  </NavLink>
                </li>
                <li className="mb-0 md:mb-6">
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/dropoffs"
                    id="dashboardVendors"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img className="h-[22px]" src={dropoffIcon} alt="vendor" />
                    <p
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Dropoffs
                    </p>
                  </NavLink>
                </li>
                <li className={`mb-0 md:mb-6 ${supervisor ? 'hidden' : ''}`}>
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/trucks"
                    id="dashboardTrucks"
                    className={({ isActive }) =>
                      isActive
                        ? 'flex gap-2 navLinkActive items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img className="h-[22px]" src={trucksIcon} alt="trucks" />
                    <p
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Trucks
                    </p>
                  </NavLink>
                </li>
                <li className={`mb-0 md:mb-6 ${supervisor ? 'hidden' : ''}`}>
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/invoices"
                    id="dashboardInvoices"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img
                      className="h-[22px]"
                      src={invoicesIcon}
                      alt="invoices"
                    />
                    <p
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Invoices
                    </p>
                  </NavLink>
                </li>
                <li className={`mb-0 md:mb-6 ${supervisor ? 'hidden' : ''}`}>
                  <NavLink
                    onClick={closeSecondarySidebarComponent}
                    to="/dashboard/workers"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img className="h-[22px]" src={workersIcon} alt="workers" />
                    <p
                      className={`text-lg font-medium ${
                        expandWithDelay ? '' : 'hidden'
                      }`}
                    >
                      Workers
                    </p>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4">
            {!sidebarOpen && (
              <div className="mb-2 flex w-full flex-col gap-y-2">
                <div
                  className={`flex items-center justify-center gap-2 px-2 ${
                    supervisor ? '!hidden' : ''
                  }`}
                >
                  <NavLink
                    to="/dashboard/settings"
                    id="profileNav"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2 items-center'
                    }
                  >
                    <img
                      className="h-[22px] object-cover text-white"
                      alt="settings"
                      src={settings}
                    />
                  </NavLink>
                </div>
                <div className="mt-2 flex w-full items-center justify-center">
                  <button onClick={() => setNotificationModal(true)}>
                    {hasUnread && (
                      <img
                        className={`h-[25px] text-white`}
                        alt="notifications"
                        src={notificationOn}
                      />
                    )}
                    {!hasUnread && (
                      <img
                        className={`h-[25px] text-white`}
                        alt="notifications"
                        src={notificationOff}
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
            {sidebarOpen && (
              <div
                className={`mb-2 flex w-full flex-col items-center gap-y-5 ${
                  supervisor ? '!hidden' : ''
                }`}
              >
                <NavLink
                  to="/dashboard/settings"
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-x-3 items-center titleAttr'
                      : 'navLinkInactive flex gap-x-3 items-center titleAttr'
                  }
                >
                  <img
                    className={`h-[25px] text-white`}
                    alt="settings"
                    src={settings}
                  />
                  <p className={`${expandWithDoubleDelay ? '' : 'hidden'}`}>
                    Settings
                  </p>
                </NavLink>
                <div className="mb-2 flex w-full items-center justify-center">
                  <button
                    className="flex flex-row items-center gap-x-3"
                    onClick={() => setNotificationModal(true)}
                  >
                    {hasUnread && (
                      <img
                        className={`h-[25px] text-white`}
                        alt="notifications"
                        src={notificationOn}
                      />
                    )}
                    {!hasUnread && (
                      <img
                        className={`h-[25px] text-white`}
                        alt="notifications"
                        src={notificationOff}
                      />
                    )}

                    <p className={`${expandWithDoubleDelay ? '' : 'hidden'}`}>
                      Notifications
                    </p>
                  </button>
                </div>
              </div>
            )}
            {sidebarOpen && (
              <div className="mb-2 flex w-full flex-col gap-y-5">
                <div
                  className={`flex items-center justify-center gap-2 px-2 ${
                    expandWithDelay
                      ? 'mx-4 h-[52px] rounded-lg border-[1px] border-greenextralight'
                      : 'mx-0 h-[52px] rounded-lg border-none bg-transparent'
                  } `}
                >
                  <NavLink
                    to="/dashboard/profile"
                    className="flex h-[32px] w-[32px] items-center justify-center rounded-full text-xs"
                    id="profileNav"
                  >
                    <div
                      className="h-[32px] w-[32px] items-center justify-center rounded-full bg-cover bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url('${user ? user.picture : ''}')`,
                      }}
                    ></div>
                  </NavLink>
                  <div
                    className={`w-[108px] ${
                      expandWithDoubleDelay
                        ? 'flex flex-col text-sm font-normal'
                        : 'hidden'
                    } `}
                  >
                    <p
                      className={`${
                        expandWithDoubleDelay ? 'truncate' : 'hidden'
                      }`}
                    >
                      {isAuthenticated ? user?.name : ''}
                    </p>
                    <p
                      className={`${
                        expandWithDoubleDelay ? 'truncate' : 'hidden'
                      }`}
                    >
                      {isAuthenticated ? user?.email : ''}
                    </p>
                  </div>
                </div>
                <div className="flex w-full items-center justify-center">
                  <button
                    className="flex flex-row items-center gap-x-3"
                    onClick={() => setLogoutModal(true)}
                  >
                    <img
                      className={`h-[25px] text-white`}
                      alt="logout"
                      src={logoutIcon}
                    />
                    <p className={`${expandWithDoubleDelay ? '' : 'hidden'}`}>
                      Log Out
                    </p>
                  </button>
                </div>
              </div>
            )}
            {!sidebarOpen && (
              <div className="mb-2 flex w-full flex-col gap-y-2">
                <div
                  className={`flex items-center justify-center gap-2 px-2 ${
                    expandWithDelay
                      ? 'mx-4 h-[52px] rounded-lg border-[1px] border-greenextralight'
                      : 'mx-0 h-[52px] rounded-lg border-none bg-transparent'
                  } `}
                >
                  <NavLink
                    to="/dashboard/profile"
                    className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-greenextralight text-xs"
                    id="profileNav"
                  >
                    <div
                      className="h-[32px] w-[32px] items-center justify-center rounded-full bg-cover bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url('${user ? user.picture : ''}')`,
                      }}
                    ></div>
                  </NavLink>
                </div>
                <div className="flex w-full items-center justify-center">
                  <button onClick={() => setLogoutModal(true)}>
                    <img
                      className="h-[22px] text-white"
                      alt="logout"
                      src={logoutIcon}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>
      {/* Secondary Sidebar */}
      <div
        className={`fixed z-30 hidden h-full w-[58px] transition-all duration-1000 ${
          sidebarOpen ? 'ml-[212px]' : 'ml-[58px]'
        }  ${secondarySidebarOpen ? 'flex flex-row ' : 'hidden'}`}
      >
        <nav className="flex w-full flex-col justify-between bg-greenextralight pt-6 text-white">
          <div className="relative my-10">
            <div className="mt-36">
              <ul className="flex flex-col items-center gap-6">
                <li id="dashboard" className="mb-6">
                  <NavLink
                    to={`/dashboard/clients/overall/${client.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center titleAttr'
                        : 'navLinkInactive flex gap-2 titleAttr'
                    }
                    data-title="Overall"
                  >
                    <img
                      className="h-[22px]"
                      src={overallIcon}
                      alt="overall icon"
                    />
                  </NavLink>
                </li>
                {
                  // If the client has a service, display the secondary nav items
                  client.services && client.services.length > 0 && (
                    <>
                      {client.services
                        .slice()
                        .sort((a, b) => a.localeCompare(b))
                        .map((service) => (
                          <SecondaryNavItem
                            key={service}
                            clientId={client.id}
                            service={service}
                          />
                        ))}
                    </>
                  )
                }

                <li className="mb-6">
                  <NavLink
                    to={`/dashboard/clients/document/${client.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center titleAttr'
                        : 'navLinkInactive flex gap-2 items-center titleAttr'
                    }
                    data-title="Document"
                  >
                    <img
                      className="h-[22px]"
                      src={documentIcon}
                      alt="document icon"
                    />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {isSecondarySettingsOpen && (
        <SecondarySideBarSettings
          sidebarOpen={sidebarOpen}
          isSecondarySettingsOpen={isSecondarySettingsOpen}
        />
      )}
      {isOpenVendors && (
        <SecondarySideBarVendors
          sidebarOpen={sidebarOpen}
          isSecondaryVendorsOpen={isOpenVendors}
        />
      )}
      {invoicesOpen && (
        <SecondarySideBarInvoices
          sidebarOpen={sidebarOpen}
          isSecondaryOpen={invoicesOpen}
        />
      )}
      {logoutModal && <ModalLogout handleClose={() => setLogoutModal(false)} />}
      {notificationModal && (
        <ModelNotification
          isMenuOpen={sidebarOpen}
          clearNotification={() => setHasUnread(false)}
          handleClose={() => setNotificationModal(false)}
        />
      )}
    </>
  );
};

export default Navigation;
