import { useTranslation } from 'react-i18next';

import DTDocuments from '../../components/ui/vendors/documents/DTDocuments';
import SingleVendorLayout from './SingleVendorLayout';

const VendorDocumentView = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <SingleVendorLayout title={t('vendor-documents.title-h1')}>
        <>
          {/* Data table */}
          {<DTDocuments />}
        </>
      </SingleVendorLayout>
    </>
  );
};

export default VendorDocumentView;
