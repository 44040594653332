import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface ProfileInputProps {
  control: any;
  registerName: string;
  defaultValue: string | undefined;
  label: string;
  type?: string;
  validations?: any;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
  decimalCount?: number;
  errors?: any;
  labelClassname?: string;
}

// Wrap NumericFormat with forwardRef
const NumericFormatWrapper = forwardRef((props: any, ref) => (
  <NumericFormat {...props} getInputRef={ref} />
));

function InputNumber({
  control,
  defaultValue,
  label,
  registerName,
  validations,
  prefix,
  suffix,
  disabled,
  decimalCount,
  errors,
  labelClassname,
}: ProfileInputProps) {
  return (
    <div className="flex h-auto  w-full flex-col items-start justify-center gap-y-2 self-stretch rounded">
      <div className="flex flex-col items-start justify-center self-stretch">
        <label className={`${labelClassname} text-sm font-normal`}>
          {label}
        </label>
      </div>
      <div className="w-full">
        <Controller
          name={registerName}
          control={control}
          defaultValue={defaultValue}
          rules={{ ...validations }}
          render={({ field: { onChange, value, ...rest } }) => (
            <NumericFormatWrapper
              {...rest}
              value={value === undefined || value === null ? '' : value}
              thousandSeparator={true}
              thousandsGroupStyle="thousand"
              decimalScale={decimalCount !== undefined ? decimalCount : 2}
              fixedDecimalScale
              prefix={prefix}
              suffix={suffix}
              onChange={(val: any) =>
                onChange(
                  Number(
                    val.target.value
                      .replace(/["$",",","(",")"]/gi, '')
                      .replace(prefix, '')
                      .replace(suffix, ''),
                  ) || null,
                )
              }
              onBlur={() => {}}
              onBlurCapture={() => {}}
              onChangeCapture={() => {}}
              onPointerLeave={() => {}}
              placeholder={label}
              disabled={disabled}
              className={`relative flex w-full items-center  p-0 text-xs placeholder:text-[#5A5A5A] ${'border-x-0 border-b-2 border-t-0 border-b-graydark'} ${
                errors
                  ? 'border-b-2 border-b-redish'
                  : 'border-x-0 border-b-2 border-t-0 border-b-graydark'
              } `}
            />
          )}
        />
        {errors && registerName && (
          <span className="absolute right-0 top-[-19px] text-[12px] font-bold text-redish">
            {errors.message}
          </span>
        )}
      </div>
    </div>
  );
}

export default InputNumber;
