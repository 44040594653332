import { gql } from '@apollo/client';

export const GET_VENDORS_TABLE = gql`
  query GetVendors(
    $vendorNameFilter: String
    $statusFilter: [String]
    $lastDropoffStartDateFilter: String
    $lastDropoffEndDateFilter: String
    $averageMiuMinFilter: Float
    $averageMiuMaxFilter: Float
    $pricePerPoundMinFilter: Float
    $pricePerPoundMaxFilter: Float
    $orderBy: String
    $orderDirection: String
    $limit: Int
    $offset: Int
  ) {
    getVendors(
      vendorNameFilter: $vendorNameFilter
      statusFilter: $statusFilter
      lastDropoffStartDateFilter: $lastDropoffStartDateFilter
      lastDropoffEndDateFilter: $lastDropoffEndDateFilter
      averageMiuMinFilter: $averageMiuMinFilter
      averageMiuMaxFilter: $averageMiuMaxFilter
      pricePerPoundMinFilter: $pricePerPoundMinFilter
      pricePerPoundMaxFilter: $pricePerPoundMaxFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
      limit: $limit
      offset: $offset
    ) {
      status
      code
      count
      data {
        id
        name
        status
        average_miu
        price_per_pound
        last_dropoff
      }
      error
    }
  }
`;

export const GET_VENDOR_SIDEBAR = gql`
  query GetVendorById($vendorId: String!) {
    getVendorById(vendorId: $vendorId) {
      status
      code
      data {
        id
        name
        createdAt
        status
        phone
        email
        address
        owner_name
        owner_email
        owner_phone
        price_per_pound
        balance
      }
    }
  }
`;

export const GET_VENDOR_OVERALL = gql`
  query GetVendorById($vendorId: String!) {
    getVendorById(vendorId: $vendorId) {
      status
      code
      data {
        id
        trucks {
          id
          license_plate
          tk
          capacity
        }
        drivers {
          id
          name
          phone
        }
      }
    }
  }
`;

export const GET_VENDOR_TIMELINE = gql`
  query GetVendorTimeLine(
    $vendorId: String!
    $type: [String]
    $limit: Int
    $offset: Int
  ) {
    getVendorTimeLine(
      vendorId: $vendorId
      type: $type
      limit: $limit
      offset: $offset
    ) {
      status
      code
      count
      error
      data {
        timeline_title
        timeline_description
        vendor_id
        createdAt
        updatedAt
        type
        user_name
        new_info
        document_deleted
      }
    }
  }
`;

export const GET_VENDOR_TRANSACTIONS = gql`
  query GetVendorTransactions($vendorId: String!, $limit: Int, $offset: Int) {
    getVendorTransactions(vendorId: $vendorId, limit: $limit, offset: $offset) {
      status
      code
      error
      count
      data {
        id
        vendor_id
        type
        amount
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_VENDOR_TRANSACTIONS_STATS = gql`
  query GetVendorTransactionsStats($vendorId: String!) {
    getVendorTransactionsStats(vendorId: $vendorId) {
      status
      code
      error
      count
      data {
        total_negative
        total_positive
        balance
      }
    }
  }
`;
