import { useRecoilState } from 'recoil';

import { selectedVendor } from '../recoil/navigation';

export const useVendorSelected = () => {
  const [vendorSelected, setVendorSelected] = useRecoilState(selectedVendor);
  const updateData = (vendorData: any) => {
    if (!vendorSelected.id && vendorData?.getVendorById?.data) {
      setVendorSelected({
        ...vendorSelected,
        id: vendorData?.getVendorById?.data.id || '',
        name: vendorData?.getVendorById?.data.name || '',
      });
    }
  };
  return { updateData, vendorSelected };
};
