const StatCard = ({
  stat,
  subtitle,
  color,
  type,
}: {
  stat: string;
  subtitle: string;
  color: string;
  type: 'small' | 'medium';
}) => {
  return (
    <div
      className={`flex w-full flex-col  ${
        type === 'small'
          ? 'items-start justify-start text-start'
          : 'items-center justify-center text-center '
      } rounded-2xl p-5 shadow-custom`}
    >
      <span
        className={`${
          type === 'small' ? 'text-[28px]' : 'text-[38px]'
        } font-bold ${color}`}
      >
        {stat}
      </span>
      <span
        className={type === 'small' ? '-mt-1 text-xs font-medium' : 'text-sm'}
      >
        {subtitle}
      </span>
    </div>
  );
};

export default StatCard;
