import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { GetTrucksQuery, TruckToShow } from '@/types/truck';

import addTruckIcon from '../../../assets/icons/trucks.svg';
import {
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
  TRUCKS_TABLES_MAX_RESULTS,
} from '../../../config/constants';
import useDebouceTime from '../../../hooks/useDebounceTime';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { cleanFiltersState } from '../../../recoil/clientsFilterClean';
import { needRefreshState } from '../../../recoil/needRefresh';
import { truckFiltersState } from '../../../recoil/trucksFilter';
import {
  DELETE_TRUCKS,
  UPDATE_TRUCK,
} from '../../../services/graphql/trucks/trucks-mutations';
import { GET_ALL_TRUCKS } from '../../../services/graphql/trucks/trucks-queries';
import type { TrucksTable } from '../../../types/tables';
import AddButton from '../common/AddButton';
import DataTable from '../common/DataTable/DataTable';
import DTHeader from '../common/DataTable/DTHeader';
import DeleteButton from '../common/DeleteButton';
import FilterMark from '../common/FilterMark';
import Searchbar from '../common/Searchbar';
import TruckStatusDropdown from './TruckStatusDropdown';

const DTTrucks = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const {
    notifyUpdateError,
    notifyUpdateOk,
    notifyDeleteError,
    notifyDeleteOk,
    notifyNoSelection,
  } = useNotification();

  // States

  const [filters, setFilters] = useRecoilState(truckFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [deleteIconState, setDeleteIconState] = useState<boolean>(false);

  const cleanFilters = useRecoilValue(cleanFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: TRUCKS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<TruckToShow[]>([]);
  const [total, setTotal] = useState<number>(0);

  const handleRowClick = (truckId: string, event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isCellClick = target.classList.contains('cell-class');

    if (isCellClick) {
      // Go to truck
      navigate(`/dashboard/trucks/${truckId}`);
    }
  };

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchTrucks,
  } = useQuery<GetTrucksQuery>(GET_ALL_TRUCKS, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'license',
      orderDirection: filters?.orderDirection || 'ASC',
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (fetchData && fetchData.getAllTruck?.data) {
      const updatedData: TruckToShow[] = fetchData.getAllTruck.data.map(
        (truckFetched) => {
          const services: string[] = [];

          if (truckFetched.oil_service) {
            services.push(OIL_DISPOSAL_SERVICE_NAME);
          }
          if (truckFetched.grease_service) {
            services.push(GREASE_TRAP_SERVICE_NAME);
          }
          if (truckFetched.hood_service) {
            services.push(HOOD_CLEANING_SERVICE_NAME);
          }

          return {
            id: truckFetched.id,
            license: truckFetched.license || '',
            status: truckFetched.status || '',
            service: services.join(', '),
            mileage: truckFetched.mileage || 0,
            last_used_by: truckFetched.last_used_by || '',
          };
        },
      );

      setData(updatedData);
      setTotal(fetchData.getAllTruck.count);
    }
  }, [fetchData]);

  // Update truck status
  const [updateStatus] = useMutation<any>(UPDATE_TRUCK, {
    onCompleted: () => {
      refetchTrucks({ limit: pageSize, offset: pageIndex * pageSize });
      notifyUpdateOk();
    },
    onError: () => {
      notifyUpdateError();
    },
  });

  const columnHelper = createColumnHelper<TrucksTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        size: 30,
        minSize: 30,
        maxSize: 30,
        header: ({ table }) => (
          <input
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="table-checkbox-th flex h-[18px] w-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        cell: ({ row }) => (
          <input
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="flex h-[18px] w-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      }),
      columnHelper.accessor('license', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="cell-class text-xs font-bold">
            {info.getValue()}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-license')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="license"
          />
        ),
      }),
      columnHelper.accessor('status', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <div className="flex items-center">
            <TruckStatusDropdown
              truckId={info.row.original.id}
              initialStatusValue={info.getValue()}
              mutation={updateStatus}
              key={info.row.original.id}
            />
          </div>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-status')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="status"
          />
        ),
      }),
      columnHelper.accessor('service', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: () => (
          <DTHeader title={t('trucks.th-service')} accessor="service" />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor('mileage', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-mileage')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="mileage"
          />
        ),
      }),
      columnHelper.accessor('last_used_by', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-last-used-by')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="last_used_by"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Debounce Name filtering
  const { handleFilterChangeWithDebounce, filterValue } = useDebouceTime({
    setFilters,
    filters,
    key: 'nameFilter',
  });

  // Delete trucks from row selection

  const [selectedTrucks, setSelectedTrucks] = useState<string[]>([]);

  useEffect(() => {
    if (table.getSelectedRowModel().flatRows.length > 0) {
      setSelectedTrucks(
        table.getSelectedRowModel().flatRows.map((row) => row.original.id),
      );
    }
  }, [table.getSelectedRowModel().flatRows]);

  const [deleteTrucks, { loading: deleteTrucksLoading }] = useMutation<any>(
    DELETE_TRUCKS,
    {
      variables: {
        truckId: selectedTrucks,
      },
      onCompleted: () => {
        refetchTrucks({ limit: pageSize, offset: pageIndex * pageSize });
        notifyDeleteOk();
        table.resetRowSelection();
        setDeleteIconState(!deleteIconState);
      },
      onError: () => {
        notifyDeleteError();
      },
    },
  );

  const handleRemoveTrucks = () => {
    if (selectedTrucks.length > 0) {
      deleteTrucks();
    } else {
      notifyNoSelection();
    }
  };

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetchTrucks({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // Modals
  const { handleOpenNewTruckModal, handleOpenTrucksFilterModal } = useModal();

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'ASC',
      orderBy: 'license',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <div className="my-2 flex h-full w-full justify-between gap-4">
          <Searchbar
            filterValue={filterValue}
            onChange={handleFilterChangeWithDebounce}
            handleOpenFilterModal={handleOpenTrucksFilterModal}
          />
          <div className="relative flex gap-4">
            <AddButton
              icon={addTruckIcon}
              handleAction={handleOpenNewTruckModal}
            />
            <DeleteButton handleRemove={handleRemoveTrucks} />
          </div>
        </div>
        <div className="my-2 flex w-full flex-wrap gap-2">
          {filters &&
            Object.entries(filters).map(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                name !== 'nameFilter' &&
                name !== 'orderDirection' &&
                name !== 'orderBy' && (
                  <FilterMark
                    key={name}
                    filterName={name}
                    filterValue={value}
                    atom={truckFiltersState}
                  />
                ),
            )}
        </div>
        <DataTable
          error={error}
          handleRowClick={handleRowClick}
          loading={loading || deleteTrucksLoading}
          table={table}
          total={total}
          tableId="TrucksTable"
          sorting={{ ...filters }}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTTrucks;
