import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import saveBigIcon from '../../../../assets/icons/save.svg';
import truckIcon from '../../../../assets/icons/truck.svg';
import {
  truckModelOptions,
  truckServiceOptions,
} from '../../../../config/constants';
import { useModal } from '../../../../hooks/useModal';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { truckFiltersState } from '../../../../recoil/trucksFilter';
import Breadcrumb from '../../../ui/common/Breadcrumb';
import { Button } from '../../../ui/common/Button';
import DropdownMultiple from '../../../ui/common/DropdownMultiple';

const FilterTruck = () => {
  const { t } = useTranslation('common');

  // Form title
  const breadcrumbTitle = `${t('modalFilterRoutes.truck')}`;
  const breadcrumbSecondTitle = t('modalFilterRoutes.filters');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters
  const [filters, setFilters] = useRecoilState(truckFiltersState);

  const [model, setModel] = useState<string[] | null>(null);
  const [service, setService] = useState<string[] | null>(null);

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };

    if (model) {
      newFilters.model = model;
    } else {
      newFilters.model = null;
    }

    if (service) {
      newFilters.service = service;
    } else {
      newFilters.service = null;
    }

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  const handleModelChange = (selected: string[]) => {
    if (selected.length > 0) {
      setModel(selected);
    } else {
      setModel(null);
    }
  };

  const handleServiceChange = (selected: string[]) => {
    if (selected.length > 0) {
      setService(selected);
    } else {
      setService(null);
    }
  };

  useEffect(() => {
    if (filters) {
      if (filters.model) {
        setModel(filters.model);
      }
      if (filters.service) {
        setService(filters.service);
      }
    }
  }, [filters]);

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={truckIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalInvoicesFilter.filters')}
      </h1>
      <div className="my-6 flex flex-col gap-2">
        {/* Model Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={truckModelOptions}
            onChange={handleModelChange}
            label={t('modalTrucksFilter.model')}
            selectedValues={model || []}
          />
        </div>
        {/* Service Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={truckServiceOptions}
            onChange={handleServiceChange}
            label={t('modalTrucksFilter.service')}
            selectedValues={service || []}
          />
        </div>

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalNewClient.save')}
            variant="green"
            icon={saveBigIcon}
            onClick={handleFiltersSave}
            type="button"
            id="save-truck-filters"
          />
        </div>
      </div>
    </>
  );
};

export default FilterTruck;
