import type { ChangeEvent, DragEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import iconSwap from '../../../assets/icons/arrange-circle-2.svg';
import iconClose from '../../../assets/icons/close-circle.svg';
import iconDnD from '../../../assets/icons/document-upload.svg';
import { documentTypeOptions } from '../../../config/constants';
import Loader from '../Loader';
import { ErrorMessage } from './ErrorMessage';
import Dropdown from './Inputs/Dropdown';

type Props = {
  onFileChange: (file: File | null, type: string | null) => void;
  submitFile?: () => void;
  closeFileUploader?: () => void;
  onFileTypeChange?: (type: string | null) => void;
  view?: 'clients' | 'routes' | 'vendors';
  customClassname?: string;
  loading?: boolean;
};

const FileUploader = ({
  onFileChange,
  submitFile,
  closeFileUploader,
  onFileTypeChange,
  view,
  customClassname,
  loading,
}: Props) => {
  const { t } = useTranslation('common');

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0] || null);
      onFileChange(event.target.files[0] || null, type);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    if (event.dataTransfer.files) {
      setFile(event.dataTransfer.files[0] || null);
      onFileChange(event.dataTransfer.files[0] || null, type);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setFile(null);
    onFileChange(null, null);
  };

  const handleTypeDropdownChange = (selectedOption: any) => {
    setType(selectedOption);
    setError(false);
    if (onFileTypeChange) {
      onFileTypeChange(selectedOption);
    }
  };

  const onSubmit = () => {
    if (view !== 'vendors' && type === null) {
      setError(true);
      return;
    }
    if (submitFile) submitFile();
    setFile(null);
    setType(null);
    setError(false);
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={() => setDragging(false)}
      className={`relative my-2  rounded-lg border-2 border-dashed border-greenlight p-5 text-center ${
        dragging && 'border-blacker opacity-50'
      } ${customClassname}`}
    >
      {view === 'clients' && (
        <button onClick={closeFileUploader} className="absolute right-1 top-1">
          <img className="h-[16px]" src={iconSwap} alt="icon swap" />
        </button>
      )}
      <input
        type="file"
        id="uploadFileInput"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <div className="flex w-full flex-col gap-2">
        {/* UI when file is selected */}
        {file && (
          <>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <button
                  className="flex h-[16px] w-[16px] items-center justify-center"
                  onClick={handleRemoveFile}
                >
                  <img src={iconClose} alt="icon close" />
                </button>
                <p className="my-1 text-xs font-bold text-blacker">
                  {file.name} ({Math.round(file.size / 1024)} Kb)
                </p>
              </div>
              {view !== 'vendors' && (
                <div className="flex min-w-[150px] items-center justify-end">
                  <Dropdown
                    options={documentTypeOptions}
                    selectedValue={type || ''}
                    id="DocumentType"
                    onSelectChange={handleTypeDropdownChange}
                    placeholder={t('clients-overall.document-type')}
                  />
                </div>
              )}
            </div>
            <button
              className="rounded-md bg-green px-3 py-1 text-xs text-white hover:bg-gold hover:text-green"
              onClick={onSubmit}
              id="submitFile"
            >
              {t('clients-overall.upload-file')}
            </button>
            <ErrorMessage
              show={!!error}
              text={t('clients-overall.document-type-error')}
            />
            {/* <p
              className={`text-left font-inter text-sm text-red-600 ${
                error ? 'block' : 'hidden'
              }`}
            >
              {t('clients-overall.document-type-error')}
            </p> */}
          </>
        )}
        {/* UI when no file has been selected yet */}
        {!file && !loading && (
          <>
            <img className="h-[16px]" src={iconDnD} alt="drag and drop icon" />
            <p className="text-xs text-greenlight">
              {t('clients-overall.drag-file')}
            </p>
            <button
              onClick={openFileDialog}
              className="mt-2 rounded-md bg-green px-3 py-1 text-xs text-white hover:bg-gold hover:text-green"
            >
              {t('clients-overall.select-file')}
            </button>
          </>
        )}
      </div>
      {/* UI while loading upload */}
      {loading && (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
