import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import callIcon from '../../../assets/icons/call.svg';
import clientIcon from '../../../assets/icons/clients-small.svg';
import editIcon from '../../../assets/icons/edit.svg';
import emailIcon from '../../../assets/icons/email.svg';
import roleIcon from '../../../assets/icons/personalcard.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import saveIcon from '../../../assets/icons/save-small.svg';
import nameIcon from '../../../assets/icons/user-square.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { personSelectedToEdit } from '../../../recoil/dataToEdit';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { selectedClient } from '../../../recoil/navigation';
import { needRefreshState } from '../../../recoil/needRefresh';
import {
  CREATE_CLIENT_PERSON,
  DELETE_CLIENT_PEOPLE,
  UPDATE_CLIENT_PERSON,
} from '../../../services/graphql/client/client-mutations';
import { GET_CLIENT_BY_ID } from '../../../services/graphql/client/client-querys';
import type { ClientQuery } from '../../../types/client';
import { EMAIL_REGEX, PHONE_REGEX } from '../../../utils/RegexValidation';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';

type Inputs = {
  name: string;
  role: string;
  phone: string;
  email: string;
};

const NewPerson = () => {
  const { t } = useTranslation('common');
  const {
    notifySaveSuccess,
    notifySaveError,
    notifyDeleteOk,
    notifyDeleteError,
    notifyUpdateError,
    notifyUpdateOk,
  } = useNotification();

  const client = useRecoilValue(selectedClient);
  const [personSelectedId, setPersonSelectedId] =
    useRecoilState(personSelectedToEdit);
  const setConfirmModalState = useSetRecoilState(confirmationModalState);

  // States
  const [nameHovered, setnameHovered] = useState<boolean>(false);
  const [nameFocused, setnameFocused] = useState<boolean>(false);

  const [phoneHovered, setphoneHovered] = useState<boolean>(false);
  const [phoneFocused, setphoneFocused] = useState<boolean>(false);

  const [roleHovered, setRoleHovered] = useState<boolean>(false);
  const [roleFocused, setRoleFocused] = useState<boolean>(false);

  const [emailHovered, setemailHovered] = useState<boolean>(false);
  const [emailFocused, setemailFocused] = useState<boolean>(false);

  // Form Validation
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: '',
      role: '',
      phone: '',
      email: '',
    },
  });

  const breadcrumbTitle = t('clients.title-h1');
  const breadcrumbSecondTitle = client.name;
  const breadcrumbThirdTitle = t('modalNewPerson.newPersonBreadcrumb');

  const name = watch('name');
  const role = watch('role');
  const phone = watch('phone');
  const email = watch('email');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
    setPersonSelectedId(null);
  };

  const handleFormReset = () => {
    setValue('name', '');
    setValue('role', '');
    setValue('phone', '');
    setValue('email', '');
    setPersonSelectedId(null);
  };

  // Queries
  const [createPerson, { loading: createPersonLoading }] = useMutation<any>(
    CREATE_CLIENT_PERSON,
    {
      variables: {
        fullName: name,
        role,
        clientId: client.id,
        phone,
        email,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  const [updatePerson, { loading: updatePersonLoading }] = useMutation<any>(
    UPDATE_CLIENT_PERSON,
    {
      variables: {
        fullName: name,
        role,
        clientPeopleId: personSelectedId,
        phone,
        email,
      },
      onCompleted: () => {
        notifyUpdateOk();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifyUpdateError();
      },
    },
  );

  const [deletePerson, { loading: deletePersonLoading }] = useMutation<any>(
    DELETE_CLIENT_PEOPLE,
    {
      variables: {
        id: personSelectedId,
      },
      onCompleted: () => {
        notifyDeleteOk();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifyDeleteError();
      },
    },
  );

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = () => {
    if (createPersonLoading || updatePersonLoading) return;

    if (personSelectedId) {
      updatePerson();
      return;
    }

    createPerson();
  };

  const { data } = useQuery<ClientQuery>(GET_CLIENT_BY_ID, {
    variables: { clientId: client.id },
  });

  useEffect(() => {
    const person = data?.getClientById?.data.clientPeople.find(
      (persona) => persona.id === personSelectedId,
    );

    if (personSelectedId && person) {
      if (person?.full_name) {
        setValue('name', person.full_name);
      }
      if (person?.role) {
        setValue('role', person.role);
      }
      if (person?.phone) {
        setValue('phone', person.phone);
      }
      if (person?.email) {
        setValue('email', person.email);
      }
    }
  }, [data]);

  const handleDeletePerson = () => {
    if (deletePersonLoading) return;

    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deletePerson();
      },
      onCancel: () => {
        // Nothing happens
      },
      message: t('global.are-you-sure-delete'),
    });
  };

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        thirdTitle={personSelectedId ? name : breadcrumbThirdTitle}
        image={clientIcon}
        id="NewPersonModal"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {personSelectedId
          ? t('modalNewPerson.newPersonTitleEdit')
          : t('modalNewPerson.newPersonTitle')}
      </h1>
      <form className="my-6">
        {/* Name Input */}
        <div className="my-2 h-[60px]">
          <label htmlFor="name" className="block text-[14px] font-[600]">
            {t('modalNewPerson.fullname')}
          </label>
          <div
            onMouseEnter={() => setnameHovered(true)}
            onMouseLeave={() => setnameHovered(false)}
            className={`flex w-full ${
              nameFocused
                ? 'border-x-0 border-b-2 border-t-0 border-b-graydark'
                : ''
            } ${
              get(errors, 'name') &&
              'border-x-0 border-b-2 border-t-0 border-red'
            } `}
          >
            <img src={nameIcon} alt="name icon" />
            <input
              {...register('name', {
                required: true,
              })}
              onFocus={() => setnameFocused(true)}
              onBlur={() => setnameFocused(false)}
              type="text"
              id="name"
              className={`block w-full border-none  p-1.5 text-sm font-medium focus:outline-none focus:ring-0`}
            />
            {nameHovered && !nameFocused && (
              <img src={editIcon} alt="edit icon" />
            )}
            {nameFocused && <img src={saveIcon} alt="save icon" />}
          </div>
        </div>

        {/* Role Input */}
        <div className="my-2 h-[60px]">
          <label htmlFor="role" className="block text-[14px] font-[600]">
            {t('modalNewPerson.role')}
          </label>
          <div
            onMouseEnter={() => setRoleHovered(true)}
            onMouseLeave={() => setRoleHovered(false)}
            className={`flex w-full ${
              roleFocused
                ? 'border-x-0 border-b-2 border-t-0 border-b-graydark'
                : ''
            } ${
              get(errors, 'role') &&
              'border-x-0 border-b-2 border-t-0 border-red'
            } `}
          >
            <img src={roleIcon} alt="role icon" />
            <input
              {...register('role', {
                required: true,
              })}
              onFocus={() => setRoleFocused(true)}
              onBlur={() => setRoleFocused(false)}
              type="text"
              id="role"
              className={`block w-full border-none  p-1.5 text-sm font-medium focus:outline-none focus:ring-0`}
            />
            {roleHovered && !roleFocused && (
              <img src={editIcon} alt="edit icon" />
            )}
            {roleFocused && <img src={saveIcon} alt="save icon" />}
          </div>
        </div>

        {/* Phone Input */}
        <div className="my-2 h-[60px]">
          <label htmlFor="phone" className="block text-[14px] font-[600]">
            {t('modalNewClient.phone')}
          </label>
          <div
            onMouseEnter={() => setphoneHovered(true)}
            onMouseLeave={() => setphoneHovered(false)}
            className={`relative flex w-full ${
              phoneFocused
                ? 'border-x-0 border-b-2 border-t-0 border-b-graydark'
                : ''
            } ${
              get(errors, 'name') &&
              'border-x-0 border-b-2 border-t-0 border-red'
            }`}
          >
            <img src={callIcon} alt="call icon" />

            <input
              {...register('phone', {
                required: true,
                pattern: PHONE_REGEX,
              })}
              onFocus={() => setphoneFocused(true)}
              onBlur={() => setphoneFocused(false)}
              type="tel"
              id="phone"
              className={`block w-full border-none  p-1.5 text-sm font-medium focus:outline-none focus:ring-0`}
            />
            {phoneHovered && !phoneFocused && (
              <img src={editIcon} alt="edit icon" />
            )}
            {phoneFocused && <img src={saveIcon} alt="save icon" />}
            {errors.phone?.type === 'pattern' && (
              <p
                className="absolute right-0 top-[-19px] text-[12px] font-bold text-redish"
                role="alert"
              >
                {t('modalNewClient.phone-error')}
              </p>
            )}
          </div>
        </div>

        {/* Email Input */}
        <div className="my-2 h-[60px]">
          <label htmlFor="email" className="block text-[14px] font-[600]">
            {t('modalNewClient.email')}
          </label>
          <div
            onMouseEnter={() => setemailHovered(true)}
            onMouseLeave={() => setemailHovered(false)}
            className={`relative flex w-full ${
              emailFocused
                ? 'border-x-0 border-b-2 border-t-0 border-b-graydark'
                : ''
            } ${
              get(errors, 'name') &&
              'border-x-0 border-b-2 border-t-0 border-red'
            } `}
          >
            <img src={emailIcon} alt="email icon" />

            <input
              {...register('email', {
                required: true,
                pattern: EMAIL_REGEX,
              })}
              onFocus={() => setemailFocused(true)}
              onBlur={() => setemailFocused(false)}
              type="email"
              id="email"
              className={`block w-full border-none  p-1.5 text-sm font-medium focus:outline-none focus:ring-0`}
            />
            {emailHovered && !emailFocused && (
              <img src={editIcon} alt="edit icon" />
            )}
            {emailFocused && <img src={saveIcon} alt="save icon" />}
            {errors.email?.type === 'pattern' && (
              <p
                className="absolute right-0 top-[-19px] text-[12px] font-bold text-redish"
                role="alert"
              >
                {t('modalNewClient.email-error')}
              </p>
            )}
          </div>
        </div>
        {personSelectedId && (
          <div className="mt-6 flex w-full justify-center">
            <button
              type="button"
              onClick={handleDeletePerson}
              id="deletePerson"
              className="text-sm font-bold text-redish underline"
            >
              {t('modalNewPerson.delete-contact')}
            </button>
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalNewPerson.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={createPersonLoading}
            loading={createPersonLoading}
            onClick={handleSubmit(onSubmit)}
            type="button"
            id="save-new-person"
          />
        </div>
      </form>
    </>
  );
};

export default NewPerson;
