import { useEffect, useState } from 'react';

import phoneIcon from '../../../../../assets/icons/call.svg';
import editIcon from '../../../../../assets/icons/edit.svg';
import emailIcon from '../../../../../assets/icons/email.svg';
import saveIcon from '../../../../../assets/icons/save-small.svg';
import type { GetVendorByIdResponse } from '../../../../../types/vendors';

type Props = {
  data?: GetVendorByIdResponse;
  updateVendor: (arg: any) => void;
  vendor: string;
};

const VendorOwnerFixedCards = ({ updateVendor, vendor, data }: Props) => {
  // === STATES ===
  // Phone
  const [phone, setPhone] = useState<string>(
    data?.getVendorById?.data.owner_phone || '',
  );
  const [isEditingPhone, setIsEditingPhone] = useState<boolean>(false);
  const [isHoveringPhone, setIsHoveringPhone] = useState<boolean>(false);

  // Email
  const [email, setEmail] = useState<string>(
    data?.getVendorById?.data.owner_email || '',
  );
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [isHoveringEmail, setIsHoveringEmail] = useState<boolean>(false);

  const resetIsEditingStates = () => {
    setIsEditingPhone(false);
    setIsEditingEmail(false);
  };

  const handleSavePhone = () => {
    updateVendor({
      variables: {
        vendorId: vendor,
        key: 'owner_phone',
        value: phone,
      },
    });
    setIsEditingPhone(false);
    resetIsEditingStates();
  };

  const handleSaveEmail = () => {
    updateVendor({
      variables: {
        vendorId: vendor,
        key: 'owner_email',
        value: email,
      },
    });
    setIsEditingPhone(false);
    resetIsEditingStates();
  };

  useEffect(() => {
    setPhone(data?.getVendorById?.data.owner_phone || '');
    setEmail(data?.getVendorById?.data.owner_email || '');
  }, [data]);

  return (
    <>
      <div className="flex flex-col gap-2 rounded-lg bg-greenextralight pb-10 pl-6 pr-3 pt-6">
        <div>
          <h1 className="text-3xl font-[700]">
            {data?.getVendorById?.data.owner_name}
          </h1>
        </div>
        <div
          onMouseOver={() => setIsHoveringPhone(true)}
          onMouseLeave={() => setIsHoveringPhone(false)}
          className="mt-3 flex justify-between gap-2 pr-2"
        >
          {isEditingPhone ? (
            <div className="flex items-center gap-2">
              <img className="h-[16px]" src={phoneIcon} alt="phone icon" />
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                id="phone"
                className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                value={phone}
              />
            </div>
          ) : (
            <div className="flex w-[calc(100%-16px)] items-center gap-2">
              <img className="h-[16px]" src={phoneIcon} alt="phone icon" />
              <p className="text-xs font-medium">{phone}</p>
            </div>
          )}

          {isEditingPhone ? (
            <button
              className={`${isEditingPhone ? 'min-w-[16px]' : 'hidden'}`}
              onClick={handleSavePhone}
              id="savePhone"
            >
              <img src={saveIcon} alt="edit icon" />
            </button>
          ) : (
            <button
              className={`${isHoveringPhone ? 'min-w-[16px]' : 'hidden'}`}
              onClick={() => setIsEditingPhone(!isEditingPhone)}
              id="editPhone"
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
        </div>
        <div
          onMouseOver={() => setIsHoveringEmail(true)}
          onMouseLeave={() => setIsHoveringEmail(false)}
          className="flex justify-between gap-2 pr-2"
        >
          {isEditingEmail ? (
            <div className="flex items-center gap-2">
              <img className="h-[16px]" src={emailIcon} alt="email icon" />
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                value={email}
              />
            </div>
          ) : (
            <div className="flex w-[calc(100%-16px)] items-center gap-2 truncate ">
              <img className="h-[16px]" src={emailIcon} alt="email icon" />
              <p className="text-xs font-medium">{email}</p>
            </div>
          )}

          {isEditingEmail ? (
            <button
              className={`${isEditingEmail ? 'min-w-[16px]' : 'hidden'}`}
              onClick={handleSaveEmail}
            >
              <img src={saveIcon} alt="edit icon" />
            </button>
          ) : (
            <button
              className={`${isHoveringEmail ? 'min-w-[16px]' : 'hidden'}`}
              onClick={() => setIsEditingEmail(!isEditingEmail)}
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default VendorOwnerFixedCards;
