import { useQuery } from '@apollo/client';
import type { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import type {
  GetSuggestedVisitsResponse,
  SuggestedVisit,
} from '@/types/service';

import {
  DASHBOARD_TABLES_MAX_RESULTS,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../config/constants';
import { isSecondarySidebarOpen } from '../../../recoil/navigation';
import { GET_SERVICE_SUGGESTED_VISITS } from '../../../services/graphql/service/service-querys';
import type { SuggestedVisitTable } from '../../../types/tables';
import { convertToAmericanDateFormat } from '../../../utils/convertTimestamp';
import DataTable from '../common/DataTable/DataTable';
import DTHeader from '../common/DataTable/DTHeader';

type Props = {
  forModal?: boolean;
  toggleModal?: () => void;
};

const DTable = ({ forModal = false, toggleModal }: Props) => {
  const [data, setData] = useState<SuggestedVisit[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [filters, setFilters] = useState({
    orderBy: '',
    orderDirection: '',
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  // Open the secondary sidebar through the recoil state
  const [isOpen, setIsOpen] = useRecoilState(isSecondarySidebarOpen);

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const columnHelper = createColumnHelper<SuggestedVisitTable>();

  const columns = [
    columnHelper.accessor('client_name', {
      cell: (info) => (
        <span className="text-xs font-bold">{info.getValue()}</span>
      ),
      header: ({ ...info }) => (
        <DTHeader
          sortable
          onClick={() => handleChangeSortDirection('name')}
          title={t('dashboard.th-business-name')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="client_name"
        />
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: (info) => (
        <span className="text-xs font-medium">{info.getValue()}</span>
      ),
      header: ({ ...info }) => (
        <DTHeader
          sortable
          onClick={() => handleChangeSortDirection('services')}
          title={t('dashboard.th-services')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="name"
        />
      ),
    }),
    columnHelper.accessor('last_service', {
      header: ({ ...info }) => (
        <DTHeader
          sortable
          onClick={() => handleChangeSortDirection('lastVisit')}
          title={t('dashboard.th-last-visit')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="last_service"
        />
      ),
      cell: (info) => (
        <span className="text-xs font-medium">
          {info.getValue() && convertToAmericanDateFormat(info.getValue())}
        </span>
      ),
    }),
    columnHelper.accessor('frequency', {
      cell: (info) => (
        <span className="text-xs font-medium">{info.getValue()}</span>
      ),
      header: ({ header, ...info }) => (
        <DTHeader
          sortable
          onClick={() => handleChangeSortDirection(header.id)}
          title={t('dashboard.th-frequency')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="frequency"
        />
      ),
    }),
  ];

  // Suggested
  const {
    data: fetchData,
    loading,
    error,
  } = useQuery<GetSuggestedVisitsResponse>(GET_SERVICE_SUGGESTED_VISITS, {
    variables: {
      ready_to_visit: true,
      limit: forModal ? 6 : DASHBOARD_TABLES_MAX_RESULTS,
      orderBy: filters?.orderBy || 'business_name',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      if (
        fetchData &&
        fetchData.getSuggestedVisits &&
        fetchData.getSuggestedVisits.data
      ) {
        setData(fetchData.getSuggestedVisits.data);
        setTotal(
          fetchData.getSuggestedVisits.count -
            fetchData.getSuggestedVisits.data.length,
        );
        setTableTotal(fetchData.getSuggestedVisits.count);
      }
    },
  });
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    state: {
      sorting,
      columnFilters,
    },
  });

  if (error) {
    return <p>Error : {error.message}</p>;
  }

  // FUNCTIONS

  const handleRowClick = (id: string, event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isCellClick = target.classList.contains('cell-class');

    if (!isCellClick) {
      return;
    }

    if (!isOpen) {
      setIsOpen(true);
    }

    const row = data.find((_row) => _row.id === id);
    const clientId = row?.client_id;
    const service = row?.name;

    switch (service) {
      case OIL_DISPOSAL_SERVICE_NAME: {
        navigate(`/dashboard/clients/oil-disposal/${clientId}`);
        break;
      }
      case HOOD_CLEANING_SERVICE_NAME: {
        navigate(`/dashboard/clients/hood-cleaning/${clientId}`);
        break;
      }
      case GREASE_TRAP_SERVICE_NAME: {
        navigate(`/dashboard/clients/grease-trap-cleaning/${clientId}`);
        break;
      }
      default: {
        navigate(`/dashboard/clients/${service}/${clientId}`);
      }
    }
  };

  return (
    <div className="min-h-[350px] min-w-[488px]">
      <DataTable
        error={error}
        handleRowClick={handleRowClick}
        loading={loading}
        table={table}
        total={tableTotal}
        tableId="RoutesSuggestedTable"
        sorting={{ ...filters }}
        loadingRows={tableTotal || DASHBOARD_TABLES_MAX_RESULTS}
        pagination={false}
      />

      {!forModal && (
        <div className="my-6 flex w-full justify-center">
          <button
            id="openModalSuggested"
            onClick={toggleModal}
            className="text-[12px] font-bold text-green underline"
          >
            + {total} {t('dashboard.table-suggested-title')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DTable;
