import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useNotification = () => {
  const { t } = useTranslation('common');

  const notifySaveSuccess = () => {
    toast.success(t('notifications.save-ok'), {
      toastId: 'notifySaveOk',
    });
  };

  const notifySaveError = () => {
    toast.error(t('notifications.save-error'), {
      toastId: 'notifySaveError',
    });
  };

  const notifyUpdateError = () =>
    toast.error(t('notifications.update-error'), {
      toastId: 'notifyUpdateError',
    });

  const notifyUpdateOk = () =>
    toast.success(t('notifications.update-ok'), {
      toastId: 'notifyUpdateOk',
    });

  const notifyDeleteOk = () =>
    toast.success(t('notifications.delete-ok'), {
      toastId: 'notifyDeleteOk',
    });

  const notifyDeleteError = () =>
    toast.error(t('notifications.delete-error'), {
      toastId: 'notifyDeleteError',
    });

  const notifyWrongPassword = () =>
    toast.error(t('profile.wrong-password'), {
      toastId: 'notifyWrongPassword',
    });

  const notifyAddressError = () =>
    toast.warn(t('modalNewClient.address-error'), {
      toastId: 'notifyAddressError',
    });

  const notifyEmptyWarn = () =>
    toast.error(t('modalNewService.warn-empty'), {
      toastId: 'notifyEmptyWarn',
    });

  const notifyExistsWarn = () =>
    toast.error(t('modalNewService.error-exists'), {
      toastId: 'notifyExistsWarn',
    });

  const notifyNoSelection = () =>
    toast.warn(t('notifications.no-selection'), {
      toastId: 'notifyNoSelection',
    });

  const notifyUserExistsError = () =>
    toast.error(t('notifications.user-exists'), {
      toastId: 'notifyUserExists',
    });

  const notifyVendorExistsError = () =>
    toast.error(t('notifications.vendor-exists'), {
      toastId: 'notifyUserExists',
    });

  return {
    notifySaveSuccess,
    notifySaveError,
    notifyUpdateError,
    notifyUpdateOk,
    notifyDeleteOk,
    notifyDeleteError,
    notifyWrongPassword,
    notifyAddressError,
    notifyEmptyWarn,
    notifyExistsWarn,
    notifyNoSelection,
    notifyUserExistsError,
    notifyVendorExistsError,
  };
};
