import { Route, Routes } from 'react-router-dom';

import { ROLES } from '../config/permissions';
import { usePermissions } from '../hooks/usePermission';
import DashboardLayout from '../layouts/DashboardLayout';
import CheckAuth from '../views/auth/CheckAuth';
import ClientDetailView from '../views/clients/ClientDetailView';
import ClientDocumentView from '../views/clients/ClientDocumentView';
import ClientFatBoneView from '../views/clients/ClientFatBoneView';
import ClientGreaseTrapView from '../views/clients/ClientGreaseTrapView';
import ClientHoodCleaningView from '../views/clients/ClientHoodCleaningView';
import ClientOilDisposalView from '../views/clients/ClientOilDisposalView';
import ClientsView from '../views/clients/ClientsView';
import DashboardView from '../views/dashboard/DashboardView';
import DropoffsView from '../views/dropoffs/DropoffsView';
import CashDepositView from '../views/invoices/cashDepositView';
import InvoicesView from '../views/invoices/InvoicesView';
import NotFoundView from '../views/NotFoundView';
import ProfileView from '../views/profile/ProfileView';
import RouteDetailView from '../views/routes/RouteDetailView';
import RouteEditView from '../views/routes/RouteEditView';
import RoutesView from '../views/routes/RoutesView';
import Equipment from '../views/settings/Equipment';
import Licensing from '../views/settings/Licensing';
import Locations from '../views/settings/Locations';
import TruckDetailView from '../views/trucks/TruckDetailView';
import TrucksView from '../views/trucks/TrucksView';
import VendorDetailView from '../views/vendors/VendorDetailView';
import VendorDocumentView from '../views/vendors/VendorDocumentView';
import VendorDropoffsView from '../views/vendors/VendorDropoffsView';
import VendorsView from '../views/vendors/VendorsView';
import VendorTransactionsView from '../views/vendors/VendorTransactionsView';
import WorkerDetailView from '../views/workers/WorkerDetailView';
import WorkersView from '../views/workers/WorkersView';
import RequireAuth from './RequireAuth';

const AppRouter = () => {
  const { checkRole } = usePermissions();
  const isSupervisor = checkRole(ROLES.SUPERVISOR);

  return (
    <>
      <Routes>
        <Route path="/" element={<CheckAuth />} />
        <Route
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          {/* Common routes for all roles */}
          <Route path="*" element={<NotFoundView />} />
          {/* DASHBOARD */}
          <Route path="/dashboard" element={<DashboardView />} />
          {/* PROFILE */}
          <Route path="/dashboard/profile" element={<ProfileView />} />
          {/* ROUTES */}
          <Route path="/dashboard/routes" element={<RoutesView />} />
          <Route path="/dashboard/routes/:id" element={<RouteDetailView />} />
          <Route
            path="/dashboard/routes/edit/:id"
            element={<RouteEditView />}
          />

          {!isSupervisor && (
            <>
              {/* Rutas para otros roles que no son SUPERVISOR */}
              {/* CLIENTS */}
              <Route path="/dashboard/clients" element={<ClientsView />} />
              <Route
                path="/dashboard/clients/overall/:client"
                element={<ClientDetailView />}
              />
              <Route
                path="/dashboard/clients/grease-trap-cleaning/:client"
                element={<ClientGreaseTrapView />}
              />
              <Route
                path="/dashboard/clients/oil-disposal/:client"
                element={<ClientOilDisposalView />}
              />
              <Route
                path="/dashboard/clients/hood-cleaning/:client"
                element={<ClientHoodCleaningView />}
              />
              <Route
                path="/dashboard/clients/meat-fat-bone/:client"
                element={<ClientFatBoneView />}
              />
              <Route
                path="/dashboard/clients/document/:client"
                element={<ClientDocumentView />}
              />
              {/* VENDORS */}
              <Route path="/dashboard/vendors" element={<VendorsView />} />
              <Route
                path="/dashboard/vendors/overall/:vendor"
                element={<VendorDetailView />}
              />
              <Route
                path="/dashboard/vendors/documents/:vendor"
                element={<VendorDocumentView />}
              />
              <Route
                path="/dashboard/vendors/dropoffs/:vendor"
                element={<VendorDropoffsView />}
              />
              <Route
                path="/dashboard/vendors/transactions/:vendor"
                element={<VendorTransactionsView />}
              />
              {/* DROPOFFS */}
              <Route path="/dashboard/dropoffs" element={<DropoffsView />} />
              {/* TRUCKS */}
              <Route path="/dashboard/trucks" element={<TrucksView />} />
              <Route
                path="/dashboard/trucks/:id"
                element={<TruckDetailView />}
              />
              {/* INVOICES */}
              <Route path="/dashboard/invoices" element={<InvoicesView />} />
              <Route
                path="/dashboard/invoices/cashdeposit"
                element={<CashDepositView />}
              />
              {/* WORKERS */}
              <Route path="/dashboard/workers" element={<WorkersView />} />
              <Route
                path="/dashboard/workers/:id"
                element={<WorkerDetailView />}
              />
              {/* SETTINGS */}
              <Route path="/dashboard/settings" element={<Licensing />} />
              <Route
                path="/dashboard/settings/equipment"
                element={<Equipment />}
              />
              <Route
                path="/dashboard/settings/location"
                element={<Locations />}
              />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
