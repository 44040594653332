import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import closeIcon from '../../assets/icons/close-square.svg';
import { useModal } from '../../hooks/useModal';
import { isWhiteModalContainerOpenState } from '../../recoil/modalContainerWhite';

type Props = {
  children?: React.ReactNode;
};

const ModalRightSide = ({ children }: Props) => {
  const { handleCloseAllModals } = useModal();
  const modalRef = useRef(null);
  const isWhiteModalContainerOpen = useRecoilValue(
    isWhiteModalContainerOpenState,
  );

  const handleClose = () => {
    handleCloseAllModals();
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event.target) &&
        event.target.id === 'openedModal'
      ) {
        handleCloseAllModals();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div id="openedModal" className="fixed z-50 h-full w-full bg-gray-500/50">
      <div
        className={`absolute right-0 top-0 h-full w-full overflow-y-auto rounded-l-3xl bg-white p-6 shadow-2xl shadow-gray-800 transition-all duration-500 md:w-[450px] ${
          isWhiteModalContainerOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        ref={modalRef}
      >
        <div className="flex h-[20px] items-start justify-end md:h-[40px]">
          <button onClick={handleClose} id="modalClose">
            <img src={closeIcon} alt="icon close" />
          </button>
        </div>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default ModalRightSide;
