import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import EditReceipt from '../components/Modals/Content/EditReceipt';
import FilterClient from '../components/Modals/Content/Filters/FilterClient';
import FilterClientDocuments from '../components/Modals/Content/Filters/FilterClientDocuments';
import FilterDropoff from '../components/Modals/Content/Filters/FilterDropoffs';
import FilterEditRoute from '../components/Modals/Content/Filters/FilterEditRoute';
import FilterInvoice from '../components/Modals/Content/Filters/FilterInvoice';
import FilterPayment from '../components/Modals/Content/Filters/FilterPayment';
import FilterRoute from '../components/Modals/Content/Filters/FilterRoute';
import FilterTruck from '../components/Modals/Content/Filters/FilterTruck';
import FilterVendor from '../components/Modals/Content/Filters/FilterVendor';
import FilterVendorDocuments from '../components/Modals/Content/Filters/FilterVendorDocuments';
import FilterWorker from '../components/Modals/Content/Filters/FilterWorker';
import NewCashDisburse from '../components/Modals/Content/NewCashDisburse';
import NewClient from '../components/Modals/Content/NewClient';
import NewDropoff from '../components/Modals/Content/NewDropoff';
import NewEquipment from '../components/Modals/Content/NewEquipment';
import NewIncident from '../components/Modals/Content/NewIncident';
import NewLocation from '../components/Modals/Content/NewLocation';
import NewPerson from '../components/Modals/Content/NewPerson';
import NewReceipt from '../components/Modals/Content/NewReceipt';
import NewRoute from '../components/Modals/Content/NewRoute';
import NewService from '../components/Modals/Content/NewService';
import NewTruck from '../components/Modals/Content/NewTruck';
import NewVendor from '../components/Modals/Content/NewVendor';
import NewVendorDriver from '../components/Modals/Content/NewVendorDriver';
import NewVendorLoan from '../components/Modals/Content/NewVendorLoan';
import NewVendorTruck from '../components/Modals/Content/NewVendorTruck';
import NewWithDraw from '../components/Modals/Content/NewWithDraw';
import NewWorker from '../components/Modals/Content/NewWorker';
import TruckDrivers from '../components/Modals/Content/TruckDrivers';
import TruckServices from '../components/Modals/Content/TruckServices';
import ModalCreate from '../components/Modals/ModalCreate';
import ModalDelete from '../components/Modals/ModalDelete';
import ModalEdit from '../components/Modals/ModalEdit';
import ModalFullImage from '../components/Modals/ModalFullImage';
import ModalRightSide from '../components/Modals/ModalRightSide';
import ModalSuccess from '../components/Modals/ModalSuccess';
import Navigation from '../components/Navigation/Navigation';
import { isClientDocumentsFilterModalOpenAtom } from '../recoil/modalClientDocuments';
import { isClientsFilterModalOpenAtom } from '../recoil/modalClientsFilter';
import { createModalStateAtom } from '../recoil/modalConfirm';
import { isRightSideModalOpenState } from '../recoil/modalContainer';
import { confirmationModalState } from '../recoil/modalDelete';
import { isDropoffsFilterModalOpenAtom } from '../recoil/modalDropoffsFilter';
import { editModalState } from '../recoil/modalEdit';
import { isEditReceiptModalOpenAtom } from '../recoil/modalEditReceipt';
import { isEditRouteFilterModalOpenAtom } from '../recoil/modalEditRouteFilter';
import { isImageModalOpenAtom } from '../recoil/modalImage';
import { isInvoicesFilterModalOpenAtom } from '../recoil/modalInvoicesFilter';
import { isNewCashDisbursedModalOpenAtom } from '../recoil/modalNewCashDisbursed';
import { isNewClientModalOpenAtom } from '../recoil/modalNewClient';
import { isNewDropoffModalOpenAtom } from '../recoil/modalNewDropoff';
import { isNewEquipmentModalOpenAtom } from '../recoil/modalNewEquipment';
import { isNewIncidentModalOpenAtom } from '../recoil/modalNewIncident';
import { ismodalNewLocationOpenAtom } from '../recoil/modalNewLocation';
import { isNewPersonModalOpenAtom } from '../recoil/modalNewPerson';
import { isNewReceiptModalOpenAtom } from '../recoil/modalNewReceipt';
import { isNewRouteModalOpenAtom } from '../recoil/modalNewRoute';
import { isNewServiceModalOpenAtom } from '../recoil/modalNewService';
import { isNewTruckModalOpenAtom } from '../recoil/modalNewTruck';
import { isNewVendorModalOpenAtom } from '../recoil/modalNewVendor';
import { isNewVendorDriverModalOpenAtom } from '../recoil/modalNewVendorDriver';
import { isNewVendorLoanModalOpenAtom } from '../recoil/modalNewVendorLoan';
import { isNewVendorTruckModalOpenAtom } from '../recoil/modalNewVendorTruck';
import { isNewWithDrawModalOpenAtom } from '../recoil/modalNewWithDraw';
import { isNewWorkerModalOpenAtom } from '../recoil/modalNewWorker';
import { isPaymentFilterModalOpenAtom } from '../recoil/modalPaymentFilter';
import { isRoutesFilterModalOpenAtom } from '../recoil/modalRoutesFilter';
import { successModalStateAtom } from '../recoil/modalSuccess';
import { isTruckDriversModalOpenAtom } from '../recoil/modalTruckDrivers';
import { isTruckServiceModalOpenAtom } from '../recoil/modalTruckService';
import { isTrucksFilterModalOpenAtom } from '../recoil/modalTrucksFilter';
import { isVendorDocumentsFilterModalOpenAtom } from '../recoil/modalVendorDocuments';
import { isVendorsFilterModalOpenAtom } from '../recoil/modalVendorsFilter';
import { isWorkersFilterModalOpenAtom } from '../recoil/modalWorkersFilter';

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [secondarySidebarOpen, setSecondarySidebarOpen] =
    useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const openSecondarySidebar = () => {
    setSecondarySidebarOpen(true);
  };

  const closeSecondarySidebar = () => {
    setSecondarySidebarOpen(false);
  };

  const [isRightSideModalOpen] = useRecoilState(isRightSideModalOpenState);
  const isDropoffsFilterModalOpen = useRecoilValue(
    isDropoffsFilterModalOpenAtom,
  );
  const isNewClientModalStateAtom = useRecoilValue(isNewClientModalOpenAtom);
  const isClientsFilterModalOpen = useRecoilValue(isClientsFilterModalOpenAtom);
  const isVendorsFilterModalOpen = useRecoilValue(isVendorsFilterModalOpenAtom);
  const isNewPersonModalStateAtom = useRecoilValue(isNewPersonModalOpenAtom);
  const isNewVendorDriverModalOpen = useRecoilValue(
    isNewVendorDriverModalOpenAtom,
  );
  const isNewVendorTruckModalOpen = useRecoilValue(
    isNewVendorTruckModalOpenAtom,
  );
  const isNewServiceModalStateAtom = useRecoilValue(isNewServiceModalOpenAtom);
  const isRoutesFilterModalOpen = useRecoilValue(isRoutesFilterModalOpenAtom);
  const isPaymentFilterModalOpen = useRecoilValue(isPaymentFilterModalOpenAtom);
  const isNewRouteModalOpen = useRecoilValue(isNewRouteModalOpenAtom);
  const isClientDocumentsFilterModalOpen = useRecoilValue(
    isClientDocumentsFilterModalOpenAtom,
  );
  const isVendorDocumentsFilterModalOpen = useRecoilValue(
    isVendorDocumentsFilterModalOpenAtom,
  );
  const isInvoiceFilterModalOpen = useRecoilValue(
    isInvoicesFilterModalOpenAtom,
  );
  const isNewTruckModalStateAtom = useRecoilValue(isNewTruckModalOpenAtom);
  const isNewLoanModalStateAtom = useRecoilValue(isNewVendorLoanModalOpenAtom);
  const isTruckFilterModalOpen = useRecoilValue(isTrucksFilterModalOpenAtom);
  const isNewWorkerModalState = useRecoilValue(isNewWorkerModalOpenAtom);
  const isWorkersFilterModalOpen = useRecoilValue(isWorkersFilterModalOpenAtom);
  const isNewReceiptModalOpen = useRecoilValue(isNewReceiptModalOpenAtom);
  const isEditReceiptModalOpen = useRecoilValue(isEditReceiptModalOpenAtom);
  const isTruckServiceModalOpen = useRecoilValue(isTruckServiceModalOpenAtom);
  const isTruckDriversModalOpen = useRecoilValue(isTruckDriversModalOpenAtom);
  const isNewIncidentModalOpen = useRecoilValue(isNewIncidentModalOpenAtom);
  const isNewLocationModalOpen = useRecoilValue(ismodalNewLocationOpenAtom);
  const isEditRouteFilterModalOpen = useRecoilValue(
    isEditRouteFilterModalOpenAtom,
  );
  const isNewEquipmentModalOpen = useRecoilValue(isNewEquipmentModalOpenAtom);
  const isNewVendorModalOpen = useRecoilValue(isNewVendorModalOpenAtom);
  const isNewWithDrawOpen = useRecoilValue(isNewWithDrawModalOpenAtom);
  const isNewDropoffOpen = useRecoilValue(isNewDropoffModalOpenAtom);
  const isNewCashDisbursedOpen = useRecoilValue(
    isNewCashDisbursedModalOpenAtom,
  );

  const [imageModalState, setImageModalState] =
    useRecoilState(isImageModalOpenAtom);

  const toggleFullImageModal = () => {
    setImageModalState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      isQR: false,
    }));
  };

  const confirmationModalStateAtom = useRecoilValue(confirmationModalState);
  const editModalStateAtom = useRecoilValue(editModalState);
  const createModalState = useRecoilValue(createModalStateAtom);
  const successModalState = useRecoilValue(successModalStateAtom);
  return (
    <>
      {confirmationModalStateAtom.isOpen && <ModalDelete />}
      {editModalStateAtom.isOpen && <ModalEdit />}
      {imageModalState.isOpen && (
        <ModalFullImage toggleModal={toggleFullImageModal} />
      )}
      {createModalState.isOpen && <ModalCreate />}
      {successModalState.isOpen && <ModalSuccess />}
      {isRightSideModalOpen && (
        <ModalRightSide>
          {isEditRouteFilterModalOpen && <FilterEditRoute />}
          {isNewWorkerModalState && <NewWorker />}
          {isWorkersFilterModalOpen && <FilterWorker />}
          {isNewTruckModalStateAtom && <NewTruck />}
          {isTruckFilterModalOpen && <FilterTruck />}
          {isInvoiceFilterModalOpen && <FilterInvoice />}
          {isClientDocumentsFilterModalOpen && <FilterClientDocuments />}
          {isVendorDocumentsFilterModalOpen && <FilterVendorDocuments />}
          {isNewClientModalStateAtom && <NewClient />}
          {isNewVendorModalOpen && <NewVendor />}
          {isClientsFilterModalOpen && <FilterClient />}
          {isVendorsFilterModalOpen && <FilterVendor />}
          {isNewPersonModalStateAtom && <NewPerson />}
          {isNewVendorTruckModalOpen && <NewVendorTruck />}
          {isNewVendorDriverModalOpen && <NewVendorDriver />}
          {isNewServiceModalStateAtom && <NewService />}
          {isRoutesFilterModalOpen && <FilterRoute />}
          {isNewRouteModalOpen && <NewRoute />}
          {isNewReceiptModalOpen && <NewReceipt />}
          {isEditReceiptModalOpen && <EditReceipt />}
          {isTruckServiceModalOpen && <TruckServices />}
          {isTruckDriversModalOpen && <TruckDrivers />}
          {isNewIncidentModalOpen && <NewIncident />}
          {isNewEquipmentModalOpen && <NewEquipment />}
          {isNewLocationModalOpen && <NewLocation />}
          {isNewWithDrawOpen && <NewWithDraw />}
          {isNewCashDisbursedOpen && <NewCashDisburse />}
          {isPaymentFilterModalOpen && <FilterPayment />}
          {isNewLoanModalStateAtom && <NewVendorLoan />}
          {isDropoffsFilterModalOpen && <FilterDropoff />}
          {isNewDropoffOpen && <NewDropoff />}
        </ModalRightSide>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex">
        <Navigation
          toggleSideBar={toggleSidebar}
          openSecondarySidebar={openSecondarySidebar}
          closeSecondarySidebar={closeSecondarySidebar}
        />
        <div
          className={`h-full w-full px-6 pt-6 text-blacker transition-all duration-1000
          md:px-12 md:pt-8
          ${!sidebarOpen && !secondarySidebarOpen ? 'ml-[58px]' : ''}
          ${sidebarOpen && !secondarySidebarOpen ? 'ml-[212px]' : ''}
          ${!sidebarOpen && secondarySidebarOpen ? 'ml-[116px]' : ''}
          ${sidebarOpen && secondarySidebarOpen ? 'ml-[270px]' : ''}
          `}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
