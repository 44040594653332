type Props = {
  title: string;
  value?: string | number | boolean | undefined | null;
  value_list?: string[];
};

const NewStopCardElement = ({ title, value, value_list }: Props) => {
  return (
    <div className="flex min-w-[215px] justify-between gap-2 border-b-[1px] border-greenextralight">
      <span className="min-w-[120px] text-xs text-greenextralight">
        {title}
      </span>
      {value_list ? (
        <div className="flex flex-col">
          {value_list.map((v) => (
            <span key={Math.random() + v} className="truncate text-xs">
              {v}
            </span>
          ))}
        </div>
      ) : (
        <span className="truncate text-xs">{value && value}</span>
      )}
    </div>
  );
};

export default NewStopCardElement;
