import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type {
  DocumentToShow,
  GetDocumentByClientIdQuery,
} from '@/types/document';

import actionIcon from '../../../../assets/icons/action-button.svg';
import filterIcon from '../../../../assets/icons/filter.svg';
import searchIcon from '../../../../assets/icons/search.svg';
import deleteIcon from '../../../../assets/icons/trash.svg';
import { CLIENTS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import useDebouceTime from '../../../../hooks/useDebounceTime';
import { useModal } from '../../../../hooks/useModal';
import { useNotification } from '../../../../hooks/useNotification';
import { clientDocumentsFiltersState } from '../../../../recoil/clientDocumentsFilter';
import { cleanDocumentsFiltersState } from '../../../../recoil/clientDocumentsFilterClean';
import { selectedClient } from '../../../../recoil/navigation';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { GET_DOCUMENT_BY_CLIENTID } from '../../../../services/graphql/document/document.queries';
import { DOCUMENT_DELETE } from '../../../../services/graphql/document/document-mutations';
import type { ClientsDocumentTable } from '../../../../types/tables';
import { convertToAmericanDateTimeFormat } from '../../../../utils/convertTimestamp';
import DataTable from '../../common/DataTable/DataTable';
import DTHeader from '../../common/DataTable/DTHeader';
import DocumentActions from '../../common/DocumentActions';
import FilterMark from '../../common/FilterMark';

const DTDocuments = () => {
  const { t } = useTranslation('common');
  const { notifyDeleteError, notifyDeleteOk, notifyNoSelection } =
    useNotification();
  const location = useLocation();

  // States

  const [filters, setFilters] = useRecoilState(clientDocumentsFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [deleteIconState, setDeleteIconState] = useState<boolean>(false);
  const client = useRecoilValue(selectedClient);
  const cleanFilters = useRecoilValue(cleanDocumentsFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<DocumentToShow[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchDocuments,
  } = useQuery<GetDocumentByClientIdQuery>(GET_DOCUMENT_BY_CLIENTID, {
    variables: {
      clientId: [client.id],
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
  });

  const [deleteDocument] = useMutation<any>(DOCUMENT_DELETE, {
    onCompleted: () => {
      refetchDocuments({ limit: pageSize, offset: pageIndex * pageSize });
      notifyDeleteOk();
      setDeleteIconState(!deleteIconState);
    },
    onError: () => {
      notifyDeleteError();
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getDocuments && fetchData.getDocuments.data) {
      const updatedData: DocumentToShow[] = fetchData.getDocuments.data.map(
        (document) => ({
          id: document.id,
          name: document.name || '',
          type: document.type || '',
          date: document.date || '',
          url: document.url || '',
        }),
      );

      setData(updatedData);
      setTotal(fetchData.getDocuments.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<ClientsDocumentTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        size: 30,
        minSize: 30,
        maxSize: 30,
        header: ({ table }) => (
          <input
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="table-checkbox-th flex h-[18px] w-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        cell: ({ row }) => (
          <input
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="flex h-[18px] w-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      }),
      columnHelper.accessor('name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-bold">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-document-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="name"
          />
        ),
      }),
      columnHelper.accessor('type', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-document-type')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="type"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateTimeFormat(info.getValue() as string)
              : ''}
          </span>
        ),
      }),
      columnHelper.accessor('url', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.id}
            documentName={info.row.original.name}
            deleteDocument={deleteDocument}
            url={info.getValue()}
          />
        ),
        header: () => (
          <DTHeader title={t('client-documents.th-document')} accessor="url" />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Debounce Name filtering
  const { handleFilterChangeWithDebounce, filterValue } = useDebouceTime({
    setFilters,
    filters,
    key: 'nameFilter',
  });

  // Delete clients from row selection

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  useEffect(() => {
    if (table.getSelectedRowModel().flatRows.length > 0) {
      setSelectedDocuments(
        table.getSelectedRowModel().flatRows.map((row) => row.original.id),
      );
    }
  }, [table.getSelectedRowModel().flatRows]);

  const [deleteDocuments, { loading: deleteDocumentsLoading }] =
    useMutation<any>(DOCUMENT_DELETE, {
      variables: {
        id: selectedDocuments,
      },
      onCompleted: () => {
        refetchDocuments({ limit: pageSize, offset: pageIndex * pageSize });
        notifyDeleteOk();
        table.resetRowSelection();
      },
      onError: () => {
        notifyDeleteError();
      },
    });

  const handleRemoveCients = () => {
    if (selectedDocuments.length > 0) {
      deleteDocuments();
    } else {
      notifyNoSelection();
    }
  };

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetchDocuments({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // Modals
  const { handleOpenClientDocumentsModal } = useModal();

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <div className="my-2 flex w-full justify-between gap-4">
          <div className="flex gap-4">
            <div className="flex h-[41px] w-[360px] items-center rounded-[15px] border border-greenlight">
              <div className="flex w-[30px] items-center justify-center">
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="h-[16px] w-[16px]"
                />
              </div>
              <input
                value={filterValue}
                onChange={handleFilterChangeWithDebounce}
                type="text"
                id="searchBarDocuments"
                placeholder={t('clients.filter-placeholder')}
                className="inline-flex  h-[90%] w-[88%]  items-center justify-start gap-2.5 border-transparent p-2 text-base font-medium focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
              />
            </div>
            <button
              onClick={handleOpenClientDocumentsModal}
              className="button-icon inline-flex h-[41px] w-[46px] items-center justify-center rounded-[5px] border border-greenlight p-2 hover:bg-green"
            >
              <img src={filterIcon} alt="filter icon" />
            </button>
          </div>
          <div className="relative flex gap-4">
            <button
              onClick={() => setDeleteIconState(!deleteIconState)}
              className="button-icon-nohover inline-flex h-[41px] w-[46px] items-center justify-center rounded-[5px] border border-greenlight p-2"
            >
              <img src={actionIcon} alt="more icon" />
            </button>
            <button
              onClick={handleRemoveCients}
              className={`absolute bottom-[-26px] right-0 h-[25px] w-full min-w-[130px] items-center gap-1 rounded-md bg-white px-2 ${
                deleteIconState ? 'flex' : 'hidden'
              }`}
            >
              <img src={deleteIcon} alt="delete icon" />
              <span className="select-none text-sm font-normal">
                {t('clients.delete-button')}
              </span>
            </button>
          </div>
        </div>
        <div className="my-2 flex w-full flex-wrap gap-2">
          {filters &&
            Object.entries(filters).map(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                name !== 'nameFilter' &&
                name !== 'orderDirection' &&
                name !== 'orderBy' &&
                name !== 'limit' &&
                name !== 'offset' && (
                  <FilterMark
                    key={name}
                    filterName={name}
                    filterValue={value}
                    atom={clientDocumentsFiltersState}
                  />
                ),
            )}
        </div>
        {/* <table
          style={{ tableLayout: 'fixed', width: '100%' }}
          className="w-full table-fixed"
        >
          <thead className="h-[37px] bg-green text-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  let className = '';

                  if (index === 0) {
                    className = 'rounded-l-lg';
                  } else if (index === headerGroup.headers.length - 1) {
                    className = 'rounded-r-lg';
                  }

                  return (
                    <th
                      colSpan={header.colSpan}
                      style={{
                        width: header.getSize(),
                      }}
                      key={header.id}
                      className={`px-2 text-left ${className}`}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  <p>Error: {error.message}</p>
                </td>
              </tr>
            ) : (
              total === 0 && (
                <tr className="h-[40px]">
                  <td colSpan={table.getAllColumns().length}>
                    <p className="text-xs ">{t('global.no-data')}</p>
                  </td>
                </tr>
              )
            )}
            {table.getRowModel().rows.map((row) => (
              <tr
                className="h-[59px] border-b-[1px] border-graylight hover:bg-graydark"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="cell-class px-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header, index) => {
                  let className = '';

                  if (index === 0) {
                    className = 'rounded-l-lg';
                  } else if (index === footerGroup.headers.length - 1) {
                    className = 'rounded-r-lg';
                  }

                  return (
                    <th key={header.id} className={`px-2 ${className}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="my-6">
          <div className="flex items-center gap-2">
            <span className="text-[14px] font-medium">Page</span>
            <div className="flex h-[29px] items-center justify-center rounded-[5px] border border-greenlight">
              <button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <img src={beforemaxIcon} alt="before icon" />
              </button>
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <img src={beforeIcon} alt="before icon" />
              </button>
              <div className="flex items-center gap-1">
                <div className="dropdown-container relative inline-flex">
                  <select
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                    style={{
                      backgroundImage: `url(${arrowDownIcon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 2px center',
                      backgroundSize: '16px 16px',
                      cursor: 'pointer',
                    }}
                    className="inline-block h-[24px] w-[40px] border-none pr-1 text-center text-[14px] font-normal focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  >
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => (
                        <option
                          className="text-center"
                          key={index + 1}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      ),
                    )}
                  </select>
                </div>
              </div>
              <button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <img src={nextIcon} alt="next icon" />
              </button>
              <button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <img src={nextmaxIcon} alt="next icon" />
              </button>
            </div>

            <span className="text-[14px] font-medium">Show</span>
            <select
              className="inline-block h-[28px] w-[63px] rounded-[5px] border border-greenlight pl-2 pr-4 text-center text-[14px] font-normal focus:outline-none focus:ring-0 focus:ring-offset-0"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              style={{
                backgroundImage: `url(${arrowDownIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 10px center',
                backgroundSize: '16px 16px',
                cursor: 'pointer',
              }}
            >
              {[10, 20, 30, 40, 50].map((_pageSize) => (
                <option key={_pageSize} value={_pageSize}>
                  {_pageSize}
                </option>
              ))}
            </select>
            {loading && (
              <div>
                <LoaderSmall />
              </div>
            )}
            {deleteDocumentsLoading && (
              <div>
                <LoaderSmall />
              </div>
            )}
          </div>
        </div> */}

        <DataTable
          error={error}
          loading={loading || deleteDocumentsLoading}
          table={table}
          total={total}
          tableId="DocumentsTable"
          sorting={{ ...filters }}
          loadingRows={total || pageSize}
        />
      </div>
    </>
  );
};

export default DTDocuments;
