export function convertDateToString(date:any): string {
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); 
  const day = String(dateObj.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function convertStringToDate(dateString: string): Date {
  const [year, month, day] = dateString.split("-");
  const dateObj = new Date(Number(year), Number(month) - 1, Number(day));
  return dateObj;
}

export function convertStrToDate(dateString: string | number): Date {
  if (typeof dateString === "string") {
    const date = new Date(parseInt(dateString, 10))
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    // return new Date(date.getTime() - userTimezoneOffset);
    return new Date(date.getTime() + (userTimezoneOffset * Math.sign(userTimezoneOffset)));
    // return new Date(parseInt(dateString, 10));
  }

  if (typeof dateString === "number") {
    const date = new Date(dateString)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + (userTimezoneOffset * Math.sign(userTimezoneOffset)));
    // return new Date(date.getTime() - userTimezoneOffset);
    // return new Date(dateString);
  }
  throw new Error("Invalid date format from convertStrToDate function");
}

export function parseDateWithoutTimezone(dateString: string) {
  // Separar la cadena 'mm-dd-yyyy' en partes
  const [year, month, day] = dateString.split('-').map(Number);

  const date = new Date();
  date.setDate(day!);
  date.setMonth(month! - 1);
  date.setFullYear(year!);

  // Retornar la fecha (se creará correctamente sin ajustar el día)
  return date;

}