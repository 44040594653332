import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import vendorIcon from '../../assets/icons/vendor.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import { LoaderScreen } from '../../components/ui/LoaderScreen';

const DTVendors = React.lazy(
  () => import('../../components/ui/vendors/DTVendors'),
);

const VendorsView = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <section>
        <Breadcrumb title={t('vendors.title-h1')} image={vendorIcon} />
        <Suspense fallback={<LoaderScreen />}>
          <DTVendors />
        </Suspense>
      </section>
    </>
  );
};

export default VendorsView;
