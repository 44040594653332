import 'react-datepicker/dist/react-datepicker.css';

import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import dropoffIcon from '../../../../assets/icons/dropoffs.svg';
import saveBigIcon from '../../../../assets/icons/save.svg';
import {
  DROPOFF_STATUS,
  MIN_MAX_FFA,
  MIN_MAX_GALLON_PRICE,
  MIN_MAX_GALLONS,
  MIN_MAX_MIU,
  MIN_MAX_SULFUR,
  MIN_MAX_TOTAL_PAID,
  STEP_FFA,
  STEP_GALLON_PRICE,
  STEP_GALLONS,
  STEP_MIU,
  STEP_SULFUR,
  STEP_TOTAL_PAID,
} from '../../../../config/constants';
import { useModal } from '../../../../hooks/useModal';
import type { TDropoffsFilters } from '../../../../recoil/dropoffFilters';
import { dropoffsFiltersState } from '../../../../recoil/dropoffFilters';
import { GET_DROPOFF_OPTIONS } from '../../../../services/graphql/vendors/dropoffs/dropoffsQuery';
import type { GetDropoffOptionsResponse } from '../../../../types/dropoffs';
import Breadcrumb from '../../../ui/common/Breadcrumb';
import { Button } from '../../../ui/common/Button';
import DropdownMultiple from '../../../ui/common/DropdownMultiple';
import RangeSliderInput from '../../../ui/common/Inputs/RangeSliderInput';

const FilterDropoff = () => {
  const { t } = useTranslation('common');

  // Filters
  const [filters, setFilters] = useRecoilState(dropoffsFiltersState);

  const { setValue, watch, control, handleSubmit } = useForm<TDropoffsFilters>({
    defaultValues: {
      ...filters,
    },
  });

  // === QUERIES ===
  const { data: options } =
    useQuery<GetDropoffOptionsResponse>(GET_DROPOFF_OPTIONS);

  // Form title
  const breadcrumbTitle = `${t('dropoffs.title')}`;
  const breadcrumbSecondTitle = t('modalFilterDropoffs.filters');

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
  };

  const handleFiltersSave = (data: TDropoffsFilters) => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters, ...data };
    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  return (
    <div className="">
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={dropoffIcon}
      />
      <h1 className="text-xl font-[600]">{t('modalFilterDropoffs.filters')}</h1>
      <form
        onSubmit={handleSubmit(handleFiltersSave)}
        className="my-6 flex flex-col gap-2"
      >
        {/* Status Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={DROPOFF_STATUS}
            onChange={(val) => {
              setValue('statusFilter', val);
            }}
            label={t('modalFilterDropoffs.status')}
            selectedValues={watch('statusFilter') || []}
          />
        </div>
        {/* Vendor Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={options?.getDropoffOptions.data.vendorOptions || []}
            onChange={(val) => {
              setValue('vendorIds', val);
              setValue(
                'vendorNames',
                val.map(
                  (v) =>
                    options?.getDropoffOptions.data.vendorOptions?.find(
                      (o) => o.value === v,
                    )?.label || '',
                ),
              );
            }}
            label={t('modalFilterDropoffs.vendor')}
            selectedValues={watch('vendorIds') || []}
          />
        </div>
        {/* Location Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={options?.getDropoffOptions.data.locationOptions || []}
            onChange={(val) => {
              setValue('locationId', val);
              setValue(
                'locationNames',
                val.map(
                  (v) =>
                    options?.getDropoffOptions.data.locationOptions.find(
                      (o) => o.value === v,
                    )?.label || '',
                ),
              );
            }}
            label={t('modalFilterDropoffs.location')}
            selectedValues={watch('locationId') || []}
          />
        </div>
        {/* Total Gallons */}
        <RangeSliderInput
          min={MIN_MAX_GALLONS[0]}
          max={MIN_MAX_GALLONS[1]}
          step={STEP_GALLONS}
          control={control}
          label={t('modalFilterDropoffs.total-gallons')}
          fieldName="totalGallons"
          format="number"
          units={1000}
        />
        {/* Avg. MIU */}
        <RangeSliderInput
          min={MIN_MAX_MIU[0]}
          max={MIN_MAX_MIU[1]}
          step={STEP_MIU}
          control={control}
          label={t('modalFilterDropoffs.avg-miu')}
          fieldName="avgMiu"
          format="percentage"
        />
        {/* UCO Gallon Price */}
        <RangeSliderInput
          min={MIN_MAX_GALLON_PRICE[0]}
          max={MIN_MAX_GALLON_PRICE[1]}
          step={STEP_GALLON_PRICE}
          control={control}
          label={t('modalFilterDropoffs.gallon-price')}
          fieldName="gallonPrice"
          format="money"
        />
        <RangeSliderInput
          min={MIN_MAX_TOTAL_PAID[0]}
          max={MIN_MAX_TOTAL_PAID[1]}
          step={STEP_TOTAL_PAID}
          control={control}
          label={t('modalFilterDropoffs.total-paid')}
          fieldName="totalPaid"
          format="money"
          units={1000}
        />
        {/* FFA */}
        <RangeSliderInput
          min={MIN_MAX_FFA[0]}
          max={MIN_MAX_FFA[1]}
          step={STEP_FFA}
          control={control}
          label={t('modalFilterDropoffs.avg-ffa')}
          fieldName="ffa"
          format="ppm"
        />
        {/* Sulfur */}
        <RangeSliderInput
          min={MIN_MAX_SULFUR[0]}
          max={MIN_MAX_SULFUR[1]}
          step={STEP_SULFUR}
          control={control}
          label={t('modalFilterDropoffs.avg-sulfur')}
          fieldName="sulfur"
          format="ppm"
        />
        <div className="mt-2 flex justify-end">
          <Button
            text={t('modalFilterDropoffs.save')}
            variant="green"
            icon={saveBigIcon}
            type="submit"
            id="save-dropoff-filters"
          />
        </div>
      </form>
    </div>
  );
};

export default FilterDropoff;
