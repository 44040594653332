/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-case */
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/call.svg';
import { ReactComponent as LocationIcon } from '../../../../../assets/icons/location.svg';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
  paidStatusOptions,
  paymentOptions,
  paymentStatusOptions,
} from '../../../../../config/constants';
import { useNotification } from '../../../../../hooks/useNotification';
import { UPDATE_STOP } from '../../../../../services/graphql/stops/stops-mutation';
import DropdownWithContent from '../../../common/DropdownWithContent';
import Dropdown from '../../../common/Inputs/Dropdown';
import InputCardField from '../../../common/Inputs/InputCardField';
import DTStopDocuments from '../../DTStopDocuments';
import StopStatusDropdown from '../../StopStatus';

type StopCardProps = {
  businessName: string;
  status: string;
  phoneNumber: string;
  address: string;
  estGallons?: number;
  puGallons?: number;
  estBarrels?: number;
  puBarrels?: number;
  paymentT?: string | null;
  container_size?: number;
  amount?: number;
  paidAmount?: number;
  paymentS?: string;
  service?: string;
  paidClient?: boolean | null;
  id: string;
  clientId?: string | undefined;
  serviceId?: string | undefined;
  invoiceNumber?: string | null;
  refetchRoute: () => void;
};

const StopCard: React.FC<StopCardProps> = ({
  businessName,
  status,
  phoneNumber,
  address,
  estGallons,
  puGallons,
  paymentT,
  amount,
  estBarrels,
  paymentS,
  service,
  invoiceNumber,
  id,
  paidClient,
  puBarrels,
  container_size,
  clientId,

  refetchRoute,
}) => {
  const { t } = useTranslation('common');
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const oilDisposal = OIL_DISPOSAL_SERVICE_NAME;
  const hoodCleaning = HOOD_CLEANING_SERVICE_NAME;
  const greaseTrap = GREASE_TRAP_SERVICE_NAME;
  const fatBones = FAT_BONES_SERVICE_NAME;
  const [updateStop] = useMutation(UPDATE_STOP);

  const [paymentType, setPaymentType] = useState(paymentT);

  const [stopPaymentStatus, setStopPaymentStatus] = useState(paymentS);

  const [stopEstGallons, setStopEstGallons] = useState(estGallons);
  const [stopEstBarrels, setStopEstBarrels] = useState(estBarrels);
  const [pickedGallons, setPickedGallons] = useState(puGallons);
  const [pickedBarrels, setPickedBarrels] = useState(puBarrels);
  const [stopInvoiceNumber, setStopInvoiceNumber] = useState(invoiceNumber);
  const [amountValue, setAmountValue] = useState(amount);
  const stopId = id;

  const calculatePercentage = (
    pickedGallons: number | undefined,
    containerSize: number | undefined,
  ): number => {
    if (!containerSize || !pickedGallons) {
      return 0;
    }
    const percentage = (pickedGallons / containerSize) * 100;
    return parseFloat(percentage.toFixed(2));
  };

  const [stopPercentage, setStopPercentage] = useState(
    calculatePercentage(pickedGallons, container_size),
  );

  useEffect(() => {
    setStopPercentage(calculatePercentage(pickedGallons, container_size));
  }, [puGallons]);

  const handleFieldChange = (
    field: string,
    value: string | number | boolean,
  ) => {
    switch (field) {
      case 'payment_type':
        setPaymentType(value as string);
        break;

      case 'paymentStatus':
        setStopPaymentStatus(value as string);
        break;
      case 'stopPercentage':
        setStopPercentage(Number(value));
        break;
      case 'estimated_gallons':
        setStopEstGallons(Number(value));
        break;
      case 'estimated_barrel':
        setStopEstBarrels(Number(value));
        break;
      case 'picked_up_gallons':
        setPickedGallons(Number(value));
        break;
      case 'picked_up_barrel':
        setPickedBarrels(Number(value));
        break;
      case 'invoice_id':
        setStopInvoiceNumber(value as string);
        break;
      case 'payments':
        setAmountValue(Number(value));
        break;
      case 'payment_status':
        setStopPaymentStatus(value as string);
        break;
    }
  };

  const convertToString = (value: string | number | boolean): string => {
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }

    return String(value);
  };

  const handleStopSubmit = (
    field: string,
    value: string | number | boolean,
    currentValue: string | number | boolean,
  ) => {
    if (value !== currentValue) {
      updateStop({
        variables: {
          key: field,
          value: convertToString(value),
          stopId,
        },
        onCompleted: () => {
          refetchRoute();
          notifySaveSuccess();
        },
        onError: () => {
          notifySaveError();
        },
      });
    }
  };

  return (
    <div className="mb-10 flex w-full flex-col items-start justify-start gap-[15px] rounded-[10px] bg-white p-2.5 shadow md:w-[50vw]">
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="shrink grow basis-0 text-sm font-semibold text-zinc-800">
          {businessName}
        </div>
        <div className="flex items-center justify-end gap-[15px]">
          <StopStatusDropdown
            initialStatus={status}
            stopId={id}
            refetchRoutes={() => {}}
          />
        </div>
      </div>
      <div className="inline-flex items-start justify-start gap-2.5 self-stretch">
        <div className="inline-flex w-[150px] flex-col items-start justify-start gap-2.5">
          <div className="inline-flex items-center justify-center gap-0.5 self-stretch">
            <PhoneIcon />
            <div className="shrink grow basis-0 text-sm font-normal text-zinc-800">
              <p>{phoneNumber}</p>
            </div>
          </div>
        </div>
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2.5">
          <div className="inline-flex items-center justify-center gap-0.5 self-stretch">
            <LocationIcon />
            <div className="shrink grow basis-0 text-sm font-normal text-zinc-800">
              <p>{address}</p>
            </div>
          </div>
        </div>
      </div>
      {service === greaseTrap && (
        <div className="grid w-full grid-cols-3 gap-4">
          <InputCardField
            id="estimated-gallons-input-route-detail-field"
            type="number"
            format="number"
            unitGal={true}
            label={t('routes.route-card-estimated-gallons')}
            placeholder={stopEstGallons || 0}
            selectedValue={stopEstGallons}
            onSelectChange={(value) =>
              handleFieldChange('estimated_gallons', value)
            }
            onSave={() =>
              handleStopSubmit(
                'estimated_gallons',
                (stopEstGallons || 0).toString(),
                estGallons || 0,
              )
            }
          />
          <InputCardField
            type="number"
            unitGal={true}
            format="number"
            id="picked-up-gallons-input-route-detail-field"
            label={t('routes.route-card-picked-up-gallons')}
            placeholder={pickedGallons || 0}
            selectedValue={pickedGallons}
            onSelectChange={(value) =>
              handleFieldChange('picked_up_gallons', value)
            }
            onSave={() =>
              handleStopSubmit(
                'picked_up_gallons',
                (pickedGallons || 0).toString(),
                puGallons || 0,
              )
            }
          />
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-type')}
            </div>
            <Dropdown
              options={paymentOptions}
              selectedValue={paymentType || ''}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setPaymentType(newValue);
                handleStopSubmit('payment_type', newValue, paymentType || '');
              }}
            />
          </div>
          <InputCardField
            id="invoice-number-input-route-detail-field"
            type="text"
            label={t('routes.route-stop-card-invoice-number')}
            placeholder={stopInvoiceNumber || '# Set'}
            selectedValue={stopInvoiceNumber}
            onSelectChange={(value) => handleFieldChange('invoice_id', value)}
            onSave={() =>
              handleStopSubmit(
                'invoice_id',
                stopInvoiceNumber || '',
                invoiceNumber || '',
              )
            }
          />
          <InputCardField
            id="paid-amount-input-route-detail-field"
            type="number"
            format="money"
            label={t('routes.route-stop-card-paid-amount')}
            placeholder={amountValue || 0}
            selectedValue={amountValue || 0}
            onSelectChange={(value) => handleFieldChange('payments', value)}
            onSave={() =>
              handleStopSubmit(
                'payments',
                (amountValue || 0).toString(),
                amount || 0,
              )
            }
          />
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-status')}
            </div>
            <Dropdown
              options={paymentStatusOptions}
              selectedValue={stopPaymentStatus}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setStopPaymentStatus(newValue);
                handleStopSubmit(
                  'payment_status',
                  newValue,
                  stopPaymentStatus || '',
                );
              }}
            />
          </div>
        </div>
      )}
      {service === fatBones && (
        <div className="grid w-full grid-cols-3 gap-4">
          <InputCardField
            id="estimated-barrels-input-route-detail-field"
            type="number"
            format="number"
            label={t('routes.route-card-estimated-barrel')}
            placeholder={stopEstBarrels || 0}
            selectedValue={stopEstBarrels}
            onSelectChange={(value) =>
              handleFieldChange('estimated_barrel', value)
            }
            onSave={() =>
              handleStopSubmit(
                'estimated_barrel',
                (stopEstBarrels || 0).toString(),
                estBarrels || 0,
              )
            }
          />

          <InputCardField
            type="number"
            format="number"
            id="picked-up-barrels-input-route-detail-field"
            label={t('routes.route-card-picked-up-barrel')}
            placeholder={pickedBarrels || 0}
            selectedValue={pickedBarrels}
            onSelectChange={(value) =>
              handleFieldChange('picked_up_barrel', value)
            }
            onSave={() =>
              handleStopSubmit(
                'picked_up_barrel',
                (pickedBarrels || 0).toString(),
                puBarrels || 0,
              )
            }
          />
          <InputCardField
            id="paid-amount-input-route-detail-field"
            type="number"
            format="money"
            label={t('routes.route-stop-card-paid-amount')}
            placeholder={amountValue || 0}
            selectedValue={amountValue || 0}
            onSelectChange={(value) => handleFieldChange('payments', value)}
            onSave={() =>
              handleStopSubmit(
                'payments',
                (amountValue || 0).toString(),
                amount || 0,
              )
            }
          />
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-type')}
            </div>
            <Dropdown
              options={paymentOptions}
              selectedValue={paymentType || ''}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setPaymentType(newValue);
                handleStopSubmit('payment_type', newValue, paymentType || '');
              }}
            />
          </div>

          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-status')}
            </div>
            <Dropdown
              options={paymentStatusOptions}
              selectedValue={stopPaymentStatus}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setStopPaymentStatus(newValue);
                handleStopSubmit(
                  'payment_status',
                  newValue,
                  stopPaymentStatus || '',
                );
              }}
            />
          </div>
        </div>
      )}
      {service === hoodCleaning && (
        <div className="grid w-full grid-cols-3 gap-4">
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-type')}
            </div>
            <Dropdown
              options={paymentOptions}
              selectedValue={paymentType || ''}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setPaymentType(newValue);
                handleStopSubmit('payment_type', newValue, paymentType || '');
              }}
            />
          </div>
          <InputCardField
            id="invoice-number-input-route-detail-field"
            type="text"
            label={t('routes.route-stop-card-invoice-number')}
            placeholder={stopInvoiceNumber || '# Set'}
            selectedValue={stopInvoiceNumber}
            onSelectChange={(value) => handleFieldChange('invoice_id', value)}
            onSave={() =>
              handleStopSubmit(
                'invoice_id',
                stopInvoiceNumber || '',
                invoiceNumber || '',
              )
            }
          />
          <InputCardField
            id="paid-amount-input-route-detail-field"
            type="number"
            format="money"
            label={t('routes.route-stop-card-paid-amount')}
            placeholder={amountValue || 0}
            selectedValue={amountValue || 0}
            onSelectChange={(value) => handleFieldChange('payments', value)}
            onSave={() =>
              handleStopSubmit(
                'payments',
                (amountValue || 0).toString(),
                amount || 0,
              )
            }
          />
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-status')}
            </div>
            <Dropdown
              options={paymentStatusOptions}
              selectedValue={stopPaymentStatus}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setStopPaymentStatus(newValue);
                handleStopSubmit('payment_status', newValue, paymentS || '');
              }}
            />
          </div>
        </div>
      )}
      {service === oilDisposal && (
        <div className="grid w-full grid-cols-3 gap-4">
          <InputCardField
            id="estimated-gallons-input-route-detail-field"
            type="number"
            format="number"
            unitGal={true}
            label={t('routes.route-card-estimated-gallons')}
            placeholder={stopEstGallons || 0}
            selectedValue={stopEstGallons}
            onSelectChange={(value) =>
              handleFieldChange('estimated_gallons', value)
            }
            onSave={() => {
              handleStopSubmit(
                'estimated_gallons',
                (stopEstGallons || 0).toString(),
                estGallons || 0,
              );
            }}
          />
          <InputCardField
            id="picked-up-gallons-input-route-detail-field"
            type="number"
            format="number"
            unitGal={true}
            label={t('routes.route-card-picked-up-gallons')}
            placeholder={pickedGallons || 0}
            selectedValue={pickedGallons}
            onSelectChange={(value) =>
              handleFieldChange('picked_up_gallons', value)
            }
            onSave={() =>
              handleStopSubmit(
                'picked_up_gallons',
                (pickedGallons || 0).toString(),
                puGallons || 0,
              )
            }
          />
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="w-full truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-percentage-picked-up')}
            </div>
            <div className="whitespace-nowrap text-right text-sm font-normal text-zinc-800">
              {`${stopPercentage} %`}
            </div>
          </div>
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-paid-client')}
            </div>
            <div className="text-right text-sm font-normal text-zinc-800">
              <label className="flex items-center gap-1">
                <div
                  className={`h-[7px] w-[7px] rounded-full ${
                    paidClient ? 'bg-green' : 'bg-red'
                  }`}
                />
                {paidClient
                  ? `${t('routes.route-stop-card-yes')}`
                  : `${t('routes.route-stop-card-no')}`}
              </label>
            </div>
          </div>
          <InputCardField
            id="paid-amount-input-route-detail-field"
            type="number"
            format="money"
            label={t('routes.route-stop-card-paid-amount')}
            placeholder={amountValue || 0}
            selectedValue={amountValue || 0}
            onSelectChange={(value) => handleFieldChange('payments', value)}
            onSave={() =>
              handleStopSubmit(
                'payments',
                (amountValue || 0).toString(),
                amount || 0,
              )
            }
          />
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-paid-status')}
            </div>
            <Dropdown
              options={paidStatusOptions}
              selectedValue={stopPaymentStatus}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setStopPaymentStatus(newValue);
                handleStopSubmit(
                  'payment_status',
                  newValue,
                  stopPaymentStatus || '',
                );
              }}
            />
          </div>
          <div className="flex items-center justify-between gap-2 border-b border-gray-400">
            <div className="truncate text-sm font-normal text-gray-400">
              {t('routes.route-stop-card-payment-type')}
            </div>
            <Dropdown
              options={paymentOptions}
              selectedValue={paymentType || ''}
              placeholder={t('routes.route-stop-card-payment-type-placeholder')}
              onSelectChange={(newValue) => {
                setPaymentType(newValue);
                handleStopSubmit('payment_type', newValue, paymentType || '');
              }}
            />
          </div>
        </div>
      )}
      <DropdownWithContent
        label={t('routes.route-stop-card-documents')}
        renderComponent={<DTStopDocuments clientId={clientId} stopId={id} />}
        key=""
      />
    </div>
  );
};

export default StopCard;
