import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import clientIcon from '../../../assets/icons/clients-small.svg';
import dollar from '../../../assets/icons/dollar.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { equipmentSelectedToEdit } from '../../../recoil/dataToEdit';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { needRefreshState } from '../../../recoil/needRefresh';
import {
  CREATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  UPDATE_EQUIPMENT,
} from '../../../services/graphql/equipment/equipmentMutation';
import { GET_EQUIPMENT_ID } from '../../../services/graphql/equipment/equipmentQuery';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import InputField from '../../ui/common/Inputs/InputField';

type Inputs = {
  name: string;
  quantity: number;
  cost: number;
};

const NewEquipment = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [equipment, setEquipment] = useRecoilState(equipmentSelectedToEdit);

  // Form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: '',
      quantity: 0,
      cost: 0,
    },
  });

  const breadcrumbTitle = `${t('modalNewEquipment.equipment')} > ${
    equipment ? t('modalNewEquipment.edit') : t('modalNewEquipment.new')
  }`;

  const name = watch('name');
  const quantity = watch('quantity');
  const cost = watch('cost');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const setConfirmModalState = useSetRecoilState(confirmationModalState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();
  const notifyDeleteEquipmentSuccess = () =>
    toast.success(t('modalNewEquipment.deleteSuccess'));
  const handleFormReset = () => {
    setValue('name', '');
    setValue('cost', 0);
    setValue('quantity', 0);
    setEquipment(null);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
    handleFormReset();
  };

  const [deleteEquipmentDB] = useMutation(DELETE_EQUIPMENT);

  // Query
  const [createEquipment, { loading: createEquipmentLoading }] =
    useMutation<any>(CREATE_EQUIPMENT, {
      variables: {
        name,
        cost,
        quantity,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    });

  const [updateEquipment, { loading: updatingEquipment }] = useMutation<any>(
    UPDATE_EQUIPMENT,
    {
      variables: {
        id: equipment,
        name,
        cost,
        quantity,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  const [getEquipment, { data }] = useLazyQuery(GET_EQUIPMENT_ID, {
    variables: {
      id: equipment,
    },
  });

  useEffect(() => {
    if (equipment) {
      getEquipment({
        variables: {
          id: equipment,
        },
      });
    }
  }, [equipment, getEquipment]);

  useEffect(() => {
    if (data && data.getEquipmentById) {
      const info = data.getEquipmentById.data[0];
      setValue('name', info.name);
      setValue('cost', info.cost);
      setValue('quantity', info.quantity);
    }
  }, [data, setValue]);

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = () => {
    if (createEquipmentLoading || updatingEquipment) {
      return;
    }
    const toSendCost = Number(Number(cost).toFixed(2).replace(',', '.'));
    const toSendQuantity = Number(quantity);
    if (equipment) {
      updateEquipment({
        variables: {
          id: equipment,
          name,
          cost: toSendCost,
          quantity: toSendQuantity,
        },
      });
      return;
    }

    createEquipment({
      variables: { name, cost: toSendCost, quantity: toSendQuantity },
    });
    handleFormReset();
  };
  const deleteEquipment = async () => {
    closeOrder();
    deleteEquipmentDB({
      variables: { id: equipment },
      onCompleted: () => {
        notifyDeleteEquipmentSuccess();
      },
    });
  };
  const handleDeleteModal = (event: any) => {
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deleteEquipment();
      },
      onCancel: () => {},
      message: t('global.are-you-sure-delete'),
    });
    event.preventDefault();
    return false;
  };

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        image={clientIcon}
        id="EquipmentModal"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {equipment ? t('modalNewEquipment.edit') : t('modalNewEquipment.new')}
      </h1>
      <form className="my-6">
        {/* Name Input */}
        <InputField
          label={t('modalNewEquipment.name')}
          selectedValue={name}
          doNotUseSaveSelfButton
          type="text"
          errors={errors.name}
          register={register}
          registerName="name"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        <InputField
          label={t('modalNewEquipment.quantity')}
          selectedValue={quantity}
          doNotUseSaveSelfButton
          type="number"
          errors={errors.quantity}
          register={register}
          registerName="quantity"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        <InputField
          label={t('modalNewEquipment.cost')}
          selectedValue={cost}
          doNotUseSaveSelfButton
          type="number"
          icon={dollar}
          errors={errors.cost}
          register={register}
          registerName="cost"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        {/* Save Button */}
        <div className="mt-2 flex justify-end">
          <Button
            text={t('modalNewEquipment.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={createEquipmentLoading}
            loading={createEquipmentLoading}
            onClick={handleSubmit(onSubmit)}
            type="button"
            id="save-new-equipment"
          />
        </div>
        {equipment && (
          <a
            className="flex w-full items-center justify-center"
            onClick={handleDeleteModal}
            href="#?"
          >
            <button className="flex cursor-pointer items-center justify-center text-center text-xs font-bold text-red underline">
              {t('modalNewEquipment.delete')}
            </button>
          </a>
        )}
      </form>
    </>
  );
};

export default NewEquipment;
