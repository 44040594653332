import { gql } from '@apollo/client';

export const DELETE_VENDORS = gql`
  mutation DeleteVendors($ids: [String]) {
    deleteVendors(ids: $ids) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($key: String!, $value: String!, $vendorId: String!) {
    updateVendor(key: $key, value: $value, vendorId: $vendorId) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_VENDOR = gql`
  mutation CreateVendor(
    $name: String!
    $phone: String!
    $email: String!
    $address: String!
    $owner_name: String!
    $owner_phone: String!
    $owner_email: String!
  ) {
    createVendor(
      name: $name
      phone: $phone
      email: $email
      address: $address
      owner_name: $owner_name
      owner_phone: $owner_phone
      owner_email: $owner_email
    ) {
      status
      code
      data
      error
    }
  }
`;

// TRUCKS
export const DELETE_VENDOR_TRUCK = gql`
  mutation DeleteVendorTruck($id: String!) {
    deleteVendorTruck(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_VENDOR_TRUCK = gql`
  mutation UpdateVendorTruck(
    $id: String!
    $license_plate: String!
    $tk: String!
    $capacity: Float!
  ) {
    updateVendorTruck(
      id: $id
      license_plate: $license_plate
      tk: $tk
      capacity: $capacity
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_VENDOR_TRUCK = gql`
  mutation CreateVendorTruck(
    $vendor_id: String!
    $license_plate: String!
    $tk: String!
    $capacity: Float!
  ) {
    createVendorTruck(
      vendor_id: $vendor_id
      license_plate: $license_plate
      tk: $tk
      capacity: $capacity
    ) {
      status
      code
      data
      error
    }
  }
`;

// DRIVERS
export const DELETE_VENDOR_DRIVER = gql`
  mutation DeleteVendorDriver($id: String!) {
    deleteVendorDriver(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_VENDOR_DRIVER = gql`
  mutation UpdateVendorDriver($id: String!, $name: String!, $phone: String!) {
    updateVendorDriver(id: $id, name: $name, phone: $phone) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_VENDOR_DRIVER = gql`
  mutation CreateVendorDriver(
    $vendor_id: String!
    $name: String!
    $phone: String!
  ) {
    createVendorDriver(vendor_id: $vendor_id, name: $name, phone: $phone) {
      status
      code
      data
      error
    }
  }
`;
