import React from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { getTrackBackground, Range } from 'react-range';

import {
  formatNumber,
  formatNumberMoney,
  formatNumberPercentage,
} from '../../../../utils/numberFormatter';

interface RangeSliderProps {
  min?: number;
  max?: number;
  step?: number;
  label: string;
  control: Control<any, any>;
  fieldName: string;
  format?: 'money' | 'percentage' | 'number' | 'ppm';
  units?: 1 | 1000;
}

const RangeSliderInput: React.FC<RangeSliderProps> = ({
  min = 0,
  max = 100,
  step = 1,
  label,
  control,
  fieldName,
  format,
  units = 1,
}) => {
  const formatValue = (val: string) => {
    if (!format) return val;
    const value = Number(val) / units;
    switch (format) {
      case 'money':
        return `${formatNumberMoney(value, units === 1000 ? 0 : 2)}k`;
      case 'number':
        return `${formatNumber(value)}k`;
      case 'percentage':
        return formatNumberPercentage(value);
      case 'ppm':
        return `${formatNumber(value)}ppm`;
      default:
        return val;
    }
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        const value = field.value || [min, max];
        return (
          <div className="py-2">
            <div className="flex flex-col items-start justify-center self-stretch">
              <label className="text-sm font-normal">{label}</label>
            </div>
            <div className="flex flex-row items-center justify-center gap-x-4 py-2">
              <span className="text-[12px]">{formatValue(min.toString())}</span>
              <Range
                values={value}
                step={step}
                min={min}
                max={max}
                onChange={(newValues) =>
                  field.onChange(newValues as [number, number])
                }
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '6px',
                      width: '100%',
                      background: getTrackBackground({
                        values: value,
                        colors: ['#D9D9D9', '#2E4347', '#D9D9D9'],
                        min,
                        max,
                      }),
                      borderRadius: '4px',
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props, index }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                      backgroundColor: '#6D7F83',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '0px 2px 6px #AAA',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '15px',
                        color: 'black',
                        fontSize: '12px',
                        padding: '4px',
                        borderRadius: '4px',
                      }}
                    >
                      {formatValue(value[index])}
                    </div>
                  </div>
                )}
              />
              <span className="text-[12px]">{formatValue(max.toString())}</span>
            </div>
          </div>
        );
      }}
    />
  );
};

export default RangeSliderInput;
